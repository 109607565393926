import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import * as reportsActionCreators from '../../../actions/reportsActionCreators';

export class AdjustHoursPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        hours: '',
        comment: '',
        month: +moment(props.date).format('M'),
        year: +moment(props.date).format('YYYY'),
      },
      errors: {},
    };
  }

  componentWillUnmount() {
    this.setState({ errors: {} });
  }

  handleChangeInput = (e) => {
    const { name, value, validity } = e.target;
    const { data } = this.state;
    data[name] = validity.valid ? value : data[name];

    this.setState({
      data,
    });
  };

  handleSubmit = () => {
    const { data } = this.state;
    const { userId, reportsActionCreators } = this.props;
    reportsActionCreators.approveHours({ id: userId, data, handleResponse: this.handleResponse });
  };

  handleResponse = (response) => {
    const { togglePopup, setHours } = this.props;
    const { status, data } = response;

    if (status === 201 || status === 200) {
      this.setState({
        errors: {},
      });

      togglePopup(null);
      setHours();
    } else if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  render() {
    const { togglePopup, t } = this.props;
    const {
      errors,
      data: { hours },
    } = this.state;

    return (
      <div className="notify-overlay">
        <div className="organization-popup">
          <h6 className="organization-popup__title">Adjust hours</h6>
          <div className="organization-popup-items">
            <div className="organization-popup-items-wrapper">
              <label className="organization-popup-items__label">{t('hours')}</label>
              <input
                type="text"
                name="hours"
                pattern="[0-9]*\.?[0-9]*"
                className={`organization-popup-items-field ${errors.hours && 'error-input'}`}
                onChange={this.handleChangeInput}
                placeholder={t('write_hours_manually')}
                value={hours}
              />
              {errors.hours && <p className="error-input-text mb-0">{errors.hours}</p>}
            </div>
            <div className="organization-popup-items-wrapper">
              <label className="organization-popup-items__label">{t('comment')}</label>
              <textarea
                name="comment"
                className="organization-popup-items-field textarea"
                onChange={this.handleChangeInput}
                placeholder={t('write_some_comments')}
              />
            </div>
          </div>
          <div className="organization-popup-buttons">
            <button className="organization-popup-buttons__addBtn" onClick={this.handleSubmit}>
              {t('apply')}
            </button>
            <button className="organization-popup-buttons__cancelBtn" onClick={togglePopup}>
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActionCreators: bindActionCreators(reportsActionCreators, dispatch),
  };
}

export default withTranslation()(connect(null, mapDispatchToProps)(AdjustHoursPopup));
