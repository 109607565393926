import axios from 'axios';

import { setCookie, deleteCookie } from '../Cookie';

export function login({ data, handleError }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/login`,
      data,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'LOGIN',
          login: response.data.data,
        });
        if (response.status === 200) {
          setCookie('auth_token', response.data.token);
          window.location.pathname = '/dashboard';
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };
}

export function logout() {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/logout`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'LOGOUT',
        });
        if (response.status === 204) {
          deleteCookie('auth_token');
          window.location.pathname = '/login';
        }
      })
      .catch((error) => {});
  };
}

export function forgotPassword({ email, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/forgot-password`,
      data: {
        email,
      },
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'FORGOT_PASSWORD',
        });
        handleSuccess(response);
      })
      .catch((error) => {
        handleError(error);
      });
  };
}

export function resetPassword({ data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/forgot-password/reset`,
      data,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'RESET_PASSWORD',
        });
        handleSuccess(response);
      })
      .catch((error) => {
        handleError(error);
      });
  };
}

export function changePassword({ data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/change-password`,
      data,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'RESET_PASSWORD',
        });
        handleSuccess(response);
      })
      .catch((error) => {
        handleError(error);
      });
  };
}

export function getAccount() {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/me`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_ACCOUNT',
          myAccData: response.data.data,
        });
      })
      .catch((error) => {});
  };
}
