import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { convertSecondsToHours } from '../../Utils/helpers';

class StatisticsTabTopPannel extends Component {
    render() {
        const { statistics, tabWidth, statisticsTotalHours, t } = this.props;

        return (
            <div className="statistics-tab__main-statistics-top-pannel">
                <table ref={this.table} className="statistics-tab__table--bordered">
                    <tbody>
                        <tr>
                            <td>
                                <div className="statistics-tab__hours">
                                    <p className="statistics-tab__main-statistics-title">
                                        {t('hours_in_base_schedule')}
                                    </p>
                                    <p>
                                        {convertSecondsToHours(statisticsTotalHours.scheduledHours)}
                                    </p>
                                </div>
                            </td>
                            {statistics?.map((el, i, arr) => {
                                const width = (tabWidth - 200) / arr.length;
                                return (
                                    <td key={i} style={{ width: `${width}px` }}>
                                        {convertSecondsToHours(el.total_seconds)}
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(StatisticsTabTopPannel);
