import React from "react";
import { Component } from "react";
import { withTranslation } from 'react-i18next';

class AddManager extends Component {
  constructor() {
    super();
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {t} = this.props;
    return (
      <div className="addManager-second">
        <h4 className="addManager-second-title">
          {t('additional_information')} <span>{t('optional')}</span>
        </h4>
        <p className="addManager-second-subtitle">
          {t('add_relatives_information')}
        </p>
        <div className="addManager-left__form-input-wrapper">
          <div className="addManager-left__form-input-wrapper-items name w-100">
            <label className="addManager-left__form-input-wrapper-items__label">
              {t('first_name')}
            </label>
            <input
              className={`addManager-left__form-input-wrapper-items__input`}
              type="text"
              name="relative_name"
              value={this.props.relative_name}
              onChange={this.props.handleChange}
            />
          </div>
          <div className="addManager-left__form-input-wrapper-items w-100">
            <label className="addManager-left__form-input-wrapper-items__label">
              {t('last_name')}
            </label>
            <input
              className={`addManager-left__form-input-wrapper-items__input`}
              type="text"
              name="relative_last_name"
              value={this.props.relative_last_name}
              onChange={this.props.handleChange}
            />
          </div>
        </div>
        <div className="addManager-left__form-input-wrapper-items">
          <label className="addManager-left__form-input-wrapper-items__label">
            {t('phone_number')}
          </label>
          <input
            className="addManager-left__form-input-wrapper-items__input"
            type="text"
            name="relative_phone"
            value={this.props.relative_phone}
            onChange={this.props.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(AddManager)
