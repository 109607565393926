import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import FullPageLoader from '../helpComponent/FullPageLoader';
import PlacesAutocomplete from '../helpComponent/PlacesAutocomplete';
import Bus from '../../Utils/Bus';

import { bindActionCreators } from 'redux';
import * as organizationActionCreators from '../../actions/organizationActionCreators';
import { connect } from 'react-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { withScriptjs } from 'react-google-maps';
import { withTranslation } from 'react-i18next';

class UpdateOrganazation extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      contact_email: '',
      address: '',
      gps_coordination: null,
      contact_phone: '',
      contact_person: '',
      errors: {},
      org: {},
      title: '',
    };
  }

  componentDidMount() {
    const { organizationData } = this.props;
    let id = this.props.match.params.id;
    this.props.organizationActionCreators.getOrganizationAccount({
      id,
    });
    if (!!organizationData) {
      this.setState({
        name: organizationData.name,
        contact_email: organizationData.contact_email || '',
        address: organizationData.address || '',
        gps_coordination: organizationData.gps_coordination,
        contact_phone: organizationData.contact_phone || '',
        contact_person: organizationData.contact_person || '',
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { organizationData } = this.props;
    if (organizationData !== prevProps.organizationData) {
      this.setState({
        name: organizationData.name,
        contact_email: organizationData.contact_email || '',
        address: organizationData.address || '',
        gps_coordination: organizationData.gps_coordination,
        contact_phone: organizationData.contact_phone || '',
        contact_person: organizationData.contact_person || '',
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeAddress = (address) => {
    this.setState({ address, gps_coordination: null });
  };

  handleSelectAddress = (address) => {
    this.handleChangeAddress(address);

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ gps_coordination: latLng });
      })
      .catch((error) => console.error('Error', error));
  };

  submitForm = (e) => {
    e.preventDefault();
    const { name, contact_email, contact_phone, contact_person, address, gps_coordination } = this.state;
    let data = {
      name,
      contact_email,
      contact_phone,
      contact_person,
      address: gps_coordination ? address : '',
      gps_coordination,
    };
    this.props.organizationActionCreators.updateOrganization({
      data,
      handleError: this.handleError,
      handleSuccess: this.handleSuccess,
      id: this.props.match.params.id,
    });
  };

  handleSuccess = (response) => {
    const { status, data } = response;
    const { history } = this.props;
    const id = this.props.match.params.id;
    if (status === 200) {
      this.setState({
        org: data,
        data,
        errors: {},
      });
      window.flash('The Organization has been updated!', 'success');
      history.push(`/settings/organization/account/${id}`);
    }
  };

  handleError = (error) => {
    const { status, data } = error.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  render() {
    const { errors } = this.state;
    const { organizationData, t } = this.props;
    return (
      <div className="organization">
        <div className="content organization-wrapper">
          <Link to="/settings/organization" className="addManager__link">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="#212529" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {t('back')}
          </Link>

          <form className="organization__form" onSubmit={this.submitForm} id="form">
            <h6 className="addManager-left__title">{organizationData?.name}</h6>
            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('name')}</label>
              <input
                className={`addManager-left__form-input-wrapper-items__input  ${errors.name && 'error-input'}`}
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
              {errors.name && <p className="error-input-text mb-0">{errors.name}</p>}
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('address')}</label>
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChangeAddress}
                onSelect={this.handleSelectAddress}
                className="addManager-left__form-input-wrapper-items__input w-100"
              />
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('contact_email')}</label>
              <input
                className={`addManager-left__form-input-wrapper-items__input`}
                type="email"
                name="contact_email"
                value={this.state.contact_email}
                onChange={this.handleChange}
              />
              {/* {errors.email && (
                <p className="error-input-text mb-0">{errors.email}</p>
              )} */}
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('contact_phone')}</label>
              <input
                className={`addManager-left__form-input-wrapper-items__input`}
                type="phone"
                name="contact_phone"
                value={this.state.contact_phone}
                onChange={this.handleChange}
              />
              {/* {errors.email && (
                <p className="error-input-text mb-0">{errors.email}</p>
              )} */}
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('contact_person')}</label>
              <input
                className={`addManager-left__form-input-wrapper-items__input`}
                type="text"
                name="contact_person"
                value={this.state.contact_person}
                onChange={this.handleChange}
              />
              {/* {errors.email && (
                <p className="error-input-text mb-0">{errors.email}</p>
              )} */}
            </div>

            <div className="addManager-buttons">
              <button type="submit" className="addManager-buttons__saveBtn">
                {t('update')}
              </button>
              <button
                type="button"
                className="addManager-buttons__cancelBtn"
                onClick={() => this.props.history.push('/settings/organization')}
              >
                {t('cancel')}
              </button>
            </div>
          </form>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    organizationData: state.organizationReducer.organizationData,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActionCreators: bindActionCreators(organizationActionCreators, dispatch),
  };
}

export default withTranslation()(withScriptjs(connect(mapStateToProps, mapDispatchToProps)(UpdateOrganazation)));
