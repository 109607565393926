import axios from 'axios';
import qs from 'query-string';
import { showLoader, hideLoader, showSmallLoader, hideSmallLoader } from './viewActionCreators';

export function createManager({ data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/manager/create`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'CREATE_MANAGER',
        });
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function updateManager({ id, data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/api/v1/manager/${id}/update`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'UPDATE_MANAGER',
        });
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function getManager({ params }) {
  let param = qs.parse(params);
  let url =
    param.search.length === 0
      ? `${process.env.REACT_APP_API_URL}/api/v1/managers${params}`
      : `${process.env.REACT_APP_API_URL}/api/v1/search/managers${params}`;
  return (dispatch) => {
    const options = {
      method: 'GET',
      url,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_MANAGERS',
          managers: response.data.data,
          meta: response.data.meta,
        });
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        dispatch(hideSmallLoader());
      });
  };
}

export function getManagerAcc({ id }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/${id}`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_MANAGER_DATA',
          managerData: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function deleteManager({ id, handleDeleteSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/${id}/trash`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'DELETE_MANAGER',
        });
        handleDeleteSuccess(response);
      })
      .catch((error) => {});
  };
}

export function toggleActivationManager({ id, active, handleActivateSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/${id}/activation`,
      data: {
        is_active: active,
      },
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'TOGGLE_ACTIVATION_MANAGER',
        });
        dispatch(hideLoader());
        handleActivateSuccess(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function getManagerEmployees({ id, params }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/manager/${id}/employees${params}`,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_MANAGER_EMPLOYEES',
          managerEmployees: response.data,
        });
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        dispatch(hideSmallLoader());
      });
  };
}

export function detachManagerEmployee({ id, handleDeleteSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_URL}/api/v1/employee/${id}/set-manager`,
      data: {
        manager_id: null,
      },
    };

    return axios(options)
      .then((response) => {
        handleDeleteSuccess(response);
      })
      .catch((error) => {});
  };
}
