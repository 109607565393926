import React, { Component } from "react";
import { SummaryPos, ViewTypes } from "react-big-scheduler";
import MembersFilter from "./MembersFilter";
import { CSSTransition } from "react-transition-group";

export class MembersHeader extends Component {
  state = {
    showMembersFilter: false,
    searchName: '',
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchName !== this.state.searchName) {
      this.props.getSearchName(this.state.searchName)
    }
  }

  toggleMemberFilters = () => {
    const { showMembersFilter } = this.state;
    this.setState({ showMembersFilter: !showMembersFilter });
  }

  handleChangeSearch = (e) => {
    this.setState({searchName: e.target.value})
  }

  render() {
    const { showMembersFilter, searchName } = this.state;
    return (
      <div className="scheduler-members">
        <p className="scheduler-members__title">Members</p>
        <div className="scheduler-members__search">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#212529"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.9999 20.9999L16.6499 16.6499"
                stroke="#212529"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          <input type="text" value={searchName} onChange={this.handleChangeSearch} />
        </div>
        <div className="d-flex justify-content-end">
          <img className="scheduler-members__img" src="/images/mail.svg"/>
          <div>
            <img
              className="scheduler-members__img"
              src="/images/sliders.svg"
              alt="sliders"
              onClick={this.toggleMemberFilters}
            />
            <CSSTransition
              in={showMembersFilter}
              timeout={300}
              classNames="show"
              unmountOnExit
            >
              <MembersFilter toggleMemberFilters={this.toggleMemberFilters} />
            </CSSTransition>
          </div>
        </div>
      </div>
    )
  }
}

export const config = {
  schedulerWidth: '100%',
  besidesWidth: 64,
  schedulerMaxHeight: window.innerHeight - 194, //window.innerHeight - 194
  tableHeaderHeight: 63,

  agendaResourceTableWidth: 500,
  agendaMaxEventWidth: 500,

  dayResourceTableWidth: 210,
  weekResourceTableWidth: 210,
  monthResourceTableWidth: 250,
  quarterResourceTableWidth: 160,
  yearResourceTableWidth: 160,
  customResourceTableWidth: 160,

  dayCellWidth: '2%',
  weekCellWidth: "11%",
  monthCellWidth: '3%',
  quarterCellWidth: 80,
  yearCellWidth: 80,
  customCellWidth: 80,

  dayMaxEvents: 99,
  weekMaxEvents: 99,
  monthMaxEvents: 99,
  quarterMaxEvents: 99,
  yearMaxEvents: 99,
  customMaxEvents: 99,

  eventItemHeight: 48,
  eventItemLineHeight: 56,
  nonAgendaSlotMinHeight: 90,
  dayStartFrom: 0,
  dayStopTo: 23,
  defaultEventBgColor: "#80C5F6",
  selectedAreaColor: "#e7f4ec",
  nonWorkingTimeHeadColor: "#87898B",
  nonWorkingTimeHeadBgColor: "#fff0f6",
  nonWorkingTimeBodyBgColor: "#fff0f6",
  summaryColor: "#666",
  summaryPos: SummaryPos.TopRight,
  groupOnlySlotColor: "#F8F8F8",

  startResizable: false,
  endResizable: false,
  movable: false,
  creatable: true,
  crossResourceMove: true,
  checkConflict: false,
  scrollToSpecialMomentEnabled: true,
  eventItemPopoverEnabled: true,
  calendarPopoverEnabled: true,
  recurringEventsEnabled: true,
  headerEnabled: true,
  displayWeekend: true,
  relativeMove: true,
  defaultExpanded: true,

  taskName: "Task Name",
  agendaViewHeader: "Shifts",
  addMorePopoverHeaderFormat: "MMM D, YYYY dddd",
  eventItemPopoverDateFormat: "MMM D",
  nonAgendaDayCellHeaderFormat: "ha",
  nonAgendaOtherCellHeaderFormat: "ddd D",

  minuteStep: 30,

  views: [
    {
      viewName: "Day",
      viewType: ViewTypes.Day,
      showAgenda: false,
      isEventPerspective: false,
    },
    {
      viewName: "Week",
      viewType: ViewTypes.Week,
      showAgenda: false,
      isEventPerspective: false,
    },
    {
      viewName: "Month",
      viewType: ViewTypes.Month,
      showAgenda: false,
      isEventPerspective: false,
    },
  ],
};
