import React, { Component } from 'react';

export default class WrapperReports extends Component {
  componentDidMount() {
    this.props.history.push('/reports/employees');
  }
  render() {
    return <></>;
  }
}
