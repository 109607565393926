import React, { Fragment } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const language = [
  { value: 'en', label: 'English' },
  { value: 'sv', label: 'Swedish' },
  { value: 'es', label: 'Spanish' },
];

export default function MainInfoList({ managerData }) {
  const { t } = useTranslation();
  const d = new Date();
  const currentYear = d.getFullYear();
  return (
    <Fragment>
      <h4 className="userAccount-table__title">{t('main_info')}</h4>
      <div className="userAccount-table-column">
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('ssn_number')}</p>
          <p className="userAccount-table__text bold">{managerData?.ssn || t('empty')}</p>
        </div>
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('bank_number')}</p>
          <p className="userAccount-table__text bold">{managerData?.bank_account || t('empty')}</p>
        </div>
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('address')}</p>
          <p className="userAccount-table__text bold">{managerData?.address || t('empty')}</p>
        </div>
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('phone_number')}</p>
          <p className="userAccount-table__text bold">{managerData?.phone || t('empty')}</p>
        </div>
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('language')}</p>
          <p className="userAccount-table__text bold">
            {language.map((option) => {
              if (option.value == managerData?.language) {
                return option.label;
              }
            })}
          </p>
        </div>
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('birthday')}</p>
          <p className="userAccount-table__text bold">
            {managerData?.birthday ? moment(managerData.birthday).format('YYYY-MM-DD') : t('empty')}{' '}
            {managerData?.birthday && `(${currentYear - moment(managerData.birthday).format('yyyy')})`}
          </p>
        </div>
      </div>
    </Fragment>
  );
}
