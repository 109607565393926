import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { withTranslation } from 'react-i18next';

import * as organizationActionCreators from '../../../actions/organizationActionCreators';
import ColorPicker from '../../helpComponent/ColorPicker';
import PlacesAutocomplete from '../../helpComponent/PlacesAutocomplete';

class AreaEditPopup extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      description: '',
      bgcolor: '#a7c5eb',
      address: '',
      gps_coordination: null,
    };
  }

  componentDidMount() {
    const { currentArea, orgId, areaId } = this.props;
    this.props.organizationActionCreators.getCurrentArea({ orgId, areaId });
    if (!!currentArea) {
      this.setState({
        name: currentArea.name,
        description: currentArea.description || '',
        bgcolor: currentArea.bgcolor || '#a7c5eb',
        address: currentArea.address || '',
        gps_coordination: currentArea.gps_coordination,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { currentArea } = this.props;
    if (currentArea !== prevProps.currentArea) {
      this.setState({
        name: currentArea.name,
        description: currentArea.description || '',
        bgcolor: currentArea.bgcolor || '#a7c5eb',
        address: currentArea.address || '',
        gps_coordination: currentArea.gps_coordination,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeAddress = (address) => {
    this.setState({ address, gps_coordination: null });
  };

  handleSelectAddress = (address) => {
    this.handleChangeAddress(address);

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ gps_coordination: latLng });
      })
      .catch((error) => console.error('Error', error));
  };

  handleChangeColor = (color) => {
    this.setState({
      bgcolor: color.hex,
    });
  };

  updateArea = (e) => {
    const { orgId, areaId } = this.props;
    const { name, description, bgcolor, address, gps_coordination } = this.state;
    let data = {
      name,
      description,
      bgcolor,
      address: gps_coordination ? address : '',
      gps_coordination,
    };
    this.props.organizationActionCreators.updateArea({
      data,
      orgId,
      areaId,
      handleSuccessUpdateArea: this.props.handleSuccessUpdateArea,
      handleErrorUpdateArea: this.props.handleErrorUpdateArea,
    });
  };

  render() {
    const { handlePopupClose, errors, t } = this.props;
    const { name, description, bgcolor, address } = this.state;
    return (
      <div className="notify-overlay">
        <div className="organization-popup">
          <h6 className="organization-popup__title">{t('edit_area')}</h6>
          <div className="organization-popup-items">
            <div className="organization-popup-items-wrapper">
              <label className="organization-popup-items__label">{t('name')}</label>
              <input
                type="text"
                name="name"
                value={name}
                className={`organization-popup-items-field ${errors.name && 'error-input'}`}
                onChange={this.handleChange}
              />
              {errors.name && <p className="error-input-text mb-0">{errors.name}</p>}
            </div>
            <div className="organization-popup-items-wrapper">
              <label className="organization-popup-items__label">{t('address')}</label>
              <PlacesAutocomplete
                value={address}
                onChange={this.handleChangeAddress}
                onSelect={this.handleSelectAddress}
                className="organization-popup-items-field w-100"
              />
            </div>
            <div className="organization-popup-items-wrapper">
              <label className="organization-popup-items__label">{t('bg_color')}</label>
              <ColorPicker onChange={this.handleChangeColor} value={bgcolor} />
            </div>
            <div className="organization-popup-items-wrapper">
              <label className="organization-popup-items__label">{t('description')}</label>
              <textarea
                name="description"
                value={description}
                className="organization-popup-items-field textarea"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="organization-popup-buttons">
            <button className="organization-popup-buttons__addBtn" onClick={this.updateArea}>
              {t('update')}
            </button>
            <button className="organization-popup-buttons__cancelBtn" onClick={handlePopupClose} type="button">
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoader: state.viewReducer.showLoader,
    currentArea: state.organizationReducer.currentArea,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActionCreators: bindActionCreators(organizationActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AreaEditPopup));
