import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import SmallLoader from '../../helpComponent/SmallLoader';

class AreaTable extends Component {
  render() {
    const { area, handlePopup, handleEditPopup, t } = this.props;
    return (
      <table className="table organization-table">
        <thead className="organization-table-thead">
          <tr>
            <th scope="col">{t('name')}</th>
            <th scope="col">{t('description')}</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody className="organization-table-tbody">
          {!!area ? (
            <Fragment>
              {area?.length !== 0 ? (
                area?.map((item, index) => {
                  return (
                    <tr className="organization-table__tr" key={index}>
                      <td>
                        <span className="organization-table__text regular">{item.name}</span>
                      </td>

                      <td>
                        <span className="organization-table__text light">{item.description}</span>
                      </td>

                      <td className="organization-table-btn">
                        <div className="organization-table-btn-wrapper">
                          <button
                            className="organization-table__editBtn"
                            type="button"
                            onClick={() => handleEditPopup(item.id)}
                          >
                            <svg
                              id={item.id}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.7"
                                d="M14.1665 2.49993C14.3854 2.28106 14.6452 2.10744 14.9312 1.98899C15.2171 1.87054 15.5236 1.80957 15.8332 1.80957C16.1427 1.80957 16.4492 1.87054 16.7352 1.98899C17.0211 2.10744 17.281 2.28106 17.4998 2.49993C17.7187 2.7188 17.8923 2.97863 18.0108 3.2646C18.1292 3.55057 18.1902 3.85706 18.1902 4.16659C18.1902 4.47612 18.1292 4.78262 18.0108 5.06859C17.8923 5.35455 17.7187 5.61439 17.4998 5.83326L6.24984 17.0833L1.6665 18.3333L2.9165 13.7499L14.1665 2.49993Z"
                                stroke="#212529"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            className="organization-table__deleteBtn"
                            type="button"
                            onClick={() => handlePopup(item.id)}
                          >
                            <svg
                              id={index}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.5 5H4.16667H17.5"
                                stroke="#EB5757"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033H15.8337Z"
                                stroke="#EB5757"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3" className="text-center p-0">
                    <div className="organization-table-empty">
                      <p className="organization-table-empty__text">You have no areas yet</p>
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          ) : (
            <tr>
              <td>
                <SmallLoader />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default withTranslation()(AreaTable)
