import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as authActionCreators from '../../actions/authActionCreators';
import * as viewActionCreators from '../../actions/viewActionCreators';
import ChangePasswordPopup from './ChangePasswordPopup';
import Header from '../Header/Header';
// import Menus from '../LeftMenu/Menus';

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: null,
    };
  }

  componentDidMount() {
    const { authActionCreators, viewActionCreators } = this.props;

    authActionCreators.getAccount();
    viewActionCreators.getNotifications();
    this.timer = setInterval(viewActionCreators.getNotifications, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    if (!this.props.myAccData) return null;

    const childrenWithProps = React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { user: this.props.myAccData });
      }
      return child;
    });

    return (
      <Fragment>
        <Header />
        <div className="main-container">
          {/* <Menus location={this.props.location} history={this.props.history} /> */}
          {this.props.myAccData.should_change_password ? <ChangePasswordPopup /> : childrenWithProps}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    myAccData: state.authReducer.myAccData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
    viewActionCreators: bindActionCreators(viewActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
