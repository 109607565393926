import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

const week = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
const month = [...Array(31 + 1).keys()].slice(1);
const year = [...Array(12 + 1).keys()].slice(1);

export class Repeat extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate) {
      this.setRepeats(this.props.repeat);
    }
  }

  handleChangeRepeat = (repeat) => {
    this.setRepeats(repeat.value);
  };

  setRepeats = (repeat) => {
    const { handleChangeRepeat, startDate } = this.props;

    switch (repeat) {
      case 'daily':
        handleChangeRepeat(repeat, {
          repeat_every: 1,
          repeat_on: [],
        });
        break;

      case 'weekly':
        handleChangeRepeat(repeat, {
          repeat_every: 1,
          repeat_on: [moment(startDate).format('dd').toUpperCase()],
        });
        break;

      case 'monthly':
        handleChangeRepeat(repeat, {
          repeat_every: 1,
          repeat_on: [+moment(startDate).format('DD')],
        });
        break;

      case 'yearly':
        handleChangeRepeat(repeat, {
          repeat_every: 1,
          repeat_on: [+moment(startDate).format('M')],
        });
        break;

      default:
        handleChangeRepeat(repeat, {
          repeat_every: 1,
          repeat_on: [],
        });
        break;
    }
  };

  render() {
    const { repeat, errors, t } = this.props;
    const repeatsList = [
      { label: t('do_not_repeat'), value: 0 },
      { label: t('every_day'), value: 'daily' },
      { label: t('every_week'), value: 'weekly' },
      { label: t('every_month'), value: 'monthly' },
      { label: t('every_year'), value: 'yearly' },
      { label: t('other'), value: 'other' },
    ];

    return (
      <div className="addManager-left__form-input-wrapper-items organization-popup__select mb-0 mt-3">
        <Select
          className={`addManager-left__form-input-wrapper-items__select ${errors.repeat && 'error-select'}`}
          options={repeatsList}
          onChange={this.handleChangeRepeat}
          name="repeat"
          value={repeatsList.find((item) => item.value === repeat)}
        />
        {errors.repeat && <p className="error-input-text mb-0 mt-1">{errors.repeat}</p>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Repeat));
