import axios from "axios";
import qs from "query-string";
import {
  showLoader,
  hideLoader,
  showSmallLoader,
  hideSmallLoader,
} from "./viewActionCreators";

export function createOrganization({ data, handleSuccess, handleError }) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "CREATE_ORGANIZATION",
        });
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function updateOrganization({ data, handleError, handleSuccess, id }) {
  return (dispatch) => {
    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "UPDATE_ORGANIZATION",
        });
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function getOrganization({ params }) {
  let param = qs.parse(params);
  let url =
    param.search.length === 0
      ? `${process.env.REACT_APP_API_URL}/api/v1/organizations${params}`
      : `${process.env.REACT_APP_API_URL}/api/v1/search/organizations${params}`;
  return (dispatch) => {
    const options = {
      method: "GET",
      url,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "GET_ORGANIZATION",
          organization: response.data.data,
          meta: response.data.meta,
        });
        // handleSuccess(response);
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        // handleError(error);
        dispatch(hideSmallLoader());
      });
  };
}

export function getOrganizationAccount({ id }) {
  return (dispatch) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "GET_ORGANIZATION_ACCOUNT",
          organizationData: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        // handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function createAreas({ data, id, handleSuccessArea, handleErrorArea }) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}/areas`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "CREATE_ORGANIZATION_AREAS",
        });
        handleSuccessArea(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleErrorArea(error);
        dispatch(hideLoader());
      });
  };
}

export function getArea({ id }) {
  return (dispatch) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}/areas`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: "GET_ORGANIZATION_AREA",
          area: response.data.data,
        });
      })
      .catch((error) => {});
  };
}

export function deleteArea({ orgId, areaId, handleSuccessDeleteArea }) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${orgId}/areas/${areaId}/trash`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: "DELETE_ORGANIZATION_AREA",
        });
        handleSuccessDeleteArea(response);
      })
      .catch((error) => {});
  };
}

export function getCurrentArea({ orgId, areaId }) {
  return (dispatch) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${orgId}/areas/${areaId}`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "GET_CURRENT_ORGANIZATION_AREA",
          currentArea: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function updateArea({
  data,
  orgId,
  areaId,
  handleSuccessUpdateArea,
  handleErrorUpdateArea,
}) {
  return (dispatch) => {
    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${orgId}/areas/${areaId}`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "UPDATE_CURRENT_ORGANIZATION_AREA",
        });
        handleSuccessUpdateArea(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
        handleErrorUpdateArea(error);
      });
  };
}

export function deleteOrganization({ id, handleDeleteSuccess }) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${id}/trash`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: "DELETE_ORGANIZATION",
        });
        handleDeleteSuccess(response);
      })
      .catch((error) => {});
  };
}

export function getAsignedManagers({ orgId }) {
  return (dispatch) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${orgId}/managers`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "GET_ASIGNED_MANAGER",
          asignedManagers: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function asignManager({
  data,
  handleSuccessAsignManager,
  handleErrorAsignManager,
}) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/assign-manager`,
      data,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: "ASIGN_MANAGER",
        });
        handleSuccessAsignManager(response);
      })
      .catch((error) => {
        handleErrorAsignManager(error);
      });
  };
}

export function deleteAsignedManager({
  areaId,
  userId,
  handleSuccessDeleteManager,
}) {
  return (dispatch) => {
    const options = {
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/v1/organizations/${userId}/areas/${areaId}/remove-manager `,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: "DELETE_ORGANIZATION",
        });
        handleSuccessDeleteManager(response);
      })
      .catch((error) => {});
  };
}
