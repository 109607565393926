import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import qs from 'query-string';
import { CSSTransition } from 'react-transition-group';

import * as newsActionCreators from '../../actions/newsActionCreators';
import Popup from '../helpComponent/Popup';
import FullPageLoader from '../helpComponent/FullPageLoader';
import Table from './Table';

class News extends Component {
  constructor() {
    super();
    this.state = {
      isShowAgreePopup: false,
      deleteId: '',
      pagination: {
        page: 1,
        limit: 10,
        search: '',
      },
      typingTimeout: 0,
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });

    this.setState({ pagination }, () => {
      let params = `?${qs.stringify(pagination)}`;
      this.props.newsActionCreators.getNews({ params });
    });
  }

  handlePopup = (id) => {
    this.setState({
      isShowAgreePopup: !this.state.isShowAgreePopup,
      deleteId: id,
    });
  };

  handleDelete = () => {
    const { deleteId } = this.state;
    this.props.newsActionCreators.deleteNews({
      id: deleteId,
      handleDeleteSuccess: this.handleDeleteSuccess,
    });
  };

  handleDeleteSuccess = (response) => {
    const { status } = response;
    const { pagination } = this.state;
    const { history } = this.props;

    if (status === 204) {
      this.setState({
        isShowAgreePopup: false,
        deleteId: '',
      });
    }
    let params = `?${qs.stringify(pagination)}`;

    this.props.newsActionCreators.getNews({ params });
    history.push({ search: params });
  };

  handlePageChange = (page) => {
    const { pagination } = this.state;
    const { history } = this.props;

    pagination.page = page;
    let params = `?${qs.stringify(pagination)}`;

    this.props.newsActionCreators.getNews({ params });
    history.push({ search: params });
  };

  handleChangeSearch = (e) => {
    const self = this;
    const { pagination } = this.state;
    const { history } = this.props;

    pagination.search = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      pagination,
      typingTimeout: setTimeout(function () {
        self.props.newsActionCreators.getNews({ params });
      }, 500),
    });
    history.push({ search: params });
  };

  handleSelect = (e) => {
    const { pagination } = this.state;
    const { history } = this.props;

    pagination.limit = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    this.props.newsActionCreators.getNews({ params });
    history.push({ search: params });
  };

  render() {
    const { news, meta } = this.props;
    const { isShowAgreePopup } = this.state;

    return (
      <div className="administrator">
        <CSSTransition in={isShowAgreePopup} timeout={300} classNames="show" unmountOnExit>
          <Popup
            type="delete"
            text="Are you sure you want to delete a news?"
            onHandleClose={this.handlePopup}
            onHandleDelete={this.handleDelete}
          />
        </CSSTransition>
        <div className="content ">
          <Table
            news={news}
            meta={meta}
            handleChangeSearch={this.handleChangeSearch}
            handlePopup={this.handlePopup}
            count={meta?.last_page}
            searchValue={this.state.pagination.search}
            handlePageChange={this.handlePageChange}
            handleSelect={this.handleSelect}
            page={this.state.pagination.page}
            selectValue={this.state.pagination.limit}
          />
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    news: state.newsReducer.news,
    meta: state.newsReducer.meta,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newsActionCreators: bindActionCreators(newsActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
