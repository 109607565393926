import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import { Logo } from '../Svg/Svg';

export default withTranslation()(
  withRouter(
    class CheckEmail extends Component {
      onSubmit = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
      };

      render() {
        const { t } = this.props;
        return (
          <div className="auth-container">
            <div className="auth-left">
              <Logo classLogo={'auth-left-logo'} />
              <div className="auth-greeting">
                <h1 className="auth-greeting__text">{t('hello_friend')}</h1>
                <h1 className="auth-greeting__text">{t('welcome_back')}</h1>
              </div>
            </div>
            <div className="auth-right">
              <form className="auth-form checkemail" onSubmit={this.onSubmit}>
                <p className="auth-form__bottomtext invisible">
                  {t('dont_receive_link')}
                  <Link to="/forgot-password" className="auth-form__bottomtext link">
                    {' '}
                    {t('dont_receive_link2')}
                  </Link>
                </p>
                <div className="d-flex align-item-center flex-column">
                  <h1 className="auth-form__title forgotpassword">{t('check_your_email')}</h1>
                  <p className="auth-form__subtitle checkemail">
                    {t('we_sent_you_password')}
                  </p>
                  <button type="submit" className="auth-form__btn checkemail">
                    {t('ok')}
                  </button>
                </div>
                <p className="auth-form__bottomtext">
                  {t('dont_receive_link')}
                  <Link to="/forgot-password" className="auth-form__bottomtext link">
                    {' '}
                    {t('dont_receive_link2')}
                  </Link>
                </p>
              </form>
            </div>
          </div>
        );
      }
    }
  )
);
