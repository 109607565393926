import React from 'react';
import { Component } from 'react';
import RadioButton from '../helpComponent/RadioButton';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';
import { schedulerData } from './Schedule';

import { bindActionCreators } from 'redux';
import * as scheduleActionCreators from '../../actions/scheduleActionCreators';
import { connect } from 'react-redux';

class MembersFilter extends Component {
  constructor() {
    super();
    this.state = {
      employeeFilter: 'my',
    };
  }

  componentDidMount() {
    if (this.props.memberFilter === undefined) {
      this.props.scheduleActionCreators.toggleMemberFilters({ filter: 'my' });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.memberFilter !== prevProps.memberFilter) {
      this.setState({
        employeeFilter: this.props.memberFilter,
      });
    }
  }

  handleEmployeeFilter = (e) => {
    this.setState({ employeeFilter: e.target.value });
    let params = {
      start: `${schedulerData.startDate}T00:00:00`,
      end: `${schedulerData.endDate}T23:59:59`,
      'filter[employees]': e.target.value,
    };
    let filter = e.target.value;
    params = `?${qs.stringify(params)}`;
    this.props.scheduleActionCreators.toggleMemberFilters({ filter });
    this.props.scheduleActionCreators.getAllShift(params);
    this.props.toggleMemberFilters();
  };
  render() {
    const { toggleMemberFilters, t } = this.props;
    return (
      <div className="scheduler-members__filter">
        <div className="scheduler-members__filter-title">
          <span>{t('display_options')}</span>
          <span className="scheduler-members__filter-title__close" onClick={toggleMemberFilters}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5L5 15"
                stroke="#646669"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 5L15 15"
                stroke="#646669"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <div className="scheduler-members__filter-options">
          <div className="scheduler-members__filter-options__title">{t('employee_sorting')}</div>
          <RadioButton
            text={t('my_employees')}
            name="eployee-filter"
            value="my"
            isCheckedRadioBtn={this.props.memberFilter === 'my'}
            onChange={this.handleEmployeeFilter}
          />
          <RadioButton
            text={t('all_employees')}
            name="eployee-filter"
            value="all"
            isCheckedRadioBtn={this.props.memberFilter === 'all'}
            onChange={this.handleEmployeeFilter}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    memberFilter: state.scheduleReducer.memberFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    scheduleActionCreators: bindActionCreators(scheduleActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MembersFilter));
