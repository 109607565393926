import React, { Fragment } from 'react';

import Avatar from 'react-avatar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import NoData from './NoData';

import cross from '../../images/cross.svg';
import check from '../../images/check-green.svg';

function isPending(obj) {
  if (obj.type === 'REQUEST' && !obj.status) return true;
}

export default function Pending({ notifications, onCLickRequest, onDelete }) {
  const { t } = useTranslation();

  const pendingNotifications = notifications.filter(isPending);
  const todayNotifications = pendingNotifications.filter((obj) =>
    moment().isSame(obj.created_at, 'day'),
  );
  const recentNotifications = pendingNotifications.filter(
    (obj) => !moment().isSame(obj.created_at, 'day'),
  );

  if (!pendingNotifications.length) {
    return (
      <div className="notifications__content">
        <NoData />
      </div>
    );
  }
  return (
    <div className="notifications__content">
      {!!todayNotifications.length && (
        <div className="notifications__list">
          <div className="notifications__list-title">
            <h6 className="text-uppercase">{t('today')}</h6>
            {/* <span>Mark all as read</span> */}
          </div>
          {todayNotifications.map((item, index) => {
            return (
              <div key={index} className="notifications__list-item">
                <Avatar
                  name={`${item.user.first_name} ${item.user.last_name}`}
                  round="50%"
                  size="32"
                  className="notifications__list-item-avatar"
                />
                <div className="notifications__list-item-text">
                  <p>{item.text}</p>
                  <span>{moment(item.created_at).fromNow()}</span>
                </div>
                <div className="notifications__list-item-status">
                  {item.type === 'REQUEST' && (
                    <Fragment>
                      {item.status ? (
                        <span className={`notifications__list-item-status-text ${item.status.toLowerCase()}`}>
                          {capitalize(t(item.status.toLowerCase()))}
                        </span>
                      ) : (
                        <Fragment>
                          <div className="notifications__list-item-status-button mr-3">
                            <button onClick={() => onCLickRequest(item.id, 'APPROVE')}>
                              <img src={check} alt={t('accept')} />
                            </button>
                            <span>{t('accept')}</span>
                          </div>
                          <div className="notifications__list-item-status-button">
                            <button
                              onClick={() => onCLickRequest(item.id, 'DECLINE')}
                              className="notifications__list-item-status-button_close"
                            >
                              <img src={cross} alt="close" />
                            </button>
                            <span>{t('decline')}</span>
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
                <div className="notifications__list-item-close">
                  <img onClick={() => onDelete(item.id)} src={cross} alt="close" />
                  {/* <Checkbox containerStyle="notifications-checkbox" checkboxStyle="notifications-checkbox-check" /> */}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!!recentNotifications.length && (
        <div className="notifications__list">
          <div className="notifications__list-title">
            <h6 className="text-uppercase">{t('recent')}</h6>
          </div>
          {recentNotifications.map((item, index) => {
            return (
              <div key={index} className="notifications__list-item">
                <Avatar
                  name={`${item.user.first_name} ${item.user.last_name}`}
                  round="50%"
                  size="32"
                  className="notifications__list-item-avatar"
                />
                <div className="notifications__list-item-text">
                  <p>{item.text}</p>
                  <span>{moment(item.created_at).fromNow()}</span>
                </div>
                <div className="notifications__list-item-status">
                  {item.type === 'REQUEST' && (
                    <Fragment>
                      {item.status ? (
                        <span className={`notifications__list-item-status-text ${item.status.toLowerCase()}`}>
                          {capitalize(t(item.status.toLowerCase()))}
                        </span>
                      ) : (
                        <Fragment>
                          <div className="notifications__list-item-status-button mr-3">
                            <button onClick={() => onCLickRequest(item.id, 'APPROVE')}>
                              <img src={check} alt={t('accept')} />
                            </button>
                            <span>{t('accept')}</span>
                          </div>
                          <div className="notifications__list-item-status-button">
                            <button
                              onClick={() => onCLickRequest(item.id, 'DECLINE')}
                              className="notifications__list-item-status-button_close"
                            >
                              <img src={cross} alt="close" />
                            </button>
                            <span>{t('decline')}</span>
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
                <div className="notifications__list-item-close">
                  <img onClick={() => onDelete(item.id)} src={cross} alt="close" />
                  {/* <Checkbox containerStyle="notifications-checkbox" checkboxStyle="notifications-checkbox-check" /> */}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
