import React from 'react';
import { Component } from 'react';
import Edit from './Edit';
import FullPageLoader from '../helpComponent/FullPageLoader';

import { bindActionCreators } from 'redux';
import * as employeeActionCreators from '../../actions/employeeActionCreators';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class EditEmployee extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      isShowMenu: false,
      id: '',
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.setState({
      id: this.props.match.params.id,
    });
    this.props.employeeActionCreators.getEmployeeAcc({ id });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { isShowMenu, id } = this.state;
    const { employeeData, t } = this.props;
    return (
      <div>
        <div className="addManager">
          <div className="content addManager__background">
            <div className="addManager-wrapper">
              <button className="settings-buttonBack" onClick={() => this.props.history.goBack()}>
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('back')}
              </button>
              <Edit togglePopup={this.togglePopup} id={id} employeeData={employeeData} history={this.props.history} />
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoader: state.viewReducer.showLoader,
    employeeData: state.employeeReducer.employeeData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    employeeActionCreators: bindActionCreators(employeeActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditEmployee));
