import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import checkUserPermission from '../Utils/CheckUserPermission';
import { getCookie } from '../Cookie';
import { withMeta } from '../component/helpComponent/PageTitle';

const SecuredRoute = ({ user, permission, title, children, ...rest }) => {
  const allowed = permission ? checkUserPermission(user, permission) : true;
  const isAuthenticated = !!getCookie('auth_token');

  const renderChildren = (props) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...props });
      }
      return child;
    });
  };

  if (allowed && isAuthenticated) {
    return <Route {...rest} render={(props) => withMeta({ children: renderChildren(props), title })} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: !isAuthenticated ? '/login' : '/dashboard',
            state: { from: location },
          }}
        />
      )}
    />
  );
};

export default SecuredRoute;
