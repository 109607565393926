import React, { Component, Fragment } from 'react';
import Avatar from 'react-avatar';
import SmallLoader from '../helpComponent/SmallLoader';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

class BirthdaysInfo extends Component {
  state = {
    isLoading: false,
    birthdayData: [],
  };

  componentDidMount() {
    this.fetchBirthdaysInfo();
  }

  fetchBirthdaysInfo() {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/users/birthday`,
    };

    this.setState({ isLoading: true });

    axios(options)
      .then((response) => {
        this.setState({ isLoading: false, birthdayData: response.data.data });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <table className="table dashboard-birthdayTable">
        <thead className="settings-table-thead">
          <tr>
            <th scope="col">{t('employee')}</th>
            <th scope="col">{t('birthday')}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.isLoading ? (
            <tr>
              <td colSpan="8">
                <SmallLoader />
              </td>
            </tr>
          ) : (
            <Fragment>
              {this.state.birthdayData?.length !== 0 ? (
                this.state.birthdayData.map((el, idx) => (
                  <tr scope="row" key={idx}>
                    <td>
                      <div>
                        <Avatar name={`${el.first_name} ${el.last_name}`} size="40" round="50%" />
                        <div className="dashboard-birthdayTable-name">{`${el.first_name} ${el.last_name}`}</div>
                      </div>
                    </td>
                    <td>{`${el.birthday}`}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">
                    <p className="dashboard-birthdayTable-emptyText">{t('dont_have_birthdays')}</p>
                  </td>
                </tr>
              )}
            </Fragment>
          )}
        </tbody>
      </table>
    );
  }
}

export default withTranslation()(BirthdaysInfo);
