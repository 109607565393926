/* eslint-disable import/no-anonymous-default-export */
export const initialState = {
  shifts: [],
  users: [],
  statistics: [],
  isShiftsLoaded: false,
  memberFilter: 'my',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_SCHEDULE_EMPLOYEE':
      return {
        ...state,
        employees: action.scheduleEmployee,
        isEmployeeLoaded: true,
      };

    case 'GET_USERS':
      return {
        ...state,
        users: action.users,
      };

    case 'GET_MY_SCHEDULE_EMPLOYEE_ARE':
      return {
        ...state,
        eployeeArea: action.eployeeArea,
      };

    case 'GET_ALL_SHIFT':
      return {
        ...state,
        shifts: action.shifts,
        isShiftsLoaded: true,
      };

    case 'CREATE_SHIFT':
      let oldShifts = state.shifts?.slice();
      let createdShift = { ...action.shift };
      createdShift.end_work_at = createdShift.end_work_at.date.replaceAll('.000000', '');
      createdShift.start_work_at = createdShift.start_work_at.date.replaceAll('.000000', '');
      oldShifts.push(createdShift);
      return {
        ...state,
        shifts: oldShifts,
      };

    case 'GET_ALL_AREAS':
      return {
        ...state,
        allAreas: action.allAreas,
      };

    case 'GET_SHIFTS_STATISTICS':
      return {
        ...state,
        statistics: action.statistics,
      };

    case 'TOGGLE_MEMBER_FILTER':
      return {
        ...state,
        memberFilter: action.memberFilter,
      };

    default:
      return state;
  }
}
