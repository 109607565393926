import React, { Fragment } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DatePicker from 'react-datepicker';
// import MaskedInput from 'react-text-mask';
// import { subMinutes, addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function Breaks({
  addBreaks,
  errors,
  breaks,
  deleteBreaks,
  handleChangeBreaksStart,
  handleChangeBreaksEnd,
}) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="accordion" id="accordion5">
        <div className="">
          <div className="addShift-card-header d-flex justify-content-between align-items-center" id="headingFive">
            <div className="addShift-card-header-wrapper">
              <svg
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.7"
                  d="M1 0.75L5.5 5.25L10 0.75"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <button
                className="addShift-card-header__btn"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                {t('breaks')}
              </button>
            </div>
            <button className="addShift-card-header__btn" type="button" onClick={addBreaks}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 4.16699V15.8337"
                  stroke="#646669"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.16602 10H15.8327"
                  stroke="#646669"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div id="collapseFive" className="collapse show" aria-labelledby="headingFive" data-parent="#accordion5">
            <div className="addShift-card-body">
              <TransitionGroup className="todo-list">
                {breaks.map((item, index) => {
                  return (
                    <CSSTransition key={index} timeout={500} classNames="list-anim">
                      <Fragment>
                        <div className="addShift-card-body-breaks" key={index}>
                          <div className="addShift-card-body-breaks-items w-100">
                            <div className="timepicker w-100">
                              <DatePicker
                                className={`date-picker schedule-bg ${
                                  errors[`breaks.${index}.start`] && 'error-input'
                                }`}
                                calendarClassName="date-calendar zoom-out"
                                wrapperClassName="w-100"
                                selected={item.start}
                                onChange={(date) => handleChangeBreaksStart(date, index)}
                                showTimeSelect
                                timeCaption="Time"
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                showPopperArrow={false}
                                // minTime={new Date().setHours(0, 0)}
                                // maxTime={subMinutes(item.end, 1)}
                                // customInput={
                                //   <MaskedInput type="text" mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]} />
                                // }
                              />
                            </div>
                            <span>-</span>
                            <div className="timepicker w-100">
                              <DatePicker
                                popperPlacement="top-end"
                                className={`date-picker schedule-bg ${errors[`breaks.${index}.end`] && 'error-input'}`}
                                calendarClassName="date-calendar zoom-out"
                                wrapperClassName="w-100"
                                selected={item.end}
                                onChange={(date) => handleChangeBreaksEnd(date, index)}
                                showTimeSelect
                                timeCaption="Time"
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                showPopperArrow={false}
                                // minTime={addMinutes(item.start, 1)}
                                // maxTime={new Date().setHours(23, 59)}
                                // customInput={
                                //   <MaskedInput type="text" mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]} />
                                // }
                              />
                            </div>
                          </div>
                          <button
                            onClick={deleteBreaks}
                            type="button"
                            id={index}
                            className="addShift-card-body-breaks__deleteBtn"
                          >
                            <svg
                              id={index}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.5 5H4.16667H17.5"
                                stroke="#EB5757"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033H15.8337Z"
                                stroke="#EB5757"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        {errors[`breaks.${index}.end`] && (
                          <p className="error-input-text mb-0 mt-1">
                            {errors[`breaks.${index}.end`][0].replaceAll(`.${index}.`, ' ')}
                          </p>
                        )}
                        {errors[`breaks.${index}.start`] && (
                          <p className="error-input-text mb-0 mt-1">{errors[`breaks.${index}.start`][0]}</p>
                        )}
                      </Fragment>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
