import axios from 'axios';
import { showLoader, hideLoader, showSmallLoader, hideSmallLoader } from './viewActionCreators';

export function getMyScheduleEmployee({ id }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/employee/${id}/areas`,
    };

    // dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_MY_SCHEDULE_EMPLOYEE_ARE',
          eployeeArea: response.data.data,
        });
      })
      .catch((error) => {});
  };
}

export function getScheduleEmployee({ filter, active = '' }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/managers-employee?employee=${filter}&isActive=${active}`,
    };

    if (!active) dispatch(showLoader());

    return axios(options)
      .then((response) => {
        if (active) {
          dispatch({
            type: 'GET_ADD_SHIFT_EMPLOYEE',
            addShiftEmployee: response.data.data,
          });
        } else {
          dispatch({
            type: 'GET_SCHEDULE_EMPLOYEE',
            scheduleEmployee: response.data.data,
          });
        }
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function getUsers({ filter, active = '' }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/users?filter[role]=${filter}&filter[isActive]=${active}`,
    };

    if (!active) dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_USERS',
          users: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function userShiftCreate({ data, handleShiftError, handleShiftSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user-shift/create`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch(hideLoader());
        handleShiftSuccess(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
        handleShiftError(error);
      });
  };
}

export function userOffsCreate({ data, callback }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/vacation/create`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch(hideLoader());
        callback(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
        callback(error.response);
      });
  };
}

export function userShiftUpdate({
  id,
  data,
  handleShiftError,
  handleShiftSuccess,
}) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user-shift/${id}/update`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch(hideLoader());
        handleShiftSuccess(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
        handleShiftError(error);
      });
  };
}

export function userOffsUpdate({ id, data, handleOffsResponse }) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/api/v1/vacation/${id}/update`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch(hideLoader());
        handleOffsResponse(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
        handleOffsResponse(error.response);
      });
  };
}

export function getAllShift(params) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/schedule${params}&filter[isActive]=active`,
      headers: {
        Accept: 'application/json',
      },
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_ALL_SHIFT',
          shifts: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function deleteShift({ id, handleSuccessDeleteShift }) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user-shift/${id}/delete`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch(hideLoader());
        handleSuccessDeleteShift(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function deleteRepeatableShift({ id, handleSuccessDeleteShift }) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user-repeatable-shifts/${id}/delete`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch(hideLoader());
        handleSuccessDeleteShift(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function deleteOffs({ id, handleSuccessDeleteShift }) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/vacation/${id}/delete`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch(hideLoader());
        handleSuccessDeleteShift(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function moveShift({ id, data, handleMoveError }) {
  return (dispatch) => {
    const options = {
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user-shift/${id}/update-date`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'UPDATE_SHIFT',
          shift: response.data.data,
        });
        dispatch(hideLoader());
        // handleSuccessDeleteShift(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
        handleMoveError(error);
      });
  };
}

export function getAllAreas() {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/areas`,
    };

    // dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_ALL_AREAS',
          allAreas: response.data.data,
        });
        // dispatch(hideLoader());
        // handleSuccessDeleteShift(response);
      })
      .catch((error) => {
        // dispatch(hideLoader());
        // handleMoveError(error);
      });
  };
}

export function getShiftsStatistics(params) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/report/daily${params}&filter[isActive]=active`,
      headers: {
        Accept: 'application/json',
      },
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_SHIFTS_STATISTICS',
          statistics: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function getShiftCheckInsAndOuts(id, handleSuccess) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/check-in/history/${id}`,
      headers: {
        Accept: 'application/json',
      },
    };

    return axios(options)
      .then((response) => {
        handleSuccess(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function shiftCheckIn(shift_id, handleSuccess) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/check-in`,
      headers: {
        Accept: 'application/json',
      },
      data: { shift_id },
    };

    return axios(options)
      .then((response) => {
        handleSuccess(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function shiftCheckOut(shift_id, handleSuccess) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/check-out`,
      headers: {
        Accept: 'application/json',
      },
      data: { shift_id },
    };

    return axios(options)
      .then((response) => {
        handleSuccess(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function toggleMemberFilters({ filter }) {
  return {
    type: 'TOGGLE_MEMBER_FILTER',
    memberFilter: filter,
  };
}
