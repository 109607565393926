import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import * as scheduleActionCreators from '../../actions/scheduleActionCreators';
import ExtendedStatistics from './ExtendedStatistics';
import StatisticsTabTopPannel from './StatisticsTabTopPannel';
import StatisticsTabBottomPannel from './StatisticsTabBottomPannel';

class StatisticsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabWidth: '',
            tabName: 'general',
        };
        this.tabRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.getTabSize);
        this.getTabSize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getTabSize);
    }

    getTabSize = async () => {
        const tabWidth = await this.tabRef.current.clientWidth;
        this.setState({ tabWidth });
    };

    onTabClick = (e) => {
        this.setState({ tabName: e.currentTarget.name });
    };

    render() {
        const { tabName, tabWidth } = this.state;
        const { statistics } = this.props;
        const statisticsTotalHours = statistics.reduce((acc, el) => {
            if (
                !acc.hasOwnProperty('scheduledHours') &&
                !acc.hasOwnProperty('totalWorkedHours') &&
                !acc.hasOwnProperty('totalLeaves')
            ) {
                acc.scheduledHours = el.total_seconds;
                acc.totalWorkedHours = el.worked_seconds;
                acc.totalLeaves = el.leave_seconds;
            } else {
                acc.scheduledHours += el.total_seconds;
                acc.totalWorkedHours += el.worked_seconds;
                acc.totalLeaves += el.leave_seconds;
            }
            return acc;
        }, {});

        return (
            <div ref={this.tabRef} className="statistics-tab">
                <div className="statistics-tab__main-statistics">
                    <StatisticsTabTopPannel
                        statistics={statistics}
                        tabWidth={tabWidth}
                        statisticsTotalHours={statisticsTotalHours}
                    />
                    <StatisticsTabBottomPannel
                        statistics={statistics}
                        tabName={tabName}
                        tabWidth={tabWidth}
                        onTabClick={this.onTabClick}
                        statisticsTotalHours={statisticsTotalHours}
                    />
                </div>
                {tabName === 'extended' && (
                    <ExtendedStatistics
                        statistics={statistics}
                        statisticsTotalHours={statisticsTotalHours}
                        tabWidth={tabWidth}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        statistics: state.scheduleReducer.statistics,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        scheduleActionCreators: bindActionCreators(scheduleActionCreators, dispatch),
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatisticsTab));
