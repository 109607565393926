import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { convertSecondsToHours } from '../../Utils/helpers';

class ExtendedStatistics extends Component {
    render() {
        const { statistics, statisticsTotalHours, tabWidth, t } = this.props;
        return (
            <div className="extended-statistics">
                <table className="statistics-tab__table--bordered">
                    <tbody>
                        <tr className="extended-statistics__row">
                            <td>
                                <div className="statistics-tab__hours">
                                    <p>{t('hours')}</p>
                                </div>
                            </td>
                        </tr>
                        <tr className="extended-statistics__row">
                            <td>
                                <div className="statistics-tab__hours">
                                    <p>{t('scheduled_hours')}</p>
                                    <p className="extended-statistics__hours">
                                        {convertSecondsToHours(statisticsTotalHours.scheduledHours)}
                                    </p>
                                </div>
                            </td>
                            {statistics?.map((el, i, arr) => {
                                const width = (tabWidth - 200) / arr.length;
                                return (
                                    <td key={i} style={{ width: `${width}px` }}>
                                        {convertSecondsToHours(el.total_seconds)}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr className="extended-statistics__row">
                            <td>
                                <div className="statistics-tab__hours">
                                    <p>{t('worked_hours')}</p>
                                    <p className="extended-statistics__hours">
                                        {convertSecondsToHours(
                                            statisticsTotalHours.totalWorkedHours,
                                        )}
                                    </p>
                                </div>
                            </td>
                            {statistics?.map((el, i, arr) => {
                                const width = (tabWidth - 200) / arr.length;
                                return (
                                    <td key={i} style={{ width: `${width}px` }}>
                                        {convertSecondsToHours(el.worked_seconds)}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr className="extended-statistics__row">
                            <td>
                                <div className="statistics-tab__hours">
                                    <p>{t('leave_hours')}</p>
                                    <p className="extended-statistics__hours">
                                        {convertSecondsToHours(statisticsTotalHours.totalLeaves)}
                                    </p>
                                </div>
                            </td>
                            {statistics?.map((el, i, arr) => {
                                const width = (tabWidth - 200) / arr.length;
                                return (
                                    <td key={i} style={{ width: `${width}px` }}>
                                        {convertSecondsToHours(el.leave_seconds)}
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(ExtendedStatistics);
