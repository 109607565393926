export const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CREATE_ADMINISTRATOR":
      return {
        ...state,
      };

    case "GET_ADMINISTRATOR":
      return {
        ...state,
        administrator: action.administrator,
        meta: action.meta,
      };

    case "UPDATE_ADMINISTRATOR":
      return {
        ...state,
      };

    case "DELETE_ADMINISTRATOR":
      return {
        ...state,
      };

    case "TOGGLE_ACTIVATION_ADMINISTRATOR":
      return {
        ...state,
      };

    case "GET_ADMINISTRATOR_DATA":
      return {
        ...state,
        adminData: action.adminData
      };

    default:
      return state;
  }
}
