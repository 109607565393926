import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { EyeOn, EyeOff, Logo } from '../Svg/Svg';
import Checkbox from '../helpComponent/Checkbox';
import NotificationAuthPopup from '../helpComponent/NotificationAuthPopup';
import { CSSTransition } from 'react-transition-group';

import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../actions/authActionCreators';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmationPassword: '',
      isShowPassword: false,
      isShowConfirmPassword: false,
      errors: {},
      isShowNotify: false,
    };
  }

  togglePassword = () => {
    const { isShowPassword } = this.state;
    this.setState({ isShowPassword: !isShowPassword });
  };

  toggleConfirmPassword = () => {
    const { isShowConfirmPassword } = this.state;
    this.setState({ isShowConfirmPassword: !isShowConfirmPassword });
  };

  submitForm = (e) => {
    e.preventDefault();
    const { password, confirmationPassword } = this.state;
    let code = this.props.location.search.replace('?code=', '');
    const data = {
      password,
      password_confirmation: confirmationPassword,
      code,
    };
    this.props.authActionCreators.resetPassword({
      data,
      handleSuccess: this.handleSuccess,
      handleError: this.handleError,
    });
  };

  handleSuccess = (success) => {
    const { status } = success;
    if (status === 200) {
      this.setState({
        isShowNotify: true,
      });
      // this.props.location.search.replace("?code=", "");
      setInterval(() => {
        this.props.history.push({
          pathname: '/login',
          search: '',
        });
      }, 5000);
    }
  };

  handleError = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { isShowPassword, isShowConfirmPassword, errors, isShowNotify } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <div className="auth-container">
          <div className="auth-left">
            <Logo classLogo={'auth-left-logo'} />
            <div className="auth-greeting">
              <h1 className="auth-greeting__text">{t('hello_friend')}</h1>
              <h1 className="auth-greeting__text">{t('welcome_back')}</h1>
            </div>
          </div>
          <div className="auth-right">
            <form className="auth-form changepassword" onSubmit={this.submitForm}>
              <h1 className="auth-form__title createpassword">{t('create_new_password')}</h1>
              <div className="auth-form-inputContainer">
                <p className="auth-form__label">{t('password')}</p>
                <div className={`auth-form__input ${errors.password ? 'error-input' : ''}`}>
                  <input
                    type={isShowPassword ? 'text' : 'password'}
                    name="password"
                    className="w-100"
                    onChange={this.handleChange}
                  />
                  {isShowPassword ? (
                    <EyeOff toggleInputType={this.togglePassword} />
                  ) : (
                    <EyeOn toggleInputType={this.togglePassword} />
                  )}
                </div>
              </div>
              <div className="auth-form-inputContainer mb-0">
                <p className="auth-form__label">{t('confirm_password')} </p>
                <div className={`auth-form__input ${!!errors.password && errors.password[1] ? 'error-input' : ''}`}>
                  <input
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    name="confirmationPassword"
                    className="w-100"
                    onChange={this.handleChange}
                  />
                  {isShowConfirmPassword ? (
                    <EyeOff toggleInputType={this.toggleConfirmPassword} />
                  ) : (
                    <EyeOn toggleInputType={this.toggleConfirmPassword} />
                  )}
                </div>
                {!!errors.password && errors.password[0] && (
                  <span className="error-input-text">{!!errors.password && errors.password[0]}</span>
                )}
                {!!errors.password && errors.password[1] && (
                  <p className="error-input-text mb-0">{!!errors.password && errors.password[1]}</p>
                )}
                {errors.code && <p className="error-input-text mb-0 mt-1">{errors.code}</p>}
              </div>
              <button type="submit" className="auth-form__btn">
                {t('save')}
              </button>
            </form>
          </div>
        </div>
        <CSSTransition in={isShowNotify} timeout={200} classNames="notify-anim" unmountOnExit>
          <NotificationAuthPopup
            isShowBtn={false}
            text="Your password has been successfully changed. You will be redirect to login page after 5 second"
          />
        </CSSTransition>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
  };
}

export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(CreatePassword)));
