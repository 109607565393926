import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as authActionCreators from '../../actions/authActionCreators';
import { EyeOn, EyeOff } from '../Svg/Svg';

export class ChangePasswordPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        password: '',
        password_confirmation: '',
      },
      isShowPassword: false,
      isShowConfirmPassword: false,
      errors: {},
    };
  }

  toggleShowPassword = (field) => {
    this.setState({ [field]: !this.state[field] });
  };

  submitForm = () => {
    const { data } = this.state;

    this.props.authActionCreators.changePassword({
      data,
      handleSuccess: this.handleSuccess,
      handleError: this.handleError,
    });
  };

  handleSuccess = (success) => {
    const { status } = success;

    if (status === 200) {
      this.props.authActionCreators.getAccount();
    }
  };

  handleError = (err) => {
    const { status, data } = err.response;

    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  handleChange = (e) => {
    const { data } = this.state;
    const { value, name } = e.target;

    data[name] = value;
    this.setState({ data });
  };

  render() {
    const { isShowPassword, isShowConfirmPassword, errors } = this.state;
    const { t } = this.props;

    return (
      <div className="notify-overlay">
        <div className="organization-popup">
          <h6 className="organization-popup__title">Change password</h6>
          <div className="organization-popup-items">
            <div className="auth-form-inputContainer">
              <p className="auth-form__label">{t('password')}</p>
              <div className={`auth-form__input ${errors.password ? 'error-input' : ''}`}>
                <input type={isShowPassword ? 'text' : 'password'} name="password" onChange={this.handleChange} />
                {isShowPassword ? (
                  <EyeOff toggleInputType={() => this.toggleShowPassword('isShowPassword')} />
                ) : (
                  <EyeOn toggleInputType={() => this.toggleShowPassword('isShowPassword')} />
                )}
              </div>
            </div>
            <div className="auth-form-inputContainer">
              <p className="auth-form__label">{t('confirm_password')}</p>
              <div className={`auth-form__input ${!!errors.password_confirmation ? 'error-input' : ''}`}>
                <input
                  type={isShowConfirmPassword ? 'text' : 'password'}
                  name="password_confirmation"
                  onChange={this.handleChange}
                />
                {isShowConfirmPassword ? (
                  <EyeOff toggleInputType={() => this.toggleShowPassword('isShowConfirmPassword')} />
                ) : (
                  <EyeOn toggleInputType={() => this.toggleShowPassword('isShowConfirmPassword')} />
                )}
              </div>
              {!!errors.password && errors.password[0] && (
                <span className="error-input-text">{!!errors.password && errors.password[0]}</span>
              )}
              {!!errors.password_confirmation && errors.password_confirmation[0] && (
                <p className="error-input-text mb-0">
                  {!!errors.password_confirmation && errors.password_confirmation[0]}
                </p>
              )}
            </div>
          </div>
          <div className="organization-popup-buttons">
            <button className="organization-popup-buttons__addBtn" onClick={this.submitForm}>
              {t('save')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
  };
}

export default withTranslation()(connect(null, mapDispatchToProps)(ChangePasswordPopup));
