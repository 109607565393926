import React from 'react';

export default function PrivacyPolicy() {
  return (
    <>
      <div id="page_1">
        <div id="id1_1">
          <p className="p0 ft0">Privacy Policy</p>
          <p className="p1 ft1">
            Updated at <nobr>2021-03-03</nobr>
          </p>
          <p className="p2 ft2">
            EFMAB (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your
            personal information is collected, used, and disclosed by EFMAB.
          </p>
          <p className="p3 ft3">
            This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”)
            alongside our application, EFMAB. By accessing or using our Service, you signify that you have read,
            understood, and agree to our collection, storage, use, and disclosure of your personal information as
            described in this Privacy Policy and our Terms of Service.
          </p>
          <p className="p2 ft0">Definitions and key terms</p>
          <p className="p4 ft2">
            To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are
            referenced, are strictly defined as:
          </p>
          <p className="p5 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify
              your browser, provide analytics, remember information about you such as your language preference or login
              information.
            </span>
          </p>
          <p className="p6 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Efmab AB, Älvsjö Gårdsväg
              2 125 30 Älvsjö, Stockholm that is responsible for your information under this Privacy Policy.
            </span>
          </p>
          <p className="p7 ft7">
            <span className="ft4">●</span>
            <span className="ft6">
              Country: where EFMAB or the owners/founders of EFMAB are based, in this case is Sweden
            </span>
          </p>
          <p className="p8 ft1">
            <span className="ft4">●</span>
            <span className="ft8">
              Customer: refers to the company, organization or person that signs up to use the EFMAB Service to manage
              the relationships with your consumers or service users.
            </span>
          </p>
          <p className="p9 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              Device: any internet connected device such as a phone, tablet, computer or any other device that can be
              used to visit EFMAB and use the services.
            </span>
          </p>
          <p className="p10 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP)
              address. These numbers are usually assigned in geographic blocks. An IP address can often be used to
              identify the location from which a device is connecting to the Internet.
            </span>
          </p>
          <p className="p11 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              Personnel: refers to those individuals who are employed by EFMAB or are under contract to perform a
              service on behalf of one of the parties.
            </span>
          </p>
          <p className="p12 ft1">
            <span className="ft4">●</span>
            <span className="ft8">
              Personal Data: any information that directly, indirectly, or in connection with other information —
              including a personal identification number — allows for the identification or identifiability of a natural
              person.
            </span>
          </p>
          <p className="p7 ft7">
            <span className="ft4">●</span>
            <span className="ft6">
              Service: refers to the service provided by EFMAB as described in the relative terms (if available) and on
              this platform.
            </span>
          </p>
          <p className="p13 ft2">
            <span className="ft4">●</span>
            <nobr>
              <span className="ft5">Third-party</span>
            </nobr>{' '}
            service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide
            our content or whose products or services we think may interest you.
          </p>
          <p className="p7 ft10">
            <span className="ft4">●</span>
            <span className="ft9">Website: EFMAB."’s" site, which can be accessed via this URL:</span>
          </p>
          <p className="p7 ft1">
            <span className="ft4">●</span>
            <span className="ft8">You: a person or entity that is registered with EFMAB to use the Services.</span>
          </p>
          <p className="p14 ft0">What Information Do We Collect?</p>
          <p className="p15 ft2">
            We collect information from you when you visit our app, register on our site, place an order, subscribe to
            our newsletter, respond to a survey or fill out a form.
          </p>
          <p className="p16 ft1">
            <span className="ft4">●</span>
            <span className="ft8">Name / Username</span>
          </p>
          <p className="p7 ft7">
            <span className="ft4">●</span>
            <span className="ft6">Phone Numbers</span>
          </p>
          <p className="p7 ft7">
            <span className="ft4">●</span>
            <span className="ft6">Email Addresses</span>
          </p>
          <p className="p7 ft10">
            <span className="ft4">●</span>
            <span className="ft9">Age</span>
          </p>
          <p className="p7 ft1">
            <span className="ft4">●</span>
            <span className="ft8">Password</span>
          </p>
        </div>
        <div id="id1_2">
          <p className="p0 ft1">
            We also collect information from mobile devices for a better user experience, although these features are
            completely optional:
          </p>
        </div>
      </div>
      <div id="page_2">
        <div id="id2_1">
          <p className="p17 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              Location (GPS): Location data helps to create an accurate representation of your interests, and this can
              be used to bring more targeted and relevant ads to potential customers.
            </span>
          </p>
          <p className="p18 ft0">How Do We Use The Information We Collect?</p>
          <p className="p1 ft1">Any of the information we collect from you may be used in one of the following ways:</p>
          <p className="p19 ft1">
            <span className="ft4">●</span>
            <span className="ft8">
              To personalize your experience (your information helps us to better respond to your individual needs)
            </span>
          </p>
          <p className="p20 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              To improve our app (we continually strive to improve our app offerings based on the information and
              feedback we receive from you)
            </span>
          </p>
          <p className="p21 ft1">
            <span className="ft4">●</span>
            <span className="ft8">
              To improve customer service (your information helps us to more effectively respond to your customer
              service requests and support needs)
            </span>
          </p>
          <p className="p7 ft7">
            <span className="ft4">●</span>
            <span className="ft6">To process transactions</span>
          </p>
          <p className="p7 ft7">
            <span className="ft4">●</span>
            <span className="ft6">To administer a contest, promotion, survey or other site feature</span>
          </p>
          <p className="p7 ft1">
            <span className="ft4">●</span>
            <span className="ft8">To send periodic emails</span>
          </p>
          <p className="p22 ft11">When does EFMAB use end user information from third parties?</p>
          <p className="p18 ft1">
            EFMAB will collect End User Data necessary to provide the EFMAB services to our customers.
          </p>
          <p className="p23 ft12">
            End users may voluntarily provide us with information they have made available on social media websites. If
            you provide us with any such information, we may collect publicly available information from the social
            media websites you have indicated. You can control how much of your information social media websites make
            public by visiting these websites and changing your privacy settings.
          </p>
          <p className="p24 ft11">When does EFMAB use customer information from third parties?</p>
          <p className="p25 ft12">
            We receive some information from the third parties when you contact us. For example, when you submit your
            email address to us to show interest in becoming a EFMAB customer, we receive information from a third party
            that provides automated fraud detection services to EFMAB. We also occasionally collect information that is
            made publicly available on social media websites. You can control how much of your information social media
            websites make public by visiting these websites and changing your privacy settings.
          </p>
          <p className="p26 ft13">Do we share the information we collect with third parties?</p>
          <p className="p27 ft12">
            We may share the information that we collect, both personal and <nobr>non-personal,</nobr> with third
            parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide
            our content or whose products or services we think may interest you. We may also share it with our current
            and future affiliated companies and business partners, and if we are involved in a merger, asset sale or
            other business reorganization, we may also share or transfer your personal and <nobr>non-personal</nobr>{' '}
            information to our
            <nobr>successors-in-interest.</nobr>
          </p>
        </div>
        <div id="id2_2">
          <p className="p0 ft1">
            We may engage trusted third party service providers to perform functions and provide services to us, such as
            hosting and
          </p>
        </div>
      </div>
      <div id="page_3">
        <p className="p28 ft3">
          maintaining our servers and the app, database storage and management, <nobr>e-mail</nobr> management, storage
          marketing, credit card processing, customer service and fulfilling orders for products and services you may
          purchase through the app. We will likely share your personal information, and possibly some
          <nobr>non-personal</nobr> information, with these third parties to enable them to perform these services for
          us and for you.
        </p>
        <p className="p29 ft3">
          We may share portions of our log file data, including IP addresses, for analytics purposes with third parties
          such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may
          be used to estimate general location and other technographics such as connection speed, whether you have
          visited the app in a shared location, and type of the device used to visit the app. They may aggregate
          information about our advertising and what you see on the app and then provide auditing, research and
          reporting for us and our advertisers. We may also disclose personal and
          <nobr>non-personal</nobr> information about you to government or law enforcement officials or private parties
          as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process
          (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public
          or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise
          comply with applicable court orders, laws, rules and regulations.
        </p>
        <p className="p30 ft13">Where and when is information collected from customers and end users?</p>
        <p className="p31 ft2">
          EFMAB will collect personal information that you submit to us. We may also receive personal information about
          you from third parties as described above.
        </p>
        <p className="p32 ft0">How Do We Use Your Email Address?</p>
        <p className="p15 ft3">
          By submitting your email address on this app, you agree to receive emails from us. You can cancel your
          participation in any of these email lists at any time by clicking on the <nobr>opt-out</nobr> link or other
          unsubscribe option that is included in the respective email. We only send emails to people who have authorized
          us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails,
          because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use
          your email address for customer audience targeting on sites like Facebook, where we display custom advertising
          to specific people who have <nobr>opted-in</nobr> to receive communications from us. Email addresses submitted
          only through the order processing page will be used for the sole purpose of sending you information and
          updates pertaining to your order. If, however, you have provided the same email to us through another method,
          we may use it for any of the purposes stated in this Policy. Note: If at any time you would like to
          unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each
          email.
        </p>
        <p className="p33 ft0">How Long Do We Keep Your Information?</p>
        <p className="p34 ft3">
          We keep your information only so long as we need it to provide EFMAB to you and fulfill the purposes described
          in this policy. This is also the case for anyone that we share your information with and who carries out
          services on our behalf. When we no longer need to use your information and there is no need for us to keep it
          to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize
          it so that we can't identify you.
        </p>
        <p className="p35 ft0">How Do We Protect Your Information?</p>
        <p className="p36 ft3">
          We implement a variety of security measures to maintain the safety of your personal information when you place
          an order or enter, submit, or access your personal information. We offer the use of a secure server. All
          supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then
          encrypted into our Payment gateway providers database only to be accessible by those authorized with special
          access rights to such systems, and are required to keep the information
        </p>
      </div>
      <div id="page_4">
        <div id="id4_1">
          <p className="p37 ft3">
            confidential. After a transaction, your private information (credit cards, social security numbers,
            financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any
            information you transmit to EFMAB or guarantee that your information on the Service may not be accessed,
            disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.
          </p>
          <p className="p35 ft0">Could my information be transferred to other countries?</p>
          <p className="p38 ft12">
            EFMAB is incorporated in Sweden. Information collected via our website, through direct interactions with
            you, or from use of our help services may be transferred from time to time to our offices or personnel, or
            to third parties, located throughout the world, and may be viewed and hosted anywhere in the world,
            including countries that may not have laws of general applicability regulating the use and transfer of such
            data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to
            the
            <nobr>trans-border</nobr> transfer and hosting of such information.
          </p>
          <p className="p39 ft13">Is the information collected through the EFMAB Service secure?</p>
          <p className="p40 ft3">
            We take precautions to protect the security of your information. We have physical, electronic, and
            managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly
            use your information. However, neither people nor security systems are foolproof, including encryption
            systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies.
            Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its
            absolute security. If applicable law imposes any
            <nobr>non-disclaimable</nobr> duty to protect your personal information, you agree that intentional
            misconduct will be the standards used to measure our compliance with that duty.
          </p>
          <p className="p32 ft0">Can I update or correct my information?</p>
          <p className="p41 ft2">
            The rights you have to request updates or corrections to the information EFMAB collects depend on your
            relationship with EFMAB. Personnel may update or correct their information as detailed in our internal
            company employment policies.
          </p>
          <p className="p42 ft12">
            Customers have the right to request the restriction of certain uses and disclosures of personally
            identifiable information as follows. You can contact us in order to (1) update or correct your personally
            identifiable information, (2) change your preferences with respect to communications and other information
            you receive from us, or (3) delete the personally identifiable information maintained about you on our
            systems (subject to the following paragraph), by cancelling your account. Such updates, corrections, changes
            and deletions will have no effect on other information that we maintain, or information that we have
            provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or
            deletion. To protect your privacy and security, we may take reasonable steps (such as requesting a unique
            password) to verify your identity before granting you profile access or making corrections. You are
            responsible for maintaining the secrecy of your unique password and account information at all times.
          </p>
          <p className="p43 ft12">
            You should be aware that it is not technologically possible to remove each and every record of the
            information you have provided to us from our system. The need to back up our systems to protect information
            from inadvertent loss means that a copy of your information may exist in a<nobr>non-erasable</nobr> form
            that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal
            information stored in databases we actively use, and other readily searchable media will be updated,
            corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically
            practicable.
          </p>
          <p className="p44 ft2">
            If you are an end user and wish to update, delete, or receive any information we have about you, you may do
            so by contacting the organization of which you are a customer.
          </p>
        </div>
        <div id="id4_2">
          <p className="p0 ft0">Personnel</p>
        </div>
      </div>
      <div id="page_5">
        <div id="id5_1">
          <p className="p45 ft2">
            If you are a EFMAB worker or applicant, we collect information you voluntarily provide to us. We use the
            information collected for Human Resources purposes in order to administer benefits to workers and screen
            applicants.
          </p>
          <p className="p46 ft12">
            You may contact us in order to (1) update or correct your information, (2) change your preferences with
            respect to communications and other information you receive from us, or (3) receive a record of the
            information we have relating to you. Such updates, corrections, changes and deletions will have no effect on
            other information that we maintain, or information that we have provided to third parties in accordance with
            this Privacy Policy prior to such update, correction, change or deletion.
          </p>
          <p className="p47 ft0">Sale of Business</p>
          <p className="p34 ft12">
            We reserve the right to transfer information to a third party in the event of a sale, merger or other
            transfer of all or substantially all of the assets of EFMAB or any of its Corporate Affiliates (as defined
            herein), or that portion of EFMAB or any of its Corporate Affiliates to which the Service relates, or in the
            event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy,
            reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this
            Privacy Policy.
          </p>
          <p className="p48 ft0">Affiliates</p>
          <p className="p49 ft3">
            We may disclose information (including personal information) about you to our Corporate Affiliates. For
            purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or
            indirectly controls, is controlled by or is under common control with EFMAB, whether by ownership or
            otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by
            those Corporate Affiliates in accordance with the terms of this Privacy Policy.
          </p>
          <p className="p35 ft0">Governing Law</p>
          <p className="p50 ft3">
            This Privacy Policy is governed by the laws of Sweden without regard to its conflict of laws provision. You
            consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between
            the parties under or in connection with this Privacy Policy except for those individuals who may have rights
            to make claims under Privacy Shield, or the
            <nobr>Swiss-US</nobr> framework.
          </p>
          <p className="p51 ft2">
            The laws of Sweden, excluding its conflicts of law rules, shall govern this Agreement and your use of the
            app. Your use of the app may also be subject to other local, state, national, or international laws.
          </p>
          <p className="p52 ft3">
            By using EFMAB or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not
            agree to this Privacy Policy, you should not engage with our website, or use our services. Continued use of
            the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do
            not significantly affect the use or disclosure of your personal information will mean that you accept those
            changes.
          </p>
          <p className="p35 ft0">Your Consent</p>
          <p className="p53 ft3">
            We've updated our Privacy Policy to provide you with complete transparency into what is being set when you
            visit our site and how it's being used. By using our app, registering an account, or making a purchase, you
            hereby consent to our Privacy Policy and agree to its terms.
          </p>
        </div>
        <div id="id5_2">
          <p className="p0 ft0">Links to Other Websites</p>
        </div>
      </div>
      <div id="page_6">
        <div id="id6_1">
          <p className="p54 ft12">
            This Privacy Policy applies only to the Services. The Services may contain links to other websites not
            operated or controlled by EFMAB. We are not responsible for the content, accuracy or opinions expressed in
            such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by
            us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy
            is no longer in effect. Your browsing and interaction on any other website, including those that have a link
            on our platform, is subject to that website’s own rules and policies. Such third parties may use their own
            cookies or other methods to collect information about you.
          </p>
          <p className="p47 ft0">Cookies</p>
          <p className="p38 ft12">
            EFMAB uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece
            of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the
            performance and functionality of our app but are <nobr>non-essential</nobr> to their use. However, without
            these cookies, certain functionality like videos may become unavailable or you would be required to enter
            your login details every time you visit the app as we would not be able to remember that you had logged in
            previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you
            may not be able to access functionality on our website correctly or at all. We never place Personally
            Identifiable Information in Cookies.
          </p>
          <p className="p55 ft0">Blocking and disabling cookies and similar technologies</p>
          <p className="p56 ft3">
            Wherever you're located you may also set your browser to block cookies and similar technologies, but this
            action may block our essential cookies and prevent our website from functioning properly, and you may not be
            able to fully utilize all of its features and services. You should also be aware that you may also lose some
            saved information (e.g. saved login details, site preferences) if you block cookies on your browser.
            Different browsers make different controls available to you. Disabling a cookie or category of cookie does
            not delete the cookie from your browser, you will need to do this yourself from within your browser, you
            should visit your browser's help menu for more information.
          </p>
          <p className="p32 ft0">Kids' Privacy</p>
          <p className="p57 ft3">
            We do not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
            child has provided Us with Personal Data, please contact Us. If We become aware that We have collected
            Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to
            remove that information from Our servers.
          </p>
          <p className="p2 ft0">Changes To Our Privacy Policy</p>
          <p className="p50 ft12">
            We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they
            accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
            example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to
            review them before they go into effect. Then, if you continue to use the Service, you will be bound by the
            updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete
            your account.
          </p>
          <p className="p47 ft0">
            <nobr>Third-Party</nobr> Services
          </p>
        </div>
        <div id="id6_2">
          <p className="p0 ft2">
            We may display, include or make available <nobr>third-party</nobr> content (including data, information,
            applications and other products services) or provide links to <nobr>third-party</nobr> websites or services
            ("Third- Party Services").
          </p>
        </div>
      </div>
      <div id="page_7">
        <div id="id7_1">
          <p className="p58 ft3">
            You acknowledge and agree that EFMAB shall not be responsible for any
            <nobr>Third-Party</nobr> Services, including their accuracy, completeness, timeliness, validity, copyright
            compliance, legality, decency, quality or any other aspect thereof. EFMAB does not assume and shall not have
            any liability or responsibility to you or any other person or entity for any
            <nobr>Third-Party</nobr> Services.
          </p>
          <p className="p59 ft2">
            <nobr>Third-Party</nobr> Services and links thereto are provided solely as a convenience to you and you
            access and use them entirely at your own risk and subject to such third parties' terms and conditions.
          </p>
          <p className="p32 ft0">Tracking Technologies</p>
          <p className="p60 ft1">
            <span className="ft4">●</span>
            <span className="ft8">Google Maps API</span>
          </p>
          <p className="p61 ft2">
            Google Maps API is a robust tool that can be used to create a custom map, a searchable map,
            <nobr>check-in</nobr> functions, display live data synching with location, plan routes, or create a mashup
            just to name a few.
          </p>
          <p className="p62 ft1">
            Google Maps API may collect information from You and from Your Device for security purposes.
          </p>
          <p className="p63 ft1">Google Maps API collects information that is held in accordance with its Privacy Policy</p>
          <p className="p7 ft1">
            <span className="ft4">●</span>
            <span className="ft8">Local Storage</span>
          </p>
          <p className="p64 ft3">
            Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for storing
            <nobr>client-side</nobr> data. Web storage supports persistent data storage, similar to cookies but with a
            greatly enhanced capacity and no information stored in the HTTP request header.
          </p>
          <p className="p7 ft1">
            <span className="ft4">●</span>
            <span className="ft8">Sessions</span>
          </p>
          <p className="p65 ft2">
            $elnombre uses "Sessions" to identify the areas of our website that you have visited. A Session is a small
            piece of data stored on your computer or mobile device by your web browser.
          </p>
          <p className="p66 ft13">Information about General Data Protection Regulation (GDPR)</p>
          <p className="p67 ft3">
            We may be collecting and using information from you if you are from the European Economic Area (EEA), and in
            this section of our Privacy Policy we are going to explain exactly how and why is this data collected, and
            how we maintain this data under protection from being replicated or used in the wrong way.
          </p>
          <p className="p35 ft0">What is GDPR?</p>
          <p className="p68 ft2">
            GDPR is an <nobr>EU-wide</nobr> privacy and data protection law that regulates how EU residents' data is
            protected by companies and enhances the control the EU residents have, over their personal data.
          </p>
          <p className="p69 ft3">
            The GDPR is relevant to any globally operating company and not just the
            <nobr>EU-based</nobr> businesses and EU residents. Our customers’ data is important irrespective of where
            they are located, which is why we have implemented GDPR controls as our baseline standard for all our
            operations worldwide.
          </p>
        </div>
        <div id="id7_2">
          <p className="p0 ft0">What is personal data?</p>
        </div>
      </div>
      <div id="page_8">
        <div id="id8_1">
          <p className="p37 ft12">
            Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of
            information that could be used on its own, or in combination with other pieces of information, to identify a
            person. Personal data extends beyond a person’s name or email address. Some examples include financial
            information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual
            orientation, and ethnicity.
          </p>
          <p className="p48 ft1">The Data Protection Principles include requirements such as:</p>
          <p className="p70 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              Personal data collected must be processed in a fair, legal, and transparent way and should only be used in
              a way that a person would reasonably expect.
            </span>
          </p>
          <p className="p71 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              Personal data should only be collected to fulfil a specific purpose and it should only be used for that
              purpose. Organizations must specify why they need the personal data when they collect it.
            </span>
          </p>
          <p className="p7 ft7">
            <span className="ft4">●</span>
            <span className="ft6">Personal data should be held no longer than necessary to fulfil its purpose.</span>
          </p>
          <p className="p11 ft2">
            <span className="ft4">●</span>
            <span className="ft5">
              People covered by the GDPR have the right to access their own personal data. They can also request a copy
              of their data, and that their data be updated, deleted, restricted, or moved to another organization.
            </span>
          </p>
          <p className="p2 ft0">Why is GDPR important?</p>
          <p className="p72 ft12">
            GDPR adds some new requirements regarding how companies should protect individuals' personal data that they
            collect and process. It also raises the stakes for compliance by increasing enforcement and imposing greater
            fines for breach. Beyond these facts it's simply the right thing to do. At EFMAB we strongly believe that
            your data privacy is very important and we already have solid security and privacy practices in place that
            go beyond the requirements of this new regulation.
          </p>
          <p className="p73 ft11">Individual Data Subject's Rights - Data Access, Portability and Deletion</p>
          <p className="p74 ft3">
            We are committed to helping our customers meet the data subject rights requirements of GDPR. EFMAB processes
            or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation and
            personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in
            accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days.
          </p>
          <p className="p75 ft12">
            We are aware that if you are working with EU customers, you need to be able to provide them with the ability
            to access, update, retrieve and remove personal data. We got you! We've been set up as self service from the
            start and have always given you access to your data and your customers data. Our customer support team is
            here for you to answer any questions you might have about working with the API.
          </p>
          <p className="p47 ft0">Contact Us</p>
          <p className="p76 ft1">Don't hesitate to contact us if you have any questions.</p>
          <p className="p77 ft1">
            <span className="ft4">●</span>
            <span className="ft8">Via Email: info@efmab.se</span>
          </p>
          <p className="p7 ft1">
            <span className="ft4">●</span>
            <span className="ft8">Via this Link: </span>
            <nobr>https://www.efmab.se/en/contact-us/</nobr>
          </p>
        </div>
        <div id="id8_2">
          <p className="p0 ft14">
            <a href="http://www.tcpdf.org">Powered by TCPDF (www.tcpdf.org)</a>
          </p>
        </div>
      </div>
    </>
  );
}
