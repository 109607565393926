import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';

import history from '../history';

import Content from '../component/Content/Content';
import FlashMessage from '../component/helpComponent/FlashMessage';
import PrivacyPolicy from '../component/Content/PrivacyPolicy';
import SecuredRoute from './SecuredRoute';
import PublicRoute from './PublicRoute';

import SignIn from '../component/auth/SignIn';
import ForgotPassword from '../component/auth/ForgotPassword';
import CheckEmail from '../component/auth/CheckEmail';
import ResetPassword from '../component/auth/ResetPassword';

import Dashboard from '../component/Dashboard/Dashboard';
import Schedule from '../component/Schedule/Main';
import WrapperSettings from '../component/Content/WrapperSettings';
import WrapperReports from '../component/Content/WrapperReports';

import Settings from '../component/Settings/Settings';
import AddManager from '../component/AddManager/AddManager';
import EditManager from '../component/EditManager/EditManager';
import ManagerAccount from '../component/ManagerAccount/ManagerAccount';

import Employees from '../component/Employees/Employees';
import AddEmployee from '../component/Employees/AddEmployee';
import EditEmployee from '../component/Employees/EditEmployee';
import EditProfile from '../component/EditProfile/EditProfile';
import EmployeeAccount from '../component/EployeeAccount/EmployeeAccount';

import Organization from '../component/Organization/Organization';
import CreateOrganazation from '../component/Organization/CreateOrganization';
import UpdateOrganization from '../component/Organization/UpdateOrganization';
import OrganizationDetails from '../component/Organization/OrganizationDetails/OrganizationDetails';

import Administrator from '../component/Administrators/Administrator';
import AddAdministrator from '../component/Administrators/AddAdministrator';
import EditAdministrator from '../component/Administrators/EditAdministrator';
import AdminAccount from '../component/AdminAccount/AdminAccount';

import News from '../component/News/News';
import AddNews from '../component/News/AddNews';
import EditNews from '../component/News/EditNews';
import NewsDetails from '../component/News/NewsDetails';

import ReportsEmployeesTable from '../component/Reports/Employees/ReportsEmployeesTable';
import ReportEmployeeDetails from '../component/Reports/Employees/ReportEmployeeDetails';
import ReportsAreasTable from '../component/Reports/Areas/ReportsAreasTable';
import ReportsSalaryTable from '../component/Reports/Salary/ReportsSalaryTable';

import Leaves from '../component/Leaves/LeavesTable';

import PasswordEditor from '../component/PasswordEditor/PasswordEditor';

const googleMapsApiKey = 'AIzaSyD9Jg1fv8sb_saiWvH4EOHDytV2MQe9lak';

export default function Routes({ store }) {
  if (window.location.pathname === '/') {
    history.push('/dashboard');
  }
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PublicRoute path="/login" title="Login">
            <SignIn />
          </PublicRoute>
          <PublicRoute path="/forgot-password" title={t('forgot_password')}>
            <ForgotPassword />
          </PublicRoute>
          <PublicRoute path="/check-email" title="Check email">
            <CheckEmail />
          </PublicRoute>
          <Route
            path="/create-password"
            render={(props) => (!props.location.search ? <Redirect to="/login" /> : <ResetPassword />)}
          />
          <Route
            path="/privacy-policy"
            render={() => <PrivacyPolicy />}
          />

          <Content history={history}>
            <FlashMessage />
            <SecuredRoute exact path="/dashboard" title="Dashboard">
              <Dashboard />
            </SecuredRoute>
            <SecuredRoute path="/schedule" title={t('schedule')}>
              <Schedule />
            </SecuredRoute>

            <SecuredRoute permission="route.reports" exact path="/reports" title={t('reports')}>
              <WrapperReports />
            </SecuredRoute>
            <SecuredRoute permission="route.reports" exact path="/reports/employees" title="Employee statistics">
              <ReportsEmployeesTable />
            </SecuredRoute>
            <SecuredRoute permission="route.reports" path="/reports/employees/employee/:id" title="Employee statistics">
              <ReportEmployeeDetails />
            </SecuredRoute>
            <SecuredRoute permission="route.reports" exact path="/reports/areas" title="Area statistics">
              <ReportsAreasTable />
            </SecuredRoute>
            <SecuredRoute permission="route.reports" exact path="/reports/salary" title={t('salary')}>
              <ReportsSalaryTable />
            </SecuredRoute>

            <SecuredRoute permission="route.settings" exact path="/settings" title={t('settings')}>
              <WrapperSettings />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" exact path="/settings/organization" title={t('organizations')}>
              <Organization />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/organization/add" title={t('organizations')}>
              <CreateOrganazation 
                googleMapURL={
                  'https://maps.googleapis.com/maps/api/js?key=' +
                  googleMapsApiKey +
                  '&libraries=geometry,drawing,places&language=en'
                }
                loadingElement={<div style={{ height: `100%` }} />}
              />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/organization/update/:id" title={t('organizations')}>
              <UpdateOrganization 
                googleMapURL={
                  'https://maps.googleapis.com/maps/api/js?key=' +
                  googleMapsApiKey +
                  '&libraries=geometry,drawing,places&language=en'
                }
                loadingElement={<div style={{ height: `100%` }} />}
              />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/organization/account/:id" title={t('organizations')}>
              <OrganizationDetails 
                googleMapURL={
                  'https://maps.googleapis.com/maps/api/js?key=' +
                  googleMapsApiKey +
                  '&libraries=geometry,drawing,places&language=en'
                }
                loadingElement={<div style={{ height: `100%` }} />}
              />
            </SecuredRoute>

            <SecuredRoute
              permission="route.settings.admins"
              exact
              path="/settings/administrators"
              title={t('administrators')}
            >
              <Administrator />
            </SecuredRoute>
            <SecuredRoute permission="route.settings.admins" path="/settings/administrators/add" title={t('administrators')}>
              <AddAdministrator />
            </SecuredRoute>
            <SecuredRoute
              permission="route.settings.admins"
              path="/settings/administrators/edit/:id"
              title={t('administrators')}
            >
              <EditAdministrator />
            </SecuredRoute>
            <SecuredRoute
              permission="route.settings.admins"
              exact
              path="/settings/administrators/admin/:id"
              title={t('administrators')}
            >
              <AdminAccount />
            </SecuredRoute>

            <SecuredRoute permission="route.settings" exact path="/settings/managers" title={t('managers')}>
              <Settings />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/managers/add" title={t('managers')}>
              <AddManager />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/managers/edit/:id" title={t('managers')}>
              <EditManager />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/managers/manager/:id" title={t('managers')}>
              <ManagerAccount />
            </SecuredRoute>

            <SecuredRoute permission="route.settings" exact path="/settings/employees" title={t('employees')}>
              <Employees />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/employees/add" title={t('employees')}>
              <AddEmployee />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/employees/edit/:id" title={t('employees')}>
              <EditEmployee />
            </SecuredRoute>
            <SecuredRoute permission="route.settings" path="/settings/employees/employee/:id" title={t('employees')}>
              <EmployeeAccount />
            </SecuredRoute>

            <SecuredRoute permission="route.news" exact path="/news" title={t('news')}>
              <News />
            </SecuredRoute>
            <SecuredRoute permission="route.news" path="/news/add" title={t('news')}>
              <AddNews />
            </SecuredRoute>
            <SecuredRoute permission="route.news" path="/news/edit/:id" title={t('news')}>
              <EditNews />
            </SecuredRoute>
            <SecuredRoute permission="route.news" path="/news/show/:id" title={t('news')}>
              <NewsDetails />
            </SecuredRoute>

            <SecuredRoute permission="route.leaves" exact path="/leaves" title={t('leaves')}>
              <Leaves />
            </SecuredRoute>
            <SecuredRoute exact path="/profile" title='Profile'>
              <EditProfile />
            </SecuredRoute>
            <SecuredRoute exact path="/profile/password" title='Profile'>
              <PasswordEditor />
            </SecuredRoute>
          </Content>
        </Switch>
      </Router>
    </Provider>
  );
}
