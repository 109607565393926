import axios from 'axios';

export function showLoader() {
  return {
    type: 'SHOW_LOADER',
  };
}

export function hideLoader() {
  return {
    type: 'HIDE_LOADER',
  };
}

export function showSmallLoader() {
  return {
    type: 'SHOW_SMALL_LOADER',
  };
}

export function hideSmallLoader() {
  return {
    type: 'HIDE_SMALL_LOADER',
  };
}

export function showFlashMessage() {
  return {
    type: 'SHOW_FLASH_MESSAGE',
  };
}

export function hideFlashMessage() {
  return {
    type: 'HIDE_FLASH_MESSAGE',
  };
}
export function toggleStatisticsTab() {
  return {
    type: 'TOGGLE_STATISTICS_TAB',
  };
}

export function getNotifications() {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/notifications`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_NOTIFICATIONS',
          notifications: response.data.data,
        });
      })
      .catch((e) => {
        console.error(error);
      });
  };
}

export function requestNotification({ data }) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/api/v1/change-status/request`,
      data,
    };

    return axios(options)
      .then((response) => {
        dispatch(getNotifications());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function deleteNotification({ id }) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/notification/${id}/delete`,
    };

    return axios(options)
      .then((response) => {
        dispatch(getNotifications());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
