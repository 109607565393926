import React from "react";
import { Component } from "react";
import { withTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class StaffingOptimization extends Component {
  render() {
    const {t} = this.props;
    return (
      <div className="staffing-wrapper">
        <h6 className="dashboard-table__title">{t('staffing_optimization')}</h6>
        <div className="dashboard-table-container">
          <CircularProgressbar
            className="dashboard-circle-graph"
            styles={{
              path: {
                stroke: "#0B9444",
                strokeLinecap: "butt",
                transition: "stroke-dashoffset 0.5s ease 0s",
                transformOrigin: "center center",
              },
              trail: {
                stroke: "#A4D8BA",
                strokeLinecap: "butt",
              },
              text: {
                fill: "#212529",
              },
            }}
            value={12}
            text={`${12}%`}
          />
        </div>
        <ul className="dashboard-table-info column">
          <li className="dashboard-table-info__text">{t('staffing_accuracy')}</li>
          <li className="dashboard-table-info__text">{t('optimal_hours')}</li>
        </ul>
      </div>
    );
  }
}

export default withTranslation()(StaffingOptimization)
