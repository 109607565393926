import axios from 'axios';
import { showLoader, hideLoader, showSmallLoader, hideSmallLoader } from './viewActionCreators';

export function getEmployeeStatistics({ params }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/report/employees${params}`,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_EMPLOYEE_STATISTICS',
          employeeStatistics: response.data.data,
          meta: response.data.meta,
        });
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        dispatch(hideSmallLoader());
      });
  };
}

export function getAreasStatistics({ params }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/areas/working-time${params}`,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_AREAS_STATISTICS',
          areasStatistics: response.data.data,
          meta: response.data.meta,
        });
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        dispatch(hideSmallLoader());
      });
  };
}

export function getEmployeeDetailedStatistics({ params, loader = false, id }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/report/employees/${id}${params}`,
    };

    if (loader) dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_EMPLOYEE_DETAILED_STATISTICS',
          employeeDetailedStatistics: response.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function getDailyReport({ params }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/report/daily${params}`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_DAILY_REPORT',
          dailyReport: response.data.data,
        });
      })
  };
}

export function approveHours({ id, data, handleResponse }) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/employee/${id}/hours`,
      data,
    };
    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        handleResponse(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleResponse(error.response);
        dispatch(hideLoader());
      });
  };
}
