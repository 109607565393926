import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';
import Avatar from 'react-avatar';
import moment from 'moment';
import qs from 'query-string';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import * as reportsActionCreators from '../../../actions/reportsActionCreators';
import * as scheduleActionCreators from '../../../actions/scheduleActionCreators';
import Pagination from '../../helpComponent/Pagination';
import SmallLoader from '../../helpComponent/SmallLoader';
import SidebarReport from '../SidebarReport';
import RangeDatePicker from '../../helpComponent/RangeDatePicker';
import { convertSecondsToHours } from '../../../Utils/helpers';

const START_DATE = 'YYYY-MM-DDT00:00:00';
const END_DATE = 'YYYY-MM-DDT23:59:59';

class ReportsEmployeesTable extends Component {
  constructor() {
    super();
    this.state = {
      newDateFromSidebar: undefined,
      startDate: null,
      endDate: null,
      pagination: {
        page: 1,
        limit: 10,
        name: '',
        area_id: '',
        start: moment().add(-30, 'days').format(START_DATE),
        end: moment().format(END_DATE),
      },
      selectedRow: {},
      selectedTableDotsId: null,
      typingTimeout: 0,
    };
    this.tableDotsDropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });

    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });
    const startDate = moment(pagination.start).toDate();
    const endDate = moment(pagination.end).toDate();

    this.setState({ pagination, startDate, endDate }, () => {
      let params = `?${qs.stringify(pagination)}`;
      this.props.reportsActionCreators.getEmployeeStatistics({ params });
    });
    this.props.scheduleActionCreators.getAllAreas();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  handlePageChange = (page) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.page = page;
    let params = `?${qs.stringify(pagination)}`;
    this.props.reportsActionCreators.getEmployeeStatistics({ params });
    history.push({ search: params });
  };

  handleSelect = (e) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.limit = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;
    this.props.reportsActionCreators.getEmployeeStatistics({ params });
    history.push({ search: params });
  };

  handleChangeSearch = (e) => {
    const self = this;
    const { pagination } = this.state;
    const { history } = this.props;

    pagination.name = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      pagination,
      typingTimeout: setTimeout(function () {
        self.props.reportsActionCreators.getEmployeeStatistics({ params });
      }, 500),
    });
    history.push({ search: params });
  };

  clearSearch = () => {
    const { pagination } = this.state;

    pagination.page = 1;
    pagination.name = '';
    let params = `?${qs.stringify(pagination)}`;

    this.props.reportsActionCreators.getEmployeeStatistics({ params });
    this.props.history.push({ search: params });
  };

  handleChangeDate = ({ startDate, endDate }) => {
    const { pagination } = this.state;
    const { history } = this.props;

    pagination.start = moment(startDate).format(START_DATE);
    pagination.end = moment(endDate).format(END_DATE);
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (!!startDate && !!endDate) {
      this.props.reportsActionCreators.getEmployeeStatistics({ params });
      history.push({ search: params });
    }
  };

  handleChangeSelect = (selectedId) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.page = 1;
    pagination.area_id = selectedId.value;
    let params = `?${qs.stringify(pagination)}`;
    this.props.reportsActionCreators.getEmployeeStatistics({ params });
    history.push({ search: params });
  };

  setSelectedRow = (selectedRow) => {
    if (selectedRow.user_id === this.state.selectedRow.user_id) {
      this.setState({ selectedRow: {}, newDateFromSidebar: undefined });
    } else {
      this.setSidebarCalendar(selectedRow.user_id, this.state.newDateFromSidebar);
      this.setState({ selectedRow });
    }
  };

  handleClickOutside = (e) => {
    const { selectedTableDotsId } = this.state;

    if (
      !this.tableDotsDropdownRef.current?.contains(e.target) &&
      +e.target.closest('button[id]')?.id !== selectedTableDotsId
    ) {
      this.setState({ selectedTableDotsId: null });
    }
  };

  toggleTableDotsDropdown = (e, id) => {
    e.stopPropagation();
    this.setState({ selectedTableDotsId: this.state.selectedTableDotsId ? null : id });
  };

  setSidebarCalendar = (id, date = undefined) => {
    const start = moment(date).startOf('month').format('YYYY-MM-DD');
    const end = moment(date).endOf('month').format('YYYY-MM-DD');
    let params = `?${qs.stringify({ start, end })}`;

    this.props.reportsActionCreators.getEmployeeDetailedStatistics({
      id,
      params,
    });
    this.setState({ newDateFromSidebar: date });
  };

  render() {
    const { employeeStatistics, meta, allAreas, employeeDetailedStatistics, t } = this.props;
    const { startDate, endDate, pagination, selectedRow, selectedTableDotsId } = this.state;
    let areaList = allAreas?.map((item) => {
      return { label: `${item.organization?.name} - ${item.name}`, value: item.id };
    });
    const selectedRowExists = Object.keys(selectedRow).length !== 0;

    return (
      <div className="d-flex">
        <div className="content">
          <div className="settings-wrapper d-flex">
            <div className={`settings-table reports-table ${selectedRowExists && 'reports-table-details'}`}>
              <div className="reports-table-header">
                <h6 className="reports-table-header__text">{t('statistics')}</h6>
                <div className="reports-table-header-items">
                  <div className="reports-table-header-items">
                    <RangeDatePicker
                      startDate={startDate}
                      endDate={endDate}
                      className="bg-light-grey"
                      onChange={this.handleChangeDate}
                    />
                  </div>
                  <div className="reports-table-header-items">
                    <div className="reports-table-header-search">
                      <div className="reports-table-header-search-wrapper">
                        <svg
                          className={`reports-table-header-search__icon`}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.7">
                            <path
                              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                              stroke="#212529"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.9999 20.9999L16.6499 16.6499"
                              stroke="#212529"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                        <input
                          className={`reports-table-header-search__input`}
                          value={pagination.name}
                          onChange={this.handleChangeSearch}
                          type="text"
                        />
                        {pagination.name && (
                          <svg
                            onClick={this.clearSearch}
                            className={`reports-table-header-search__icon clear`}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 5L5 15"
                              stroke="#646669"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5 5L15 15"
                              stroke="#646669"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    <Select
                      className={`addManager-left__form-input-wrapper-items__select reports-select`}
                      options={areaList}
                      value={areaList?.find((el) => {
                        if (el.value == pagination.area_id) {
                          return el.label;
                        }
                      })}
                      placeholder={t('filter_by_areas')}
                      name="areas"
                      noOptionsMessage={() => 'No areas'}
                      onChange={this.handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
              <table className="table">
                <thead className="settings-table-thead reports-table-thead">
                  <tr>
                    <th scope="col">{t('employees')}</th>
                    <th scope="col">{t('day')}</th>
                    <th scope="col">{t('night')}</th>
                    <th scope="col">{t('weekend')}</th>
                    <th scope="col">{t('vacation')}</th>
                    <th scope="col">{t('total')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.showSmallLoader ? (
                    <tr>
                      <td colSpan="7">
                        <SmallLoader />
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      {employeeStatistics?.length !== 0 ? (
                        <Fragment>
                          {employeeStatistics?.map((item, index) => {
                            const isShowDropdown = item.user_id === selectedTableDotsId;

                            return (
                              <tr
                                className={`settings-table__tr ${
                                  selectedRow.user_id === item.user_id ? 'settings-table__tr-selected' : ''
                                }`}
                                key={index}
                                onClick={() => this.setSelectedRow(item)}
                              >
                                <td className="settings-table-items" scope="row">
                                  <Avatar
                                    name={`${item.first_name} ${item.last_name}`}
                                    size="36"
                                    round="50%"
                                  />
                                  <div className="settings-table-items-info">
                                    <Link
                                      to={`/settings/employees/employee/${item.user_id}`}
                                      className="settings-table-items-info__name"
                                    >
                                      {item.first_name} {item.last_name}
                                    </Link>
                                    <p className="settings-table-items-info__id"># {item.user_id}</p>
                                  </div>
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.daily_seconds)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.nightly_seconds)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.weekend_seconds)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.total_vacations)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.total_seconds)} {t('h')}
                                </td>
                                <td className="text-right">
                                  <button
                                    id={item.user_id}
                                    className="reports-table__dotsBtn"
                                    onClick={(e) => this.toggleTableDotsDropdown(e, item.user_id)}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g opacity="0.7">
                                        <path
                                          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                          stroke="#212529"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                          stroke="#212529"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                          stroke="#212529"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                    </svg>
                                  </button>
                                  <CSSTransition in={isShowDropdown} timeout={300} classNames="show" unmountOnExit>
                                    <div
                                      onClick={(e) => e.stopPropagation()}
                                      ref={this.tableDotsDropdownRef}
                                      className="table-dots-dropdown"
                                    >
                                      <Link
                                        to={`/reports/employees/employee/${item.user_id}`}
                                        className="table-dots-dropdown__item"
                                      >
                                        {t('detailed_report')}
                                      </Link>
                                    </div>
                                  </CSSTransition>
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="7">
                            <p className="settings-table-emptyText">{t('dont_have_employees')}</p>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
              <div className="settings-table-pagination reports-table-pagination">
                <Pagination
                  count={meta?.last_page}
                  handlePageChange={this.handlePageChange}
                  activePage={this.state.pagination.page}
                />
                <div className="settings-table-pagination-items">
                  <p className="settings-table-pagination__text">{t('view')}</p>
                  <select
                    className="settings-table-pagination__select"
                    onChange={this.handleSelect}
                    value={this.state.pagination.limit}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </select>{' '}
                  <p className="settings-table-pagination__text">{t('items_per_page')}</p>
                </div>
                <p className="settings-table-pagination__text">
                  {!!meta && meta.from} - {!!meta && meta.to} {t('out_of')} {!!meta && meta.total} {t('items')}
                </p>
              </div>
            </div>
            <CSSTransition in={selectedRowExists} timeout={300} classNames="detailed-report" unmountOnExit>
              <SidebarReport
                data={employeeDetailedStatistics}
                onMonthChange={this.setSidebarCalendar}
              />
            </CSSTransition>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employeeStatistics: state.reportsReducer.employeeStatistics,
    employeeDetailedStatistics: state.reportsReducer.employeeDetailedStatistics,
    meta: state.reportsReducer.meta,
    showSmallLoader: state.viewReducer.showSmallLoader,
    allAreas: state.scheduleReducer.allAreas,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActionCreators: bindActionCreators(reportsActionCreators, dispatch),
    scheduleActionCreators: bindActionCreators(scheduleActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportsEmployeesTable));
