import React from "react";

export default function RadioButton({
  text,
  handleChangeRadioBtn,
  isCheckedRadioBtn,
  ...rest
}) {
  return (
    <label className="container-radiobtn">
      <p className={`container-radiobtn__text`}>{text}</p>
      <input
        type="radio"
        // onChange={handleChangeRadioBtn}
        checked={isCheckedRadioBtn}
        {...rest}
      />
      <span className={`radiobtn-fake`}></span>
    </label>
  );
}
