import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import en from '../locales/en.json';
import sv from '../locales/sv.json';
import es from '../locales/es.json';

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
  es: {
    translation: es,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
