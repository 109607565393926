import { combineReducers } from 'redux';

import authReducer from './authReducer';
import managerReducer from './managerReducer';
import viewReducer from './viewReducer';
import employeeReducer from './employeeReducer';
import organizationReducer from './organizationReducer';
import administratorReducer from './administratorReducer';
import scheduleReducer from './scheduleReducer';
import reportsReducer from './reportsReducer';
import newsReducer from './newsReducer';
import leavesReducer from './leavesReducer';

const rootReducer = combineReducers({
  authReducer,
  managerReducer,
  viewReducer,
  employeeReducer,
  organizationReducer,
  administratorReducer,
  scheduleReducer,
  reportsReducer,
  newsReducer,
  leavesReducer,
});

export default rootReducer;
