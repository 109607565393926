import React from 'react';
import { Component, Fragment } from 'react';
import Table from './Table';
import { CSSTransition } from 'react-transition-group';
import Popup from '../helpComponent/Popup';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';

import { bindActionCreators } from 'redux';
import * as employeeActionCreators from '../../actions/employeeActionCreators';
import { connect } from 'react-redux';

class Employees extends Component {
  constructor() {
    super();
    this.state = {
      isShowMenu: false,
      isShowAgreePopup: false,
      employees: [],
      isChecked: false,
      isShowFilter: false,
      deleteId: '',
      pagination: {
        page: 1,
        limit: 10,
        employee: 'allEmployee',
        search: '',
      },
      typingTimeout: 0,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });
    this.setState({ pagination }, () => {
      let params = `?${qs.stringify(pagination)}`;
      this.props.employeeActionCreators.getEmployee({ params });
    });
    if (pagination.employee === 'allEmployee') {
      this.setState({
        isChecked: false,
      });
    } else if (pagination.employee === 'myEmployee') {
      this.setState({
        isChecked: true,
      });
    } else {
      this.setState({
        isChecked: false,
      });
    }
    this.setState({
      employees: this.props.employees,
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.setState({
        isShowFilter: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.employees !== prevProps.employees) {
      this.setState({
        employees: this.props.employees,
      });
    }
  }

  toggleFilter = () => {
    const { isShowFilter } = this.state;
    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  filterEmployee = () => {
    const { isChecked } = this.state;
    this.setState({
      isChecked: !isChecked,
    });
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.employee = isChecked ? 'allEmployee' : 'myEmployee';
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;
    this.props.employeeActionCreators.getEmployee({
      params,
    });
    history.push({ search: params });
  };

  handlePageChange = (page) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.page = page;
    let params = `?${qs.stringify(pagination)}`;
    this.props.employeeActionCreators.getEmployee({
      params,
    });
    history.push({ search: params });
  };

  handlePopup = (id) => {
    this.setState({
      isShowAgreePopup: !this.state.isShowAgreePopup,
      deleteId: id,
    });
  };

  handleDelete = () => {
    const { deleteId } = this.state;
    this.props.employeeActionCreators.deleteEmployee({
      id: deleteId,
      handleDeleteSuccess: this.handleDeleteSuccess,
    });
  };

  handleDeleteSuccess = (response) => {
    const { status } = response;
    const { pagination } = this.state;
    const { history } = this.props;
    if (status === 200) {
      this.setState({
        isShowAgreePopup: false,
        deleteId: '',
      });
      let params = `?${qs.stringify(pagination)}`;
      this.props.employeeActionCreators.getEmployee({
        params,
      });
      history.push({ search: params });
    }
  };

  handleChangeSearch = (e) => {
    const self = this;
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.search = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      pagination,
      typingTimeout: setTimeout(function () {
        self.props.employeeActionCreators.getEmployee({ params });
      }, 500),
    });
    history.push({ search: params });
  };

  handleSelect = (e) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.limit = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;
    this.props.employeeActionCreators.getEmployee({
      params,
    });
    history.push({ search: params });
  };

  toggleActivation = (id, isActive) => {
    let active = isActive === 1 ? 0 : 1;
    this.props.employeeActionCreators.toggleActivationManager({
      id,
      active,
      handleActivateSuccess: this.handleActivateSuccess,
    });
  };

  handleActivateSuccess = (response) => {
    const { status } = response;
    const { pagination } = this.state;
    const { history } = this.props;
    if (status === 200) {
      let params = `?${qs.stringify(pagination)}`;
      this.props.employeeActionCreators.getEmployee({
        params,
      });
      history.push({ search: params });
    }
  };

  render() {
    const { employees, meta, t } = this.props;
    const { isShowMenu, searchValue, isChecked, isShowFilter, isShowAgreePopup } = this.state;
    return (
      <Fragment>
        <CSSTransition in={isShowAgreePopup} timeout={300} classNames="show" unmountOnExit>
          <Popup
            type={t('delete')}
            text={t('sure_delete_employee')}
            onHandleClose={this.handlePopup}
            onHandleDelete={this.handleDelete}
          />
        </CSSTransition>
        <div className="settings full-height">
          <div className="content">
            <Table
              employees={employees}
              meta={meta}
              handleChangeSearch={this.handleChangeSearch}
              searchValue={this.state.pagination.search}
              handlePopup={this.handlePopup}
              filterEmployee={this.filterEmployee}
              isChecked={isChecked}
              isShowFilter={isShowFilter}
              toggleFilter={this.toggleFilter}
              wrapperRef={this.wrapperRef}
              handlePageChange={this.handlePageChange}
              handleSelect={this.handleSelect}
              toggleActivation={this.toggleActivation}
              page={this.state.pagination.page}
              selectValue={this.state.pagination.limit}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    employees: state.employeeReducer.employees,
    meta: state.employeeReducer.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    employeeActionCreators: bindActionCreators(employeeActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Employees));
