import React from "react";
import { Component } from "react";
import { withTranslation } from 'react-i18next';
import {
  LeaveApplications,
  UnassignedShifts,
  ShiftBookingsToApprove,
  NoticesOfinterest,
} from "../Svg/Svg";

class ShiftsInfo extends Component {
  render() {
    const {t} = this.props;
    return (
      <div className="dashboard-tables__shifts">
        <div className="dashboard-tables__shifts-items">
          <div className="dashboard-tables__shifts-items__icons">
            <LeaveApplications />
          </div>
          <div className="dashboard-tables__shifts-items-text">
            <p className="dashboard-tables__shifts-items__title">
              {t('leave_applications')}
            </p>
            <p className="dashboard-tables__shifts-items__quantity">0</p>
          </div>
        </div>

        <div className="dashboard-tables__shifts-items">
          <div className="dashboard-tables__shifts-items__icons">
            <NoticesOfinterest />
          </div>
          <div className="dashboard-tables__shifts-items-text">
            <p className="dashboard-tables__shifts-items__title">
              {t('notice_of_interest')}
            </p>
            <p className="dashboard-tables__shifts-items__quantity">0</p>
          </div>
        </div>

        <div className="dashboard-tables__shifts-items">
          <div className="dashboard-tables__shifts-items__icons">
            <ShiftBookingsToApprove />
          </div>
          <div className="dashboard-tables__shifts-items-text">
            <p className="dashboard-tables__shifts-items__title">
              {t('shift_bookings_approve')}
            </p>
            <p className="dashboard-tables__shifts-items__quantity">0</p>
          </div>
        </div>

        <div className="dashboard-tables__shifts-items">
          <div className="dashboard-tables__shifts-items__icons">
            <UnassignedShifts />
          </div>
          <div className="dashboard-tables__shifts-items-text">
            <p className="dashboard-tables__shifts-items__title">
              {t('unassigned_shifts')}
            </p>
            <p className="dashboard-tables__shifts-items__quantity">0</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ShiftsInfo)
