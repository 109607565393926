import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { EyeOn, EyeOff, Logo } from '../Svg/Svg';
import Checkbox from '../helpComponent/Checkbox';

import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../actions/authActionCreators';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPassword: false,
      email: '',
      password: '',
      errors: {},
    };
  }

  toggleInputType = () => {
    const { isShowPassword } = this.state;
    this.setState({ isShowPassword: !isShowPassword });
  };

  submitForm = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const data = {
      email,
      password,
    };
    this.props.authActionCreators.login({
      data,
      handleError: this.handleError,
    });
  };

  handleError = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { isShowPassword, errors } = this.state;
    const { t } = this.props;

    return (
      <div className="auth-container">
        <div className="auth-left">
          <Logo classLogo={'auth-left-logo'} />
          <div className="auth-greeting">
            <h1 className="auth-greeting__text">{t('hello_friend')}</h1>
            <h1 className="auth-greeting__text">{t('welcome_back')}</h1>
          </div>
        </div>
        <div className="auth-right">
          <form className="auth-form" onSubmit={this.submitForm}>
            <h1 className="auth-form__title">{t('sign_in_efmab')}</h1>
            <div className="auth-form-inputContainer">
              <p className="auth-form__label">{t('email_address')}</p>
              <div className={`auth-form__input ${errors.email ? 'error-input' : ''}`}>
                <input type="email" name="email" className="w-100" onChange={this.handleChange} />
              </div>
              {errors.email && <span className="error-input-text">{errors.email}</span>}
            </div>
            <div className="auth-form-inputContainer">
              <p className="auth-form__label">{t('password')}</p>
              <div className={`auth-form__input ${errors.password ? 'error-input' : ''}`}>
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  name="password"
                  className="w-100"
                  onChange={this.handleChange}
                />
                {isShowPassword ? (
                  <EyeOff toggleInputType={this.toggleInputType} />
                ) : (
                  <EyeOn toggleInputType={this.toggleInputType} />
                )}
              </div>
              {errors.password && <span className="error-input-text">{errors.password}</span>}
            </div>
            <div className="d-flex justify-content-between w-100">
              <div className="auth-form-remember">
                <Checkbox text={t('remember_me')} styles={{ fontSize: 14 }} />
              </div>
              <Link to="/forgot-password" className="auth-form-remember__link">
                {t('forgot_password')}
              </Link>
            </div>
            <button type="submit" className="auth-form__btn signIn">
              {t('sign_in')}
            </button>
            <Link to="/privacy-policy" className="auth-form-remember__link privacy">
              Privacy policy
            </Link>
          </form>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
  };
}

export default withTranslation()(connect(null, mapDispatchToProps)(SignIn));
