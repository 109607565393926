import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from 'react-avatar';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import * as authActionCreators from '../../actions/authActionCreators';
import Notifications from '../Notifications/Notifications';
import NavBar from './NavBar';
import { Logo, Bell, Mail, Search } from '../Svg/Svg';
import flagES from '../../images/es.svg';
import flagSV from '../../images/se.svg';
import flagEN from '../../images/gb.svg';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isSearch: false,
      isShowDropdown: false,
      isShowDropdownLang: false,
      isShowNotifications: false,
      language: 'EN',
    };
    this.wrapperRef = React.createRef();
    this.wrapperLangRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const lang = this.props.i18n.language;

    switch (lang) {
      case 'en':
      case 'sv':
      case 'es':
        this.setState({ language: lang.toUpperCase() });
        break;

      default:
        this.setState({ language: 'EN' });
        break;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.setState({
        isShowDropdown: false,
      });
    }
    if (this.wrapperLangRef.current && e.target.id !== 'lang-switcher' && e.target.id !== 'lang-arrow') {
      this.setState({
        isShowDropdownLang: false,
      });
    }
  };

  showSearchField = () => {
    const { isSearch } = this.state;
    this.setState({
      isSearch: !isSearch,
    });
  };

  logout = () => {
    this.props.authActionCreators.logout();
  };

  toggleNotifications = () => {
    this.setState({ isShowNotifications: !this.state.isShowNotifications });
  };

  changeLang = (lang) => {
    this.props.i18n.changeLanguage(lang);
    this.setState({ language: lang.toUpperCase() });
  };

  render() {
    const { myAccData, notifications, t } = this.props;
    const { isSearch, isShowDropdown, isShowDropdownLang, isShowNotifications, language } = this.state;
    return (
      <div className="header-container">
        <div className="header">
          <Link to="/dashboard">
            <svg width="96" height="27" viewBox="0 0 96 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M91.9168 15.382C91.9168 12.3713 90.0005 10.4846 87.4479 10.4846C85.1934 10.4846 82.9189 12.3788 82.9189 15.382C82.9189 18.3851 85.1734 20.2467 87.4479 20.2467C90.0055 20.2417 91.9168 18.3926 91.9168 15.382ZM95.9999 15.382C95.9999 20.5076 92.2425 24 88.1844 24C86.0125 24 84.2591 23.3477 82.9239 22.2438V23.5183H78.708V0H82.9189V8.53021C84.2591 7.38867 86.0125 6.73636 88.1794 6.73636C92.2299 6.73636 95.9949 10.2488 95.9949 15.387"
                fill="white"
              />
              <path
                d="M71.515 15.5406C71.515 12.581 69.2602 10.7263 66.9853 10.7263C64.4324 10.7214 62.5183 12.581 62.5183 15.5406C62.5183 18.5002 64.4324 20.3228 66.9853 20.3228C69.2401 20.3228 71.515 18.4928 71.515 15.5406ZM75.7265 23.5314H71.515V22.2736C70.1746 23.3662 68.4209 24 66.2538 24C62.2001 24 58.4346 20.5669 58.4346 15.5283C58.4346 10.4896 62.1926 7.02441 66.2538 7.02441C68.4234 7.02441 70.1771 7.66565 71.515 8.78783V7.50534H75.7265V23.5314Z"
                fill="white"
              />
              <path
                d="M56.6361 13.4648V23.4147H52.4516V14.325C52.4516 12.1905 51.0768 10.8941 49.3208 10.8941C47.083 10.8941 45.6129 12.4257 45.6129 15.7734V23.4147H41.3958V14.325C41.3958 12.1905 40.021 10.8941 38.2649 10.8941C36.0271 10.8941 34.6223 12.4257 34.6223 15.7734V23.4147H30.4102V7.5023H34.6273V8.80852C35.7462 7.69345 37.3116 7.02441 39.3938 7.02441C41.5337 7.02441 43.483 7.94833 44.632 9.63931C45.944 8.01204 47.8933 7.02441 50.4472 7.02441C53.7712 7.02441 56.6462 9.57559 56.6462 13.4648"
                fill="white"
              />
              <path
                d="M28.523 7.47542V10.9245H23.5484V23.4146H19.1999V10.9245H16.6958V7.48769H19.1999V6.03689C19.1999 2.30063 21.8359 0.000457582 25.6903 0.000457582C26.7031 -0.0110954 27.7054 0.196256 28.6213 0.606801L27.6227 4.02638C27.0932 3.84723 26.5369 3.74944 25.9749 3.73671C24.5599 3.73671 23.5381 4.53699 23.5381 6.03689V7.47542H28.523Z"
                fill="white"
              />
              <path
                d="M4.4079 13.67H12.2801C11.7236 11.6144 10.1569 10.7088 8.43373 10.7088C6.5459 10.6886 4.88746 11.9108 4.41559 13.67H4.4079ZM16.6957 16.656H4.27712C4.70278 18.9683 6.36953 20.3823 8.35937 20.3823C9.64148 20.3823 11.2339 20.2194 12.4108 18.2625L16.2572 19.0645C14.8186 22.4034 11.9134 24 8.35167 24C3.75659 24.0099 0 20.5748 0 15.5381C0 10.5015 3.75659 7.02441 8.42603 7.02441C12.7698 7.02441 16.5238 10.3312 16.688 15.2124L16.6957 16.656Z"
                fill="white"
              />
            </svg>
          </Link>
          <NavBar />
          <div className="header-items">
            {/* {!isSearch && <Search showSearchField={this.showSearchField} />}
                        <div className={`header-search ${isSearch ? 'active' : ''}`}>
                            <Search showSearchField={this.showSearchField} />
                            <input type="text" className="header-search__input" />
                        </div> */}
            {/* <div className="header-items__icons">
                            <Mail />
                        </div> */}
            <div className="position-relative">
              <div onClick={this.toggleNotifications} className="header-items__icons">
                <Bell />
                {!!notifications.length && <span className="header-items__notifier"></span>}
              </div>
              <CSSTransition in={isShowNotifications} timeout={300} classNames="show" unmountOnExit>
                <Notifications close={this.toggleNotifications} />
              </CSSTransition>
            </div>
            <div className="position-relative">
              <div
                onClick={() => this.setState({ isShowDropdownLang: !isShowDropdownLang })}
                className="header-items__lang"
                id="lang-switcher"
              >
                {language === 'EN' && <img src={flagEN} alt="ES" className="header-items__lang-flag mr-2" />}
                {language === 'SV' && <img src={flagSV} alt="SV" className="header-items__lang-flag mr-2" />}
                {language === 'ES' && <img src={flagES} alt="ES" className="header-items__lang-flag mr-2" />}
                {language}
                <svg
                  id="lang-arrow"
                  width="16"
                  height="24"
                  viewBox="0 0 16 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.7"
                    d="M4 10L8 14L12 10"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <CSSTransition in={isShowDropdownLang} timeout={300} classNames="show" unmountOnExit>
                <div ref={this.wrapperLangRef} className="header-items-dropdown" style={{ width: '130px' }}>
                  <p onClick={() => this.changeLang('en')} className="d-flex align-items-center">
                    <img src={flagEN} alt="EN" className="mr-2" />
                    English
                  </p>
                  <p onClick={() => this.changeLang('sv')} className="d-flex align-items-center">
                    <img src={flagSV} alt="SV" className="mr-2" />
                    Svenska
                  </p>
                  <p onClick={() => this.changeLang('es')} className="d-flex align-items-center">
                    <img src={flagES} alt="ES" className="mr-2" />
                    Espanol
                  </p>
                </div>
              </CSSTransition>
            </div>
            <div className="header-items-user">
              <Avatar
                name={`${!!myAccData && myAccData.first_name} ${!!myAccData && myAccData.last_name}`}
                className="header-items-user__avatar__text"
                round="50%"
                size="40"
              />
              <div className="header-items-user__info">
                <p className="header-items-user__info__name">
                  {!!myAccData && myAccData.first_name} {!!myAccData && myAccData.last_name}
                </p>
                <p className="header-items-user__info__type">{!!myAccData && myAccData.role.toLowerCase()}</p>
              </div>
              <button
                className="header-items-user__btn"
                onClick={() => {
                  this.setState({ isShowDropdown: !isShowDropdown });
                }}
                ref={this.wrapperRef}
              >
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L13 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              <CSSTransition in={isShowDropdown} timeout={300} classNames="show" unmountOnExit>
                <div className="header-items-dropdown">
                  <Link to="/profile">
                    <p>{t('edit_profile')}</p>
                  </Link>
                  <Link to="/profile/password">
                    <p>{t('change_password')}</p>
                  </Link>
                  <p onClick={this.logout}>{t('logout')}</p>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myAccData: state.authReducer.myAccData,
    notifications: state.viewReducer.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));
