import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { convertSecondsToHours } from '../../Utils/helpers';
import { Sum, Columns } from '../Svg/Svg';

class StatisticsTabBottomPannel extends Component {
    render() {
        const { statistics, tabName, onTabClick, tabWidth, statisticsTotalHours, t } = this.props;

        return (
            <div className="statistics-tab__main-statistics-bottom-pannel">
                <table
                    ref={this.table}
                    className={
                        tabName === 'general'
                            ? 'statistics-tab__table'
                            : 'statistics-tab__table--bordered'
                    }
                >
                    <tbody>
                        <tr>
                            <td>
                                <div className="statistics-tab__buttons">
                                    <button
                                        className={`${
                                            tabName === 'general'
                                                ? 'statistics-tab__buttons--active'
                                                : ''
                                        }`}
                                        name="general"
                                        onClick={onTabClick}
                                    >
                                        <Sum />
                                    </button>
                                    <button
                                        className={`${
                                            tabName === 'extended'
                                                ? 'statistics-tab__buttons--active'
                                                : ''
                                        }`}
                                        name="extended"
                                        onClick={onTabClick}
                                    >
                                        <Columns />
                                    </button>
                                </div>
                            </td>
                            {tabName === 'general' && (
                                <>
                                    <td
                                        style={{
                                            width: `${(tabWidth - 200) / 3}px`,
                                        }}
                                    >
                                        <p className="statistics-tab__main-statistics-title">
                                            {t('scheduled_hours')}
                                        </p>
                                        <p>
                                            {convertSecondsToHours(
                                                statisticsTotalHours.scheduledHours,
                                            )}
                                        </p>
                                    </td>
                                    <td
                                        style={{
                                            width: `${(tabWidth - 200) / 3}px`,
                                        }}
                                    >
                                        <p className="statistics-tab__main-statistics-title">
                                            {t('worked_hours')}
                                        </p>
                                        <p>
                                            {convertSecondsToHours(
                                                statisticsTotalHours.totalWorkedHours,
                                            )}
                                        </p>
                                    </td>
                                    <td
                                        style={{
                                            width: `${(tabWidth - 200) / 3}px`,
                                        }}
                                    >
                                        <p className="statistics-tab__main-statistics-title">
                                            {t('leave_hours')}
                                        </p>
                                        <p>
                                            {convertSecondsToHours(
                                                statisticsTotalHours.totalLeaves,
                                            )}
                                        </p>
                                    </td>
                                </>
                            )}
                            {tabName === 'extended' &&
                                statistics?.map((el, i, arr) => {
                                    const width = (tabWidth - 200) / arr.length;
                                    if (arr.length > 7) {
                                        return (
                                            <td
                                                key={i}
                                                style={{
                                                    width: `${width}px`,
                                                    backgroundColor:
                                                        moment().format('YYYY-MM-DD') === el.date &&
                                                        '#f0f0f0',
                                                }}
                                            >
                                                <div className="d-flex flex-column statistics-tab__table-cell">
                                                    <span>{moment(el.date).format('dd')}</span>
                                                    <span>{moment(el.date).format('Do')}</span>
                                                </div>
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td
                                                key={i}
                                                style={{
                                                    width: `${width}px`,
                                                    backgroundColor:
                                                        moment().format('YYYY-MM-DD') === el.date &&
                                                        '#f0f0f0',
                                                }}
                                            >
                                                <div className="statistics-tab__table-cell">
                                                    <span>{moment(el.date).format('dddd')}</span>
                                                    {', '}
                                                    <span>{moment(el.date).format('Do')}</span>
                                                </div>
                                            </td>
                                        );
                                    }
                                })}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(StatisticsTabBottomPannel);
