import React, { Fragment, useState } from 'react';

import enGb from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import {convertSecondsToHours} from '../../Utils/helpers'
import { useTranslation } from 'react-i18next';

registerLocale('en-gb', enGb);

export default function SidebarReport({ data, onMonthChange }) {
  const [startDate, setStartDate] = useState(new Date());
  const { t } = useTranslation();
  const total_sec = data.data?.reduce((accum, item) => accum + item.total_seconds, 0);

  const renderDayContents = (day, date) => {
    let hours = null;

    data.data?.map((item) => {
      const dateIsSame = moment(item.date).isSame(moment(date));
      if (dateIsSame) hours = convertSecondsToHours(item.total_seconds);
    });

    return (
      <Fragment>
        <span className="react-datepicker__day-name-day">{day}</span>
        {hours !== null && <span className="react-datepicker__day-name-hours">{hours}{t('h')}</span>}
      </Fragment>
    );
  };

  return (
    <div className="reports-detailed">
      <div className="reports-detailed__header">
        <div className="reports-detailed__header-left">
          {data.first_name} {data.last_name}
        </div>
      </div>

      <div className="reports-detailed__item">
        <div className="reports-detailed__item-header">
          <span className="text-uppercase">{t('monthly_report')}</span>
          <span>{t('total_time')}</span>
        </div>
        <div className="reports-detailed__item-content">{convertSecondsToHours(total_sec)}{t('h')}</div>
      </div>

      <button
        onClick={() => {
          onMonthChange(data.user_id);
          setStartDate(new Date());
        }}
        className="reports-detailed__btn"
      >
        {t('today')}
      </button>
      <DatePicker
        locale="en-gb"
        selected={startDate}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        dateFormatCalendar="MMMM"
        renderDayContents={renderDayContents}
        calendarClassName="reports-detailed__calendar"
        onMonthChange={(newDate) => {
          onMonthChange(data.user_id, newDate);
          setStartDate(null);
        }}
        inline
        disabled
        disabledKeyboardNavigation
      />
    </div>
  );
}
