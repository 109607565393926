import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Routes from './routes/Routes';
import reportWebVitals from './reportWebVitals';
import './sass/styles.scss';
import './sass/index.scss';
import store from './store';
// require("dotenv").config();
import { getCookie, deleteCookie } from './Cookie';
import axios from 'axios';
import Bus from './Utils/Bus';
import './Utils/Chart';
import './Utils/i18n';

if (getCookie('auth_token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie('auth_token');
}

window.flash = (message, type = 'success') => Bus.emit('flash', { message, type });

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      deleteCookie('auth_token');
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(<Routes store={store} />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
