export const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
      };

    case "FORGOT_PASSWORD":
      return {
        ...state,
      };

    case "LOGOUT":
      return {
        ...state,
      };

    case "RESET_PASSWORD":
      return {
        ...state,
      };

    case "GET_ACCOUNT":
      return {
        ...state,
        myAccData: action.myAccData,
      };

    default:
      return state;
  }
}
