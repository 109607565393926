import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import Edit from './Edit';
import FullPageLoader from '../helpComponent/FullPageLoader';

import { bindActionCreators } from 'redux';
import * as managerActionCreators from '../../actions/managerActionCreators';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class EditManager extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      id: '',
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.setState({
      id: this.props.match.params.id,
    });
    this.props.managerActionCreators.getManagerAcc({ id });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { id } = this.state;
    const { managerData, t } = this.props;
    return (
      <div>
        <div className="addManager">
          <div className="content addManager__background">
            <div className="addManager-wrapper">
              <button onClick={() => this.props.history.goBack()} className="settings-buttonBack">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('back')}
              </button>
              <Edit id={id} managerData={managerData} history={this.props.history} />
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoader: state.viewReducer.showLoader,
    managerData: state.managerReducer.managerData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    managerActionCreators: bindActionCreators(managerActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditManager));
