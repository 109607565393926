import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserTable from './UserTable';
import FullPageLoader from '../helpComponent/FullPageLoader';

import { bindActionCreators } from 'redux';
import * as employeeActionCreators from '../../actions/employeeActionCreators';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class EmployeeAccount extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.employeeActionCreators.getEmployeeAcc({ id });
  }
  render() {
    const { employeeData, t } = this.props;
    return (
      <div>
        <div className="userAccount">
          <div className="content">
            <div className="userAccount-wrapper">
              <button onClick={() => this.props.history.goBack()} className="settings-buttonBack">
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('back_to_table')}
              </button>
              <UserTable employeeData={employeeData} />
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employeeData: state.employeeReducer.employeeData,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    employeeActionCreators: bindActionCreators(employeeActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmployeeAccount));
