import React, { Component } from 'react';

export default class WrapperSettings extends Component {
  componentDidMount() {
    this.props.history.push('/settings/organization');
  }
  render() {
    return <></>;
  }
}
