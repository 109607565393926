import React, { Fragment, useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { subMinutes, addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function Tasks({
  tasks,
  errors,
  addTasks,
  deleteTasks,
  handleChangeTaskName,
  handleChangeTaskEnd,
  handleChangeTaskStart,
}) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="accordion" id="accordion6">
        <div className="">
          <div className="addShift-card-header d-flex justify-content-between align-items-center" id="headingSix">
            <div className="addShift-card-header-wrapper">
              <svg
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.7"
                  d="M1 0.75L5.5 5.25L10 0.75"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <button
                className="addShift-card-header__btn"
                type="button"
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                {t('tasks')}
              </button>
            </div>
            <button className="addShift-card-header__btn" type="button" onClick={addTasks}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 4.16699V15.8337"
                  stroke="#646669"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.16602 10H15.8327"
                  stroke="#646669"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion6">
            <div className="addShift-card-body">
              <TransitionGroup>
                {tasks.map((item, index) => {
                  return (
                    <CSSTransition key={index} timeout={500} classNames="list-anim">
                      <div className="accordion" id={`accordion${index}`}>
                        <div className="mb-2">
                          <div
                            className="addShift-card-header d-flex justify-content-between align-items-center"
                            id={`heading${index}`}
                          >
                            <div className="addShift-card-header-wrapper">
                              <svg
                                data-toggle="collapse"
                                data-target={`#collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index}`}
                                width="11"
                                height="6"
                                viewBox="0 0 11 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.7"
                                  d="M1 0.75L5.5 5.25L10 0.75"
                                  stroke="#212529"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>

                              <button
                                className="addShift-card-header__btn light"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index}`}
                              >
                                ({t('new_tasks')})
                              </button>
                            </div>
                            <button
                              className="addShift-card-header__btn icon"
                              type="button"
                              id={index}
                              onClick={deleteTasks}
                            >
                              <svg
                                id={index}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.5 5H4.16667H17.5"
                                  stroke="#EB5757"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033H15.8337Z"
                                  stroke="#EB5757"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>

                          <div
                            id={`collapse${index}`}
                            className="collapse"
                            aria-labelledby={`heading${index}`}
                            data-parent={`#accordion${index}`}
                          >
                            <div className="addShift-card-body">
                              <div>
                                <input
                                  className={`addShift-input ${errors[`tasks.${index}.name`] && 'error-input'}`}
                                  type="text"
                                  placeholder="Task"
                                  value={item.name}
                                  onChange={(e) => handleChangeTaskName(e.target.value, index)}
                                />
                                {errors[`tasks.${index}.name`] && (
                                  <p className="error-input-text mb-0 mt-1">
                                    {errors[`tasks.${index}.name`][0].replaceAll(`.${index}.`, ' ')}
                                  </p>
                                )}
                              </div>
                              <div className="addShift-card-body-breaks" key={index}>
                                <div className="addShift-card-body-breaks-items ">
                                  <div className="timepicker">
                                    <DatePicker
                                      className="datepicker-time"
                                      selected={item.start}
                                      onChange={(date) => handleChangeTaskStart(date, index)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeCaption="Time"
                                      dateFormat="HH:mm"
                                      timeFormat="HH:mm"
                                      timeIntervals={5}
                                      showPopperArrow={false}
                                      minTime={new Date().setHours(0, 0)}
                                      maxTime={subMinutes(item.end, 1)}
                                      customInput={
                                        <MaskedInput type="text" mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]} />
                                      }
                                    />
                                  </div>
                                  <span>-</span>
                                  <div className="timepicker">
                                    <DatePicker
                                      className={`datepicker-time ${errors[`tasks.${index}.end`] && 'error-input'}`}
                                      selected={item.end}
                                      onChange={(date) => handleChangeTaskEnd(date, index)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeCaption="Time"
                                      dateFormat="HH:mm"
                                      timeFormat="HH:mm"
                                      timeIntervals={5}
                                      showPopperArrow={false}
                                      minTime={addMinutes(item.start, 1)}
                                      maxTime={new Date().setHours(23, 59)}
                                      customInput={
                                        <MaskedInput type="text" mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]} />
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              {errors[`tasks.${index}.end`] && (
                                <p className="error-input-text mb-0 mt-1">
                                  {errors[`tasks.${index}.end`][0].replaceAll(`.${index}.`, ' ')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
