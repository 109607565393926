import React, { Component } from 'react';
import UserTable from './UserTable';
import EmployeeTable from './EmployeeTable';
import FullPageLoader from '../helpComponent/FullPageLoader';

import { bindActionCreators } from 'redux';
import * as managerActionCreators from '../../actions/managerActionCreators';
import { connect } from 'react-redux';

class ManagerAccount extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.managerActionCreators.getManagerAcc({ id });
  }
  render() {
    const { managerData, history, match } = this.props;
    return (
      <div>
        <div className="userAccount">
          <div className="content">
            <div className="userAccount-wrapper">
              <button onClick={() => this.props.history.goBack()} className="settings-buttonBack">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Back to table
              </button>
              <UserTable managerData={managerData} />
              <EmployeeTable history={history} match={match} />
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    managerData: state.managerReducer.managerData,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    managerActionCreators: bindActionCreators(managerActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerAccount);
