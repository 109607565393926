import React from "react";
import { useTranslation } from 'react-i18next';

export default function Popup({ text, onHandleClose, onHandleDelete, type }) {
  const { t } = useTranslation();
  return (
    <div className="popup-overlay">
      <div className="popup-wrapper">
        <div className="popup-header">
          {type === "confirm" ? (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.15" cx="16" cy="16" r="16" fill="#0B9444" />
              <path
                d="M22.6663 11L13.4997 20.1667L9.33301 16"
                stroke="#0B9444"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                opacity="0.15"
                cx="16.6758"
                cy="16"
                r="16"
                fill="#EB5757"
              />
              <path
                d="M21.6758 11L11.6758 21"
                stroke="#EB5757"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.6758 11L21.6758 21"
                stroke="#EB5757"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <p className="popup-header__text">{type}</p>
        </div>
        <p className="popup__text">{text}</p>
        <div className="popup-buttons">
          <button
            className={`popup__btn ${type === "confirm" ? "green" : "red"}`}
            onClick={onHandleDelete}
          >
            {type}
          </button>
          <button
            className={`popup__btn ${
              type === "confirm" ? "cancelGreen" : "cancelRed"
            }`}
            onClick={onHandleClose}
          >
            {t('close')}
          </button>
        </div>
      </div>
    </div>
  );
}
