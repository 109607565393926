import React from "react";
import { useTranslation } from 'react-i18next';

export default function NotificationAuthPopup({
  isShowNotify,
  text,
  isShowBtn,
}) {
  const { t } = useTranslation();
  return (
    <div className="notify-overlay">
      <div className="notify-popup">
        <svg
          width="42"
          height="42"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.15" cx="16" cy="16" r="16" fill="#0B9444" />
          <path
            d="M22.6663 11L13.4997 20.1667L9.33301 16"
            stroke="#0B9444"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className={`notify__text ${!isShowBtn && "mb-0"}`}>{text}</p>
        {isShowBtn && (
          <button className="notify__btn" onClick={isShowNotify}>
            {t('close')}
          </button>
        )}
      </div>
    </div>
  );
}
