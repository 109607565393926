import React, { Component } from 'react';

import moment from 'moment';

export default class List extends Component {
  render() {
    const { data, prevData } = this.props;
    const isSameDate = prevData ? moment(prevData.publish_at).isSame(data.publish_at, 'day') : false;

    return (
      <div className={`${isSameDate ? 'mt-2' : 'mt-3'}`}>
        {!isSameDate && <p className="dashboard-news__date">{moment(data.publish_at).format('MMM D, YYYY')}</p>}
        <div className="dashboard-news-table">
          <p className="dashboard-news-table__title">{data.title}</p>
        </div>
      </div>
    );
  }
}
