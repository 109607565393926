export default function checkUserPermission(user = { role: '' }, permission) {
  const allowAccessForRoles = {
    // * means any
    // ! means except
    'route.settings': ['*', '!employee'],
    'route.settings.admins': ['admin'],
    'route.reports': ['*', '!employee'],
    'route.news': ['admin'],
    'route.leaves': ['admin', 'manager'],

    'component.managerField': ['admin', 'manager'],
    'component.roleField': ['admin'],
    'component.leavesActions': ['manager'],
  };

  // If we don't have such permission in list
  if (!Array.isArray(allowAccessForRoles[permission])) {
    return false;
  }

  // If user's role explicitly denies access
  if (allowAccessForRoles[permission].includes('!' + user.role.toLowerCase())) {
    return false;
  }

  // If list of allowed roles contains '*'
  if (allowAccessForRoles[permission].includes('*')) {
    return true;
  }

  // Check if user's role allowes access
  if (allowAccessForRoles[permission].includes(user.role.toLowerCase())) {
    return true;
  }

  return false;
}
