import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import * as viewActionCreators from '../../actions/viewActionCreators';

import checkUserPermission from '../../Utils/CheckUserPermission';
import { ChevronDown } from '../LeftMenuIcons/LeftMenuIcons';
import Checkbox from '../helpComponent/Checkbox';

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      openedSubmenu: '',
    };
    this.navLinkRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if(e.target.classList.contains('display_checkbox')) return

    if (this.navLinkRef && !this.navLinkRef.current?.contains(e.target)) {
      this.setState({ openedSubmenu: '' });
    }
  };

  handleSubmenu = (e, name) => {
    e.preventDefault();
    this.setState({ openedSubmenu: name });
  };

  render() {
    const { t, myAccData, viewActionCreators, showStatisticsTab, location } = this.props;
    const { openedSubmenu } = this.state;

    return (
      <div className="nav-bar">
        <NavLink to="/dashboard" className="nav-bar__link" activeClassName="active">
          {t('dashboard')}
        </NavLink>
        <NavLink to="/schedule" className="nav-bar__link" activeClassName="active">
          {t('schedule')}
        </NavLink>
        {checkUserPermission(myAccData, 'route.leaves') && (
          <NavLink to="/leaves" className="nav-bar__link" activeClassName="active">
            {t('leaves')}
          </NavLink>
        )}
        {checkUserPermission(myAccData, 'route.news') && (
          <NavLink to="/news" className="nav-bar__link" activeClassName="active">
            {t('news')}
          </NavLink>
        )}
        {checkUserPermission(myAccData, 'route.reports') && (
          <div className="position-relative">
            <NavLink
              to="/reports"
              className="nav-bar__link"
              activeClassName="active"
              onClick={(e) => this.handleSubmenu(e, 'reports')}
              ref={this.navLinkRef}
            >
              {t('reports')}
              <ChevronDown classMenuIcon="nav-bar__link-icon" />
            </NavLink>
            <CSSTransition in={openedSubmenu === 'reports'} timeout={300} classNames="show" unmountOnExit>
              <div className="header-items-dropdown">
                <Link to="/reports/employees">
                  <p>{t('employees')}</p>
                </Link>
                <Link to="/reports/areas">
                  <p>{t('areas')}</p>
                </Link>
                <Link to="/reports/salary">
                  <p>{t('salary')}</p>
                </Link>
              </div>
            </CSSTransition>
          </div>
        )}
        {checkUserPermission(this.props.myAccData, 'route.settings') && (
          <div className="position-relative">
            <NavLink
              to="/settings"
              className="nav-bar__link"
              activeClassName="active"
              onClick={(e) => this.handleSubmenu(e, 'settings')}
              ref={this.navLinkRef}
            >
              {t('settings')}
              <ChevronDown classMenuIcon="nav-bar__link-icon" />
            </NavLink>
            <CSSTransition in={openedSubmenu === 'settings'} timeout={300} classNames="show" unmountOnExit>
              <div className="header-items-dropdown">
                <Link to="/settings/organization">
                  <p>{t('organizations')}</p>
                </Link>
                {checkUserPermission(myAccData, 'route.settings.admins') && (
                  <Link to="/settings/administrators">
                      <p>{t('administrators')}</p>
                  </Link>
                )}
                <Link to="/settings/managers">
                  <p>{t('managers')}</p>
                </Link>
                <Link to="/settings/employees">
                  <p>{t('employees')}</p>
                </Link>
              </div>
            </CSSTransition>
          </div>
        )}
        {location.pathname === '/schedule' && <div className="position-relative">
            <NavLink
              to="/display"
              className="nav-bar__link mr-0"
              activeClassName="active"
              onClick={(e) => this.handleSubmenu(e, 'display')}
              ref={this.navLinkRef}
            >
              {t('display')}
              <ChevronDown classMenuIcon="nav-bar__link-icon" />
            </NavLink>
            <CSSTransition in={openedSubmenu === 'display'} timeout={300} classNames="show" unmountOnExit>
              <div className="header-items-dropdown">
              <Checkbox
                name="isShowStatistics"
                containerStyle="addManager-checkbox__container display_checkbox"
                checkboxStyle="addManager-checkbox__aligned display_checkbox"
                textStyle="addManager-checkbox__text-solid display_checkbox"
                text={t('statistics')}
                handleChangeCheckbox={viewActionCreators.toggleStatisticsTab}
                isCheckedCheckbox={showStatisticsTab}
              />
              </div>
            </CSSTransition>
          </div>}
         
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myAccData: state.authReducer.myAccData,
    showStatisticsTab: state.viewReducer.showStatisticsTab,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    viewActionCreators: bindActionCreators(viewActionCreators, dispatch),
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar)));
