import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import NotificationAuthPopup from '../helpComponent/NotificationAuthPopup';
import { Logo } from '../Svg/Svg';

import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../actions/authActionCreators';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errors: {},
      isShowNotify: false,
    };
  }

  submitForm = (e) => {
    e.preventDefault();
    const { email } = this.state;
    this.props.authActionCreators.forgotPassword({
      email,
      handleSuccess: this.handleSuccess,
      handleError: this.handleError,
    });
  };

  handleChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleSuccess = (success) => {
    const { status } = success;
    if (status === 200) {
      this.setState({
        isShowNotify: true,
      });
      setInterval(() => {
        this.setState({
          isShowNotify: false,
        });
      }, 5000);
      this.props.history.push('/check-email');
    }
  };

  handleError = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  isShowNotify = () => {
    const { isShowNotify } = this.state;
    this.setState({
      isShowNotify: !isShowNotify,
    });
  };

  render() {
    const { errors, isShowNotify } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <div className="auth-container">
          <div className="auth-left">
            <Logo classLogo={'auth-left-logo'} />
            <div className="auth-greeting">
              <h1 className="auth-greeting__text">{t('hello_friend')}</h1>
              <h1 className="auth-greeting__text">{t('welcome_back')}</h1>
            </div>
          </div>
          <div className="auth-right">
            <Link to="/login" className="auth-right__link">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#212529"
                  opacity="0.7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {t('sign_in')}
            </Link>
            <form className="auth-form forgotpassword" onSubmit={this.submitForm}>
              <h1 className="auth-form__title forgotpassword">Forgot Password?</h1>
              <p className="auth-form__subtitle">{t('enter_your_email')}</p>
              <div className="auth-form-inputContainer forgotpassword">
                <p className="auth-form__label">{t('email_address')}</p>
                <div className={`auth-form__input forgotpassword ${errors.email ? 'error-input' : ''}`}>
                  <input type="email" name="email" className="w-100" onChange={this.handleChange} />
                </div>
                {errors.email && <span className="error-input-text">{errors.email}</span>}
              </div>
              <button type="submit" className="auth-form__btn">
                {t('reset_your_password')}
              </button>
            </form>
          </div>
        </div>
        <CSSTransition in={isShowNotify} timeout={200} classNames="notify-anim" unmountOnExit>
          <NotificationAuthPopup
            isShowNotify={this.isShowNotify}
            text="The email with further instructions was sent to the submitted email
          address. If you don’t receive a message in 5 minutes, check the junk
          folder."
            isShowBtn={true}
          />
        </CSSTransition>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
  };
}

export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(ForgotPassword)));
