import React, { Fragment } from 'react';

export function EyeOn({ toggleInputType }) {
  return (
    <Fragment>
      <svg
        onClick={toggleInputType}
        className="cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.54">
          <path
            d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
            stroke="#212529"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            stroke="#212529"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Fragment>
  );
}

export function EyeOff({ toggleInputType }) {
  return (
    <Fragment>
      <svg
        onClick={toggleInputType}
        className="cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.54">
          <path
            d="M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003L17.94 17.94ZM9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19L9.9 4.24002Z"
            stroke="#212529"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M1 1L23 23" stroke="#212529" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    </Fragment>
  );
}

export function Logo({ classLogo }) {
  return (
    <Fragment>
      <svg
        className={classLogo}
        width="128"
        height="32"
        viewBox="0 0 128 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M122.556 20.5093C122.556 16.4951 120.001 13.9795 116.597 13.9795C113.591 13.9795 110.559 16.5051 110.559 20.5093C110.559 24.5135 113.565 26.9956 116.597 26.9956C120.007 26.9889 122.556 24.5235 122.556 20.5093ZM128 20.5093C128 27.3435 122.99 32 117.579 32C114.683 32 112.345 31.1303 110.565 29.6584V31.3577H104.944V0H110.559V11.3736C112.345 9.85156 114.683 8.98181 117.573 8.98181C122.973 8.98181 127.993 13.6651 127.993 20.516"
          fill="white"
        />
        <path
          d="M95.3537 20.7206C95.3537 16.7745 92.3472 14.3016 89.3141 14.3016C85.9102 14.2951 83.358 16.7745 83.358 20.7206C83.358 24.6667 85.9102 27.0968 89.3141 27.0968C92.3205 27.0968 95.3537 24.6568 95.3537 20.7206ZM100.969 31.3751H95.3537V29.698C93.5665 31.1547 91.2282 31.9999 88.3387 31.9999C82.9338 31.9999 77.9131 27.4224 77.9131 20.7042C77.9131 13.9859 82.9238 9.36572 88.3387 9.36572C91.2315 9.36572 93.5699 10.2207 95.3537 11.7169V10.007H100.969V31.3751Z"
          fill="white"
        />
        <path
          d="M75.5146 17.953V31.2194H69.9352V19.0999C69.9352 16.2538 68.1022 14.5253 65.7608 14.5253C62.7771 14.5253 60.8169 16.5675 60.8169 21.031V31.2194H55.1941V19.0999C55.1941 16.2538 53.3611 14.5253 51.0196 14.5253C48.0359 14.5253 46.1628 16.5675 46.1628 21.031V31.2194H40.5466V10.0029H46.1695V11.7445C47.6613 10.2578 49.7486 9.36572 52.5249 9.36572C55.3781 9.36572 57.9771 10.5976 59.5091 12.8522C61.2585 10.6826 63.8575 9.36572 67.2626 9.36572C71.6947 9.36572 75.528 12.7673 75.528 17.953"
          fill="white"
        />
        <path
          d="M38.0305 9.96722V14.5659H31.3978V31.2195H25.5998V14.5659H22.261V9.98359H25.5998V8.04918C25.5998 3.06751 29.1145 0.000610109 34.2537 0.000610109C35.6041 -0.0147939 36.9405 0.261675 38.1616 0.809068L36.8302 5.36851C36.1242 5.12964 35.3825 4.99926 34.6331 4.98228C32.7464 4.98228 31.384 6.04931 31.384 8.04918V9.96722H38.0305Z"
          fill="white"
        />
        <path
          d="M5.8772 18.2266H16.3734C15.6315 15.4857 13.5425 14.2782 11.245 14.2782C8.72787 14.2512 6.51661 15.8809 5.88745 18.2266H5.8772ZM22.2609 22.2078H5.70283C6.27038 25.2909 8.49271 27.1762 11.1458 27.1762C12.8553 27.1762 14.9785 26.9591 16.5478 24.3498L21.6762 25.4192C19.7582 29.871 15.8845 31.9998 11.1356 31.9998C5.00878 32.013 0 27.4329 0 20.7173C0 14.0018 5.00878 9.36572 11.2347 9.36572C17.0264 9.36572 22.0318 13.7748 22.2506 20.283L22.2609 22.2078Z"
          fill="white"
        />
      </svg>
    </Fragment>
  );
}

export function Search({ classSearch, showSearchField, isSearch }) {
  return (
    <Fragment>
      <svg
        onClick={showSearchField}
        className={`${classSearch} ${isSearch ? 'active' : 'unactive'}`}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.7">
          <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.9999 20.9999L16.6499 16.6499"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Fragment>
  );
}

export function Mail() {
  return (
    <Fragment>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.7">
          <path
            d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M22 6L12 13L2 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    </Fragment>
  );
}

export function Bell() {
  return (
    <Fragment>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.7">
          <path
            d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Fragment>
  );
}

export function LeaveApplications() {
  return (
    <Fragment>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.15" width="48" height="48" rx="8" fill="#FEC700" />
        <path
          d="M23 16H16C15.4696 16 14.9609 16.2107 14.5858 16.5858C14.2107 16.9609 14 17.4696 14 18V32C14 32.5304 14.2107 33.0391 14.5858 33.4142C14.9609 33.7893 15.4696 34 16 34H30C30.5304 34 31.0391 33.7893 31.4142 33.4142C31.7893 33.0391 32 32.5304 32 32V25"
          stroke="#FEC700"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.5 14.5002C30.8978 14.1024 31.4374 13.8789 32 13.8789C32.5626 13.8789 33.1022 14.1024 33.5 14.5002C33.8978 14.8981 34.1213 15.4376 34.1213 16.0002C34.1213 16.5628 33.8978 17.1024 33.5 17.5002L24 27.0002L20 28.0002L21 24.0002L30.5 14.5002Z"
          stroke="#FEC700"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Fragment>
  );
}

export function NoticesOfinterest() {
  return (
    <Fragment>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.15" width="48" height="48" rx="8" fill="#0B9444" />
        <path
          d="M28 16H30C30.5304 16 31.0391 16.2107 31.4142 16.5858C31.7893 16.9609 32 17.4696 32 18V32C32 32.5304 31.7893 33.0391 31.4142 33.4142C31.0391 33.7893 30.5304 34 30 34H18C17.4696 34 16.9609 33.7893 16.5858 33.4142C16.2107 33.0391 16 32.5304 16 32V18C16 17.4696 16.2107 16.9609 16.5858 16.5858C16.9609 16.2107 17.4696 16 18 16H20"
          stroke="#0B9444"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27 14H21C20.4477 14 20 14.4477 20 15V17C20 17.5523 20.4477 18 21 18H27C27.5523 18 28 17.5523 28 17V15C28 14.4477 27.5523 14 27 14Z"
          stroke="#0B9444"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Fragment>
  );
}

export function ShiftBookingsToApprove() {
  return (
    <Fragment>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.15" width="48" height="48" rx="8" fill="#FF7F00" />
        <path
          d="M27.7397 30C27.7397 31.6569 29.0829 33 30.7397 33C32.3966 33 33.7397 31.6569 33.7397 30C33.7397 28.3431 32.3966 27 30.7397 27C29.0829 27 27.7397 28.3431 27.7397 30Z"
          stroke="#FF7F00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.7397 18C20.7397 19.6569 22.0829 21 23.7397 21C25.3966 21 26.7397 19.6569 26.7397 18C26.7397 16.3431 25.3966 15 23.7397 15C22.0829 15 20.7397 16.3431 20.7397 18Z"
          stroke="#FF7F00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7397 30C13.7397 31.6569 15.0829 33 16.7397 33C18.3966 33 19.7397 31.6569 19.7397 30C19.7397 28.3431 18.3966 27 16.7397 27C15.0829 27 13.7397 28.3431 13.7397 30Z"
          stroke="#FF7F00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.23 20.5898L18.25 27.4198"
          stroke="#FF7F00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.23 27.4098L25.25 20.5898"
          stroke="#FF7F00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Fragment>
  );
}

export function UnassignedShifts() {
  return (
    <Fragment>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.15" width="48" height="48" rx="8" fill="#0D1D41" />
        <path d="M23.7397 32H32.7397" stroke="#0D1D41" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M28.2397 15.5002C28.6376 15.1024 29.1771 14.8789 29.7397 14.8789C30.0183 14.8789 30.2942 14.9338 30.5515 15.0404C30.8089 15.147 31.0428 15.3032 31.2397 15.5002C31.4367 15.6972 31.593 15.9311 31.6996 16.1884C31.8062 16.4458 31.8611 16.7217 31.8611 17.0002C31.8611 17.2788 31.8062 17.5547 31.6996 17.812C31.593 18.0694 31.4367 18.3032 31.2397 18.5002L18.7397 31.0002L14.7397 32.0002L15.7397 28.0002L28.2397 15.5002Z"
          stroke="#0D1D41"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Fragment>
  );
}

export function MapPin() {
  return (
    <Fragment>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path
            opacity="0.37"
            d="M14.4404 7.16699C14.4404 11.8337 8.44043 15.8337 8.44043 15.8337C8.44043 15.8337 2.44043 11.8337 2.44043 7.16699C2.44043 5.57569 3.07257 4.04957 4.19779 2.92435C5.32301 1.79913 6.84913 1.16699 8.44043 1.16699C10.0317 1.16699 11.5579 1.79913 12.6831 2.92435C13.8083 4.04957 14.4404 5.57569 14.4404 7.16699Z"
            stroke="#212529"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.37"
            d="M8.44043 9.16699C9.545 9.16699 10.4404 8.27156 10.4404 7.16699C10.4404 6.06242 9.545 5.16699 8.44043 5.16699C7.33586 5.16699 6.44043 6.06242 6.44043 7.16699C6.44043 8.27156 7.33586 9.16699 8.44043 9.16699Z"
            stroke="#212529"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="16" height="16" fill="white" transform="translate(0.44043 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </Fragment>
  );
}

export function Dots() {
  return (
    <Fragment>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
          <path
            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
            stroke="#212529"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
            stroke="#212529"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
            stroke="#212529"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Fragment>
  );
}

export function Refresh() {
  return (
    <Fragment>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
          <path d="M0.75 3V7.5H5.25" stroke="#212529" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M17.25 15V10.5H12.75"
            stroke="#212529"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3675 6.75018C14.9871 5.67527 14.3406 4.71423 13.4884 3.95674C12.6361 3.19925 11.6059 2.67 10.4938 2.41837C9.38167 2.16674 8.22393 2.20093 7.12861 2.51776C6.03328 2.83458 5.03606 3.42371 4.23 4.23018L0.75 7.50018M17.25 10.5002L13.77 13.7702C12.9639 14.5766 11.9667 15.1658 10.8714 15.4826C9.77607 15.7994 8.61833 15.8336 7.50622 15.582C6.3941 15.3304 5.36385 14.8011 4.5116 14.0436C3.65935 13.2861 3.01288 12.3251 2.6325 11.2502"
            stroke="#212529"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Fragment>
  );
}
export function Sum() {
  return (
    <Fragment>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5721 4.44759C4.64127 4.28525 4.75664 4.14683 4.90385 4.04954C5.05107 3.95225 5.22364 3.90038 5.4001 3.90039H18.6001C18.8388 3.90039 19.0677 3.99521 19.2365 4.16399C19.4053 4.33278 19.5001 4.5617 19.5001 4.80039C19.5001 5.03909 19.4053 5.268 19.2365 5.43679C19.0677 5.60557 18.8388 5.70039 18.6001 5.70039H7.5145L12.4897 10.87C12.6435 11.0298 12.7328 11.2409 12.7406 11.4626C12.7484 11.6842 12.674 11.901 12.5317 12.0712L7.3249 18.3004H18.6001C18.8388 18.3004 19.0677 18.3952 19.2365 18.564C19.4053 18.7328 19.5001 18.9617 19.5001 19.2004C19.5001 19.4391 19.4053 19.668 19.2365 19.8368C19.0677 20.0056 18.8388 20.1004 18.6001 20.1004H5.4001C5.22897 20.1003 5.06141 20.0514 4.91708 19.9595C4.77275 19.8675 4.65763 19.7363 4.5852 19.5813C4.51278 19.4262 4.48606 19.2537 4.50818 19.0841C4.5303 18.9144 4.60035 18.7545 4.7101 18.6232L10.6321 11.536L4.7521 5.42439C4.62956 5.2972 4.54715 5.13676 4.51515 4.96307C4.48314 4.78938 4.50295 4.61011 4.5721 4.44759Z"
          fill="#212529"
        />
      </svg>
    </Fragment>
  );
}
export function Columns() {
  return (
    <Fragment>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3V21M12 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H12V3ZM12 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H12V3Z"
          stroke="#212529"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Fragment>
  );
}
export function Check() {
  return (
    <Fragment>
      <svg
        width="17"
        height="17"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.484 4.10443L6.15072 11.4378L2.81738 8.10443"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Fragment>
  );
}
export function Clock() {
  return (
    <Fragment>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1982_794)">
          <path
            d="M8.49967 14.7711C12.1816 14.7711 15.1663 11.7863 15.1663 8.10441C15.1663 4.42251 12.1816 1.43774 8.49967 1.43774C4.81778 1.43774 1.83301 4.42251 1.83301 8.10441C1.83301 11.7863 4.81778 14.7711 8.49967 14.7711Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.5 4.10443V8.10443L11.1667 9.43776"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1982_794">
            <rect width="16" height="16" fill="white" transform="translate(0.5 0.104431)" />
          </clipPath>
        </defs>
      </svg>
    </Fragment>
  );
}
