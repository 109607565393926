import React, { Component } from 'react';
import Avatar from 'react-avatar';
import MainInfoList from './MainInfoList';
import AdditionalList from './AdditionalList';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class UserTable extends Component {
  render() {
    const { managerData, t } = this.props;
    return (
      <div className="userAccount-table">
        <div className="userAccount-table-avatar">
          <Avatar
            name={`${managerData?.first_name} ${' '} ${managerData?.last_name}`}
            round="50%"
            size="96"
            className="userAccount-table-avatar__avatar"
          />
          <div className="userAccount-table-avatar-col">
            <div className="d-flex">
              <p className="userAccount-table-avatar__name">
                {managerData?.first_name} {managerData?.last_name}{' '}
              </p>
              <span
                className={`userAccount-table-avatar-active ${managerData?.is_active === 1 ? 'active' : 'inactive'}`}
              >
                {managerData?.is_active === 1 ? t('active') : t('inactive')}
              </span>
            </div>
            <p className="userAccount-table-avatar__secondaryText"># {managerData?.id}</p>
            <p className="userAccount-table-avatar__secondaryText">{managerData?.email}</p>
          </div>
        </div>
        <div className="userAccount-table-mainInfo">
          <MainInfoList managerData={managerData} />
        </div>
        <div className="userAccount-table-additionalInfo">
          <AdditionalList managerData={managerData} />
        </div>
        <div className="userAccount-table-edit">
          <div className="userAccount-table-edit-wrapper">
            <Link to={`/settings/managers/edit/${managerData?.id}`}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.7"
                  d="M14.1665 2.49993C14.3854 2.28106 14.6452 2.10744 14.9312 1.98899C15.2171 1.87054 15.5236 1.80957 15.8332 1.80957C16.1427 1.80957 16.4492 1.87054 16.7352 1.98899C17.0211 2.10744 17.281 2.28106 17.4998 2.49993C17.7187 2.7188 17.8923 2.97863 18.0108 3.2646C18.1292 3.55057 18.1902 3.85706 18.1902 4.16659C18.1902 4.47612 18.1292 4.78262 18.0108 5.06859C17.8923 5.35455 17.7187 5.61439 17.4998 5.83326L6.24984 17.0833L1.6665 18.3333L2.9165 13.7499L14.1665 2.49993Z"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UserTable)
