import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { withMeta } from '../component/helpComponent/PageTitle';

import { getCookie } from '../Cookie';

export default function PublicRoute({ title, children, ...rest }) {
  const isAuthenticated = getCookie('auth_token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location },
            }}
          />
        ) : (
          withMeta({ children, title })
        )
      }
    />
  );
}
