import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function AdditionalList({ managerData }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <h4 className="userAccount-table__title">
        {t('additional_information')} <span>(relatives)</span>
      </h4>
      <div className="userAccount-table-column">
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('name')}</p>
          <p className="userAccount-table__text bold">{managerData?.relative_name || t('empty')}</p>
        </div>
        {/* <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">Last name</p>
          <p className="userAccount-table__text bold">
            {managerData?.relative_name || "(empty)"}
          </p>
        </div> */}
        <div className="d-flex justify-content-between w-100">
          <p className="userAccount-table__text">{t('phone_number')}</p>
          <p className="userAccount-table__text bold">{managerData?.relative_phone || t('empty')}</p>
        </div>
      </div>
    </Fragment>
  );
}
