export const initialData = {
  showLoader: false,
  showSmallLoader: false,
  showFlashMessage: false,
  showStatisticsTab: false,
  notifications: [],
};

export default function (state = initialData, action) {
  switch (action.type) {
    case 'SHOW_LOADER':
      return {
        ...state,
        showLoader: true,
      };

    case 'HIDE_LOADER':
      return {
        ...state,
        showLoader: false,
      };

    case 'SHOW_SMALL_LOADER':
      return {
        ...state,
        showSmallLoader: true,
      };

    case 'HIDE_SMALL_LOADER':
      return {
        ...state,
        showSmallLoader: false,
      };

    case 'SHOW_FLASH_MESSAGE':
      return {
        showFlashMessage: true,
      };

    case 'HIDE_FLASH_MESSAGE':
      return {
        showFlashMessage: false,
      };

    case 'TOGGLE_STATISTICS_TAB':
      return {
        ...state,
        showStatisticsTab: !state.showStatisticsTab,
      };

    case 'GET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.notifications,
      };

    default:
      return state;
  }
}
