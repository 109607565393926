import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Avatar from 'react-avatar';
import moment from 'moment';
import qs from 'query-string';
import MonthPicker from 'react-datepicker';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import * as reportsActionCreators from '../../../actions/reportsActionCreators';
import Pagination from '../../helpComponent/Pagination';
import SmallLoader from '../../helpComponent/SmallLoader';
import { Input } from '../../helpComponent/RangeDatePicker';
import FullPageLoader from '../../helpComponent/FullPageLoader';
import AdjustHoursPopup from './AdjustHoursPopup';
import { convertSecondsToHours } from '../../../Utils/helpers';

class ReportsSalaryTable extends Component {
  constructor() {
    super();
    this.state = {
      isShowAdjustHoursPopup: false,
      isHoverRowId: null,
      isSelectedUserId: null,
      startDate: new Date(),
      pagination: {
        page: 1,
        limit: 10,
        name: '',
        start: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
        end: moment().endOf('month').format('YYYY-MM-DDT23:59:59'),
        withTrash: true,
      },
      typingTimeout: 0,
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });
    const startDate = moment(pagination.start).toDate();

    this.setState({ pagination, startDate });
    this.setWorkingHours(pagination);
  }

  setWorkingHours = (pagination = this.state.pagination) => {
    const { reportsActionCreators, history } = this.props;

    let params = `?${qs.stringify(pagination)}`;
    reportsActionCreators.getEmployeeStatistics({ params });
    history.push({ search: params });
  };

  handlePageChange = (page) => {
    const { pagination } = this.state;
    pagination.page = page;

    this.setState({ pagination });
    this.setWorkingHours(pagination);
  };

  handleSelect = (e) => {
    const { pagination } = this.state;
    pagination.limit = e.target.value;
    pagination.page = 1;

    this.setState({ pagination });
    this.setWorkingHours(pagination);
  };

  handleChangeSearch = (e) => {
    const self = this;
    const { pagination } = this.state;
    pagination.name = e.target.value;
    pagination.page = 1;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      pagination,
      typingTimeout: setTimeout(function () {
        self.setWorkingHours(pagination);
      }, 500),
    });
  };

  clearSearch = () => {
    const { pagination } = this.state;
    pagination.page = 1;
    pagination.name = '';

    this.setState({ pagination });
    this.setWorkingHours(pagination);
  };

  handleChangeDate = (startDate) => {
    const { pagination } = this.state;
    (pagination.start = moment(startDate).startOf('month').format('YYYY-MM-DDT00:00:00')),
      (pagination.end = moment(startDate).endOf('month').format('YYYY-MM-DDT23:59:59')),
      (pagination.page = 1);

    this.setState({ pagination, startDate });
    this.setWorkingHours(pagination);
  };

  handleRowHover = (item, action) => {
    if (action === 'enter' && !item.approved_hours) {
      this.setState({ isHoverRowId: item.user_id });
    } else {
      this.setState({ isHoverRowId: null });
    }
  };

  toggleAdjustHoursPopup = (id) => {
    this.setState({
      isShowAdjustHoursPopup: !this.state.isShowAdjustHoursPopup,
      isSelectedUserId: id,
    });
  };

  approveHours = (item) => {
    const { reportsActionCreators } = this.props;
    const { startDate } = this.state;
    const data = {
      hours: convertSecondsToHours(item.total_seconds),
      month: +moment(startDate).format('M'),
      year: +moment(startDate).format('YYYY'),
    };
    reportsActionCreators.approveHours({
      id: item.user_id,
      data,
      handleResponse: () => this.setWorkingHours(),
    });
  };

  downloadReport = () => {
    const { startDate } = this.state;
    const start = moment(startDate).startOf('month').format('YYYY-MM-DDT00:00:00');
    const end = moment(startDate).endOf('month').format('YYYY-MM-DDT23:59:59');

    axios({
      url: `${process.env.REACT_APP_API_URL}/api/v1/salary-export?start=${start}&end=${end}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Salary_report.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    const { employeeStatistics, meta, showLoader, t } = this.props;
    const { startDate, pagination, isHoverRowId, isShowAdjustHoursPopup, isSelectedUserId } = this.state;

    return (
      <div className="d-flex">
        <div className="content">
          <div className="settings-wrapper d-flex">
            <div className={`settings-table reports-table w-100`}>
              <div className="reports-table-header">
                <h6 className="reports-table-header__text">{t('working_hours')}</h6>
                <div className="reports-table-header-items">
                  <div className="reports-table-header-items">
                    <MonthPicker
                      selected={startDate}
                      onChange={(date) => this.handleChangeDate(date)}
                      dateFormat="MMM, yyyy"
                      className={`date-picker date-picker-range`}
                      calendarClassName={`date-calendar date-calendar-months`}
                      showPopperArrow={false}
                      customInput={<Input />}
                      showMonthYearPicker
                      // showFullMonthYearPicker
                    />
                    <button
                      onClick={this.downloadReport}
                      type="button"
                      className="primary-btn ml-3 px-3"
                      disabled={!employeeStatistics?.length}
                    >
                      Download report
                    </button>
                  </div>
                  <div className="reports-table-header-items">
                    <div className="reports-table-header-search">
                      <div className="reports-table-header-search-wrapper">
                        <svg
                          className={`reports-table-header-search__icon`}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.7">
                            <path
                              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                              stroke="#212529"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.9999 20.9999L16.6499 16.6499"
                              stroke="#212529"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                        <input
                          className={`reports-table-header-search__input`}
                          value={pagination.name}
                          onChange={this.handleChangeSearch}
                          type="text"
                        />
                        {pagination.name && (
                          <svg
                            onClick={this.clearSearch}
                            className={`reports-table-header-search__icon clear`}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 5L5 15"
                              stroke="#646669"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5 5L15 15"
                              stroke="#646669"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table">
                <thead className="settings-table-thead reports-table-thead">
                  <tr>
                    <th scope="col">{t('employees')}</th>
                    <th scope="col">{t('day')}</th>
                    <th scope="col">{t('night')}</th>
                    <th scope="col">{t('weekend')}</th>
                    <th scope="col">{t('vacation')}</th>
                    <th scope="col">{t('total')}</th>
                    <th scope="col">{t('status')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.showSmallLoader ? (
                    <tr>
                      <td colSpan="8">
                        <SmallLoader />
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      {employeeStatistics?.length !== 0 ? (
                        <Fragment>
                          {employeeStatistics?.map((item, index) => {
                            const isShowActions = item.user_id === isHoverRowId;

                            return (
                              <tr
                                className={`settings-table__tr`}
                                key={index}
                                onMouseEnter={() => this.handleRowHover(item, 'enter')}
                                onMouseLeave={() => this.handleRowHover(item, 'leave')}
                              >
                                <td className="settings-table-items" scope="row">
                                  <Avatar name={`${item.first_name} ${item.last_name}`} size="36" round="50%" />
                                  <div className="settings-table-items-info">
                                    <Link
                                      to={`/settings/employees/employee/${item.user_id}`}
                                      className="settings-table-items-info__name"
                                    >
                                      {item.first_name} {item.last_name}
                                    </Link>
                                    <p className="settings-table-items-info__id"># {item.user_id}</p>
                                  </div>
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.daily_seconds)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.nightly_seconds)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.weekend_seconds)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.total_vacations)} {t('h')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {convertSecondsToHours(item.total_seconds)} {t('h')}
                                </td>
                                <td className="settings-table__text">
                                  {!!item.approved_hours && (
                                    <div className={`settings-table-status approved`}>{t('approved')}</div>
                                  )}
                                </td>
                                <td className="text-right align-middle hidden-actions">
                                  <CSSTransition in={isShowActions} timeout={300} classNames="show" unmountOnExit>
                                    <div>
                                      {/* <button
                                          onClick={() => this.toggleAdjustHoursPopup(item.user_id)}
                                          className="outline-btn mr-3"
                                        >
                                          {t('adjust')}
                                        </button> */}
                                      <button
                                        onClick={() => this.approveHours(item)}
                                        className="organization-popup-buttons__addBtn mr-0"
                                      >
                                        {t('approve')}
                                      </button>
                                    </div>
                                  </CSSTransition>
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <p className="settings-table-emptyText">{t('dont_have_employees')}</p>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
              <div className="settings-table-pagination reports-table-pagination">
                <Pagination
                  count={meta?.last_page}
                  handlePageChange={this.handlePageChange}
                  activePage={pagination.page}
                />
                <div className="settings-table-pagination-items">
                  <p className="settings-table-pagination__text">{t('view')}</p>
                  <select
                    className="settings-table-pagination__select"
                    onChange={this.handleSelect}
                    value={pagination.limit}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </select>{' '}
                  <p className="settings-table-pagination__text">{t('items_per_page')}</p>
                </div>
                <p className="settings-table-pagination__text">
                  {!!meta && meta.from} - {!!meta && meta.to} {t('out_of')} {!!meta && meta.total} {t('items')}
                </p>
              </div>
            </div>
          </div>
        </div>
        {showLoader && <FullPageLoader />}
        <CSSTransition in={isShowAdjustHoursPopup} timeout={300} classNames="show" unmountOnExit>
          <AdjustHoursPopup
            togglePopup={this.toggleAdjustHoursPopup}
            date={startDate}
            userId={isSelectedUserId}
            setHours={this.setWorkingHours}
          />
        </CSSTransition>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employeeStatistics: state.reportsReducer.employeeStatistics,
    meta: state.reportsReducer.meta,
    showSmallLoader: state.viewReducer.showSmallLoader,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActionCreators: bindActionCreators(reportsActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportsSalaryTable));
