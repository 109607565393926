import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AddShift from './AddShift';
import AddOffs from './AddOffs';
import EditOffs from './EditOffs';
import EditShift from './EditShift';
import StatisticsTab from '../StatisticsTab/StatisticsTab'
import Popup from '../helpComponent/Popup';
import RepeatableDeletePopup from './RepeatableDeletePopup';
import { CSSTransition } from 'react-transition-group';
import FullPageLoader from '../helpComponent/FullPageLoader';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import moment from 'moment';
import withDragDropContext from './withDnDContext';
import { config, MembersHeader } from './config';
import { generateAvatarInitials, humanizeString, sortArrByDate, convertSecondsToHours } from '../../Utils/helpers';
import * as DateHolidays from 'date-holidays';
import { withTranslation } from 'react-i18next';

const holidays = new DateHolidays.default();
holidays.init('SE');

moment.locale('us', {
  week: {
    dow: 1,
  },
});

const isNonWorkingTime = (schedulerData, time) => {
  const { localeMoment } = schedulerData;
  const isHoliday = holidays.isHoliday(new Date(time).setHours(1, 0));
  const isPublicHoliday = isHoliday && isHoliday[0].type === 'public';

  if (schedulerData.viewType === ViewTypes.Day) {
    // let hour = localeMoment(time).hour();
    // if (hour < 7 || hour > 18) return true;
    return false;
  } else {
    let dayOfWeek = localeMoment(time).weekday();
    if (dayOfWeek === 5 || dayOfWeek === 6 || isPublicHoliday) return true;
  }

  return false;
};

export let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Week, false, false, config, {
  isNonWorkingTimeFunc: isNonWorkingTime,
});
schedulerData.setLocaleMoment(moment);

let colorsShift = [
  { hex: '#7868e6' },
  { hex: '#edeef7' },
  { hex: '#a7c5eb' },
  { hex: '#a7c5eb' },
  { hex: '#1687a7' },
  { hex: '#dddddd' },
  { hex: '#a685e2' },
  { hex: '#6155a6' },
  { hex: '#bedcfa' },
  { hex: '#98acf8' },
];

let colorsAvatar = [
  { hex: '#67AE3E' },
  { hex: '#FF407F' },
  { hex: '#D61A7F' },
  { hex: '#7E3794' },
  { hex: '#4285F4' },
  { hex: '#D73D33' },
];

class Schedule extends Component {
  constructor() {
    super();
    this.state = {
      isShowAddShift: false,
      isShowAddOffs: false,
      isShowEditShift: false,
      isShowEditOffs: false,
      isShowRepeatableDeletePopup: false,
      addShiftDate: new Date(),
      addShiftUser: '',
      copyData: undefined,
      newEvent: [],
      currentShift: {},
      areas: [],
      searchInput: '',
      areasIds: [],
      searchName: '',
    };
  }

  componentDidMount() {
    this.setSchedule();
    schedulerData.config.resourceName = <MembersHeader getSearchName={this.getSearchName}/>;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.shifts !== prevProps.shifts) {
      this.setSchedule();
    }
    if (this.state.searchName !== prevState.searchName) {
      this.setSchedule();
    }
  }

  setSchedule = () => {
    let resources = [];
    let events = [];

    this.props.shifts.forEach((u) => {
      resources.push({
        ...u,
        id: u.user_id,
        name: `${u.first_name} ${u.last_name}`,
        bgColor: colorsAvatar[Math.floor(Math.random() * colorsAvatar.length)].hex,
      });
      u.shifts.forEach((s) => {
        let background = colorsShift[Math.floor(Math.random() * colorsShift.length)];

        events.push({
          ...s,
          start: s.start_work_at,
          end: s.end_work_at,
          resourceId: u.user_id,
          title: (
            <span className="event-item-wrap">
              <span className="event-item-wrap__title">{`${moment(s.start_work_at).format('HH:mm')} - ${moment(
                s.end_work_at
              ).format('HH:mm')}`}</span>{' '}
              <span className="event-item-wrap__title">{`${s.area?.name || ''}`}</span>
            </span>
          ),
          bgColor: s.area?.bgcolor || background.hex,
        });
      });
      u.vacations.forEach((v) => {
        events.push({
          ...v,
          start: v.start_at,
          end: v.end_at,
          resourceId: u.user_id,
          title: (
            <span className="event-item-wrap">
              <span className="event-item-wrap__title">{`${moment(v.start_at).format('DD-MM')} - ${moment(
                v.end_at
              ).format('DD-MM')}`}</span>{' '}
              <span className="event-item-wrap__title">{`${humanizeString(v.type.replace('TYPE_', ''))}`}</span>
            </span>
          ),
          bgColor: '#ffb8d6',
        });
      });
    });

    const sortedEvents = sortArrByDate(events, 'YYYYMMDDHHmmss', 'start');
    const filteredShiftsByName = resources.filter(el => el.first_name.concat(' ', el.last_name).toLowerCase().includes(this.state.searchName.toLowerCase()))
    schedulerData.setResources(filteredShiftsByName);
    schedulerData.setEvents(sortedEvents);
    this.setState({ viewModel: schedulerData });
  };

  toggleAddShift = (data = undefined) => {
    const { isShowAddShift } = this.state;
    this.setState({
      isShowAddShift: !isShowAddShift,
      isShowAddOffs: false,
      isShowEditShift: false,
      addShiftDate: new Date(),
      addShiftUser: '',
      copyData: data,
    });
  };

  toggleAddOffs = () => {
    const { isShowAddOffs } = this.state;
    this.setState({
      isShowAddOffs: !isShowAddOffs,
      isShowAddShift: false,
    });
  };

  deleteShift = (repeat = undefined) => {
    let id = this.state.deleteShiftId;
    if (this.state.currentShift.type) {
      this.props.scheduleActionCreators.deleteOffs({
        id,
        handleSuccessDeleteShift: this.handleSuccessDeleteShift,
      });
    } else {
      if (repeat === 'all') {
        this.props.scheduleActionCreators.deleteRepeatableShift({
          id: this.state.currentShift.shift_repeat.id,
          handleSuccessDeleteShift: this.handleSuccessDeleteShift,
        });
      } else {
        this.props.scheduleActionCreators.deleteShift({
          id,
          handleSuccessDeleteShift: this.handleSuccessDeleteShift,
        });
      }
    }
  };

  handleSuccessDeleteShift = (success) => {
    const { status } = success;
    let params = {
      start: `${schedulerData.startDate}T00:00:00`,
      end: `${schedulerData.endDate}T23:59:59`,
      'filter[employees]': this.props.memberFilter,
    };
    if (this.state.areasIds.length) {
      params = { ...params, 'filter[area]': this.state.areasIds.join(',') };
    }
    let statisticsParams = {
      start: `${schedulerData.startDate}`,
      end: `${schedulerData.endDate}`,
    };
    params = `?${qs.stringify(params)}`;
    statisticsParams = `?${qs.stringify(statisticsParams)}`;

    if (status === 204) {
      let eventId = this.state.deleteShiftId;
      schedulerData.removeEventById(eventId);
      this.setState({
        deleteShiftId: '',
        isShowEditShift: false,
        isShowEditOffs: false,
        isShowConfirmPopup: false,
        isShowRepeatableDeletePopup: false,
      });
      this.props.scheduleActionCreators.getAllShift(params);
      this.props.scheduleActionCreators.getShiftsStatistics(statisticsParams);
    }
  };

  toggleEditShift = () => {
    const { isShowEditShift } = this.state;
    this.setState({
      isShowEditOffs: false,
      isShowEditShift: !isShowEditShift,
    });
  };

  toggleEditOffs = () => {
    const { isShowEditOffs } = this.state;
    this.setState({
      isShowEditShift: false,
      isShowEditOffs: !isShowEditOffs,
    });
  };

  togglePopup = () => {
    const { isShowConfirmPopup, isShowRepeatableDeletePopup, currentShift } = this.state;

    if (currentShift.shift_repeat) {
      this.setState({
        isShowRepeatableDeletePopup: !isShowRepeatableDeletePopup,
      });
    } else {
      this.setState({
        isShowConfirmPopup: !isShowConfirmPopup,
      });
    }
  };

  handleMoveError = (err) => {
    const { status, data } = err.response;
  };

  handleClickToday = () => {
    let date = moment();
    this.onSelectDate(schedulerData, date);
  };

  addOrDeleteAreas = (item) => {
    const { areas, areasIds } = this.state;
    let isExist = areas.includes(item);

    if (isExist) {
      let newAreas = areas.filter((el) => el.id !== item.id);
      let newIds = [];

      newAreas.filter((el) => {
        if (el.id !== item.id) {
          newIds.push(el.id);
        }
      });
      this.setState(
        {
          areas: newAreas,
          areasIds: newIds,
        },
        () => {
          let params;

          if (newIds.length !== 0) {
            params = {
              start: `${schedulerData.startDate}T00:00:00`,
              end: `${schedulerData.endDate}T23:59:59`,
              'filter[employees]': this.props.memberFilter,
              'filter[area]': newIds.join(','),
            };
          } else {
            params = {
              start: `${schedulerData.startDate}T00:00:00`,
              end: `${schedulerData.endDate}T23:59:59`,
              'filter[employees]': this.props.memberFilter,
            };
          }
          params = `?${qs.stringify(params)}`;
          this.props.scheduleActionCreators.getAllShift(params);
        }
      );
    } else {
      let newAreas = areas;
      let newIds = areasIds;
      newAreas.push(item);
      newIds.push(item.id);
      this.setState(
        {
          areas: newAreas,
          areasIds: newIds,
        },
        () => {
          let params = {
            start: `${schedulerData.startDate}T00:00:00`,
            end: `${schedulerData.endDate}T23:59:59`,
            'filter[employees]': this.props.memberFilter,
            'filter[area]': areasIds.join(','),
          };
          params = `?${qs.stringify(params)}`;
          this.props.scheduleActionCreators.getAllShift(params);
        }
      );
    }
  };

  handleChangeSearch = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };

  getSearchName = (name) => {
    this.setState({searchName: name})
  }

  render() {
    const { t } = this.props;
    let searchAreas = this.props.allAreas?.filter((o) =>
      o.areas.some((a) => a.name.toLowerCase().includes(this.state.searchInput.toLowerCase()))
    );

    let leftCustomHeader = (
      <div className="schedule-header">
        <div className="schedule-header-items">
          <button className="schedule-header__btn btn" onClick={this.handleClickToday}>
            {t('today')}
          </button>
        </div>
      </div>
    );
    let rightCustomHeader = (
      <div className="schedule-header-items">
        {this.state.areas.map((item, index) => {
          return (
            <button
              className="schedule-header-paginationBtn"
              id={item.id}
              onClick={() => this.addOrDeleteAreas(item)}
              key={index}
            >
              {item.name}
              <svg
                id={item.id}
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 5L5 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 5L15 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          );
        })}
        <div className="schedule-header-dropdown">
          <button
            type="button"
            className={
              this.props.myAccData.role !== 'EMPLOYEE'
                ? 'schedule-header-toggle__btn'
                : 'schedule-header-toggle__btn placed'
            }
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('select_areas')}
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.7">
                <path
                  d="M12.5 15L7.5 10L12.5 5"
                  stroke="#212529"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-input">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.9999 20.9999L16.6499 16.6499"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>

              <input
                type="text"
                placeholder="Search"
                value={this.state.searchInput}
                onChange={this.handleChangeSearch}
              />
            </div>
            <div className="dropdown-items">
              {searchAreas?.map((o, index) => {
                return (
                  <div key={index} className="py-2 d-flex flex-column">
                    <span className="dropdown-items__btn-title">{o.name}</span>
                    {o.areas.map((a, i) => {
                      let isActive;
                      if (this.state.areas.length !== 0) {
                        this.state.areas.map((el) => {
                          if (el.id == a.id) {
                            isActive = true;
                          }
                        });
                      }
                      return (
                        <button
                          className={`dropdown-items__btn ${isActive ? 'active' : ''}`}
                          id={a.id}
                          value={a.name}
                          onClick={() => this.addOrDeleteAreas(a)}
                          key={i}
                        >
                          {a.name}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
              {/* {searchAreas?.map((item, index) => {
                let isActive;
                if (this.state.areas.length !== 0) {
                  this.state.areas.map((el) => {
                    if (el.id == item.id) {
                      isActive = true;
                    }
                  });
                }
                return (
                  <button
                    className={`dropdown-items__btn ${isActive ? 'active' : ''}`}
                    id={item.id}
                    value={item.name}
                    onClick={() => this.addOrDeleteAreas(item)}
                    key={index}
                  >
                    {item.name}
                  </button>
                );
              })} */}
            </div>
          </div>
        </div>
        {this.props.myAccData.role !== 'EMPLOYEE' && (
          <Fragment>
            <button className="schedule-header__btn green mr-2" onClick={this.toggleAddShift}>
              {t('add_shift')}
            </button>
            <button className="schedule-header__btn green" onClick={this.toggleAddOffs}>
              {t('add_offs')}
            </button>
          </Fragment>
        )}
      </div>
    );
    const {
      isShowAddShift,
      isShowEditShift,
      isShowAddOffs,
      isShowEditOffs,
      isShowConfirmPopup,
      isShowRepeatableDeletePopup,
    } = this.state;
    return (
      <Fragment>
        <Scheduler
          schedulerData={schedulerData}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          newEvent={this.newEvent}
          leftCustomHeader={leftCustomHeader}
          rightCustomHeader={rightCustomHeader}
          slotItemTemplateResolver={this.slotItemTemplateResolver}
          nonAgendaCellHeaderTemplateResolver={this.cellHeaderTemplateResolver}
          subtitleGetter={this.eventItemSubtitleGetterResolver}
        />
        <CSSTransition in={isShowAddShift} timeout={300} classNames="show" unmountOnExit>
          <AddShift
            closeAddShift={this.toggleAddShift}
            schedulerData={schedulerData}
            date={this.state.addShiftDate}
            user={this.state.addShiftUser}
            copyData={this.state.copyData}
            areasIds={this.state.areasIds}
          />
        </CSSTransition>
        <CSSTransition in={isShowAddOffs} timeout={300} classNames="show" unmountOnExit>
          <AddOffs closeAddOffs={this.toggleAddOffs} areasIds={this.state.areasIds} schedulerData={schedulerData} />
        </CSSTransition>
        <CSSTransition in={isShowEditOffs} timeout={300} classNames="show" unmountOnExit>
          <EditOffs
            closeEditOffs={this.toggleEditOffs}
            shift={this.state.currentShift}
            showConfirmPopup={this.togglePopup}
            areasIds={this.state.areasIds}
            schedulerData={schedulerData}
          />
        </CSSTransition>
        <CSSTransition in={isShowEditShift} timeout={300} classNames="show" unmountOnExit>
          <EditShift
            closeEditShift={this.toggleEditShift}
            toggleAddShift={this.toggleAddShift}
            schedulerData={schedulerData}
            shift={this.state.currentShift}
            showConfirmPopup={this.togglePopup}
            areasIds={this.state.areasIds}
          />
        </CSSTransition>
        <CSSTransition in={isShowConfirmPopup} timeout={300} classNames="show" unmountOnExit>
          <Popup
            text={t('sure_delete_event')}
            onHandleClose={this.togglePopup}
            onHandleDelete={this.deleteShift}
            type="delete"
          />
        </CSSTransition>
        <CSSTransition in={isShowRepeatableDeletePopup} timeout={300} classNames="show" unmountOnExit>
          <RepeatableDeletePopup onHandleClose={this.togglePopup} onHandleDelete={this.deleteShift} />
        </CSSTransition>
        {this.props.showStatisticsTab && <StatisticsTab />}
        {this.props.showLoader && <FullPageLoader />}
      </Fragment>
    );
  }

  eventItemSubtitleGetterResolver = (schedulerData, eventItem) => {
    return eventItem.description && <h6>{eventItem.description}</h6>;
  };

  cellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;

    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    } else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
    }

    return (
      <th key={item.time} className={`header3-text ${isCurrentDate ? 'active-date' : ''}`} style={style}>
        {formattedDateItems.map((item, index) => {
          const newFormatItem = schedulerData.viewType === ViewTypes.Month ? datetime.format('D') : item;

          return (
            <div key={index}>
              <div>{newFormatItem}</div>
            </div>
          );
        })}
      </th>
    );
  };

  slotItemTemplateResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    const daySeconds = schedulerData.getSlotById(slot.slotId).daily_seconds;
    const nightSeconds = schedulerData.getSlotById(slot.slotId).nightly_seconds;
    const weekendSeconds = schedulerData.getSlotById(slot.slotId).weekend_seconds;
    const offsSeconds = schedulerData.getSlotById(slot.slotId).total_vacations;
    const totalSeconds = schedulerData.getSlotById(slot.slotId).total_seconds;
    const background = schedulerData.getSlotById(slot.slotId).bgColor;
    const employee_number = schedulerData.getSlotById(slot.slotId).employee_number;

    const day = convertSecondsToHours(daySeconds);
    const night = convertSecondsToHours(nightSeconds);
    const weekend = convertSecondsToHours(weekendSeconds);
    const offs = convertSecondsToHours(offsSeconds);
    const total = convertSecondsToHours(totalSeconds);

    return (
      <div title={slot.slotName} className="overflow-text header2-text" style={{ textAlign: 'left' }}>
        <span className="slot-cell">
          <span className="slot-text">
            {slot.slotName} ({employee_number})
          </span>
          <p className="slot-hours">
            <span className="text-primary">d: {day}h</span>&nbsp; <span className="text-danger">n: {night}h</span>
          </p>
          <p className="slot-hours">
            <span className="text-info">w: {weekend}h</span>
            &nbsp; <span className="text-warning">o: {offs}h</span>
            &nbsp; <span className="text-success">t: {total}h</span>
          </p>
        </span>
        <Link
          style={{ background }}
          className="scheduler-table-avatar"
          to={`/settings/employees/employee/${slot.slotId}`}
        >
          <p className="scheduler-table-avatar__name">{generateAvatarInitials(slot.slotName)}</p>
        </Link>
      </div>
    );
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    let param = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let params = {
      start: `${schedulerData.startDate}T00:00:00`,
      end: `${schedulerData.endDate}T23:59:59`,
      'filter[employees]': this.props.memberFilter,
    };
    if (this.state.areasIds.length) {
      params = { ...params, 'filter[area]': this.state.areasIds.join(',') };
    }
    let statisticsParams = {
      start: `${schedulerData.startDate}`,
      end: `${schedulerData.endDate}`,
    };
    statisticsParams = `?${qs.stringify(statisticsParams)}`;
    params = `?${qs.stringify(params)}`;
    this.props.scheduleActionCreators.getAllShift(params);
    this.props.scheduleActionCreators.getShiftsStatistics(statisticsParams);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    let param = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let params = {
      start: `${schedulerData.startDate}T00:00:00`,
      end: `${schedulerData.endDate}T23:59:59`,
      'filter[employees]': this.props.memberFilter,
    };
    if (this.state.areasIds.length) {
      params = { ...params, 'filter[area]': this.state.areasIds.join(',') };
    }
    let statisticsParams = {
      start: `${schedulerData.startDate}`,
      end: `${schedulerData.endDate}`,
    };
    params = `?${qs.stringify(params)}`;
    statisticsParams = `?${qs.stringify(statisticsParams)}`;
    this.props.scheduleActionCreators.getAllShift(params);
    this.props.scheduleActionCreators.getShiftsStatistics(statisticsParams);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData, event) => {
    this.setState(
      {
        deleteShiftId: event.recurringEventId || event.id,
        currentShift: event,
      },
      () => {
        if (this.state.currentShift.type) {
          this.toggleEditOffs();
        } else {
          this.toggleEditShift();
        }
      }
    );
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    let params = {
      start: `${schedulerData.startDate}T00:00:00`,
      end: `${schedulerData.endDate}T23:59:59`,
      'filter[employees]': this.props.memberFilter,
    };
    if (this.state.areasIds.length) {
      params = { ...params, 'filter[area]': this.state.areasIds.join(',') };
    }
    let statisticsParams = {
      start: `${schedulerData.startDate}`,
      end: `${schedulerData.endDate}`,
    };
 
    statisticsParams = `?${qs.stringify(statisticsParams)}`;
    params = `?${qs.stringify(params)}`;
    this.props.scheduleActionCreators.getAllShift(params);
    this.props.scheduleActionCreators.getShiftsStatistics(statisticsParams);

    this.setState({
      viewModel: schedulerData,
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item, isSave) => {
    if (this.props.myAccData.role !== 'EMPLOYEE') {
      this.setState({
        isShowAddShift: true,
        isShowAddOffs: false,
        addShiftDate: start,
        addShiftUser: schedulerData.getSlotById(slotId).id,
      });
    }
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    this.onSelectDate(schedulerData, moment());

    this.setState({
      viewModel: schedulerData,
    });
  };
}

function mapStateToProps(state) {
  return {
    myAccData: state.authReducer.myAccData,
    showStatisticsTab: state.viewReducer.showStatisticsTab,
  };
}

export default withTranslation()(connect(mapStateToProps, null)(withDragDropContext(Schedule)));
