import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withScriptjs } from 'react-google-maps';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { withTranslation } from 'react-i18next';

import * as organizationActionCreators from '../../../actions/organizationActionCreators';
import FullPageLoader from '../../helpComponent/FullPageLoader';

import AreaAddPopup from '../Popups/AreaAddPopup';
import AreaEditPopup from '../Popups/AreaEditPopup';
import ManagerAddPopup from '../Popups/ManagerAddPopup';
import ConfirmPopup from '../../helpComponent/Popup';

import AreaTable from './AreaTable';
import ManagerTable from './ManagerTable';

class OrganizationDetails extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      description: '',
      bgcolor: '#a7c5eb',
      address: '',
      gps_coordination: null,

      isShowAreaAddPopup: false,
      isShowAreaEditPopup: false,
      isShowAreaConfirmPopup: false,

      isShowManagerAddPopup: false,
      isShowManagerConfirmPopup: false,

      user_id: '',
      area_id: '',

      id: '',
      managerAreaId: '',
      areaId: '',
      areaError: {},
      managerError: {},
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.organizationActionCreators.getOrganizationAccount({
      id,
    });
    this.props.organizationActionCreators.getArea({ id });
    this.props.organizationActionCreators.getAsignedManagers({ orgId: id });
  }

  handleAreaAddPopup = () => {
    const { isShowAreaAddPopup } = this.state;
    this.setState({
      isShowAreaAddPopup: !isShowAreaAddPopup,
    });
  };

  handleAreaEditPopup = (id) => {
    const { isShowAreaEditPopup } = this.state;
    this.setState({
      isShowAreaEditPopup: !isShowAreaEditPopup,
      areaId: id,
      areaError: {},
    });
  };

  handleAreaConfirmPopup = (id) => {
    const { isShowAreaConfirmPopup } = this.state;
    this.setState({
      isShowAreaConfirmPopup: !isShowAreaConfirmPopup,
      id,
    });
  };

  handleSuccessArea = (success) => {
    const { status } = success;
    let id = this.props.match.params.id;
    if (status === 201) {
      this.props.organizationActionCreators.getArea({ id });
      this.setState({
        areaError: {},
        isShowAreaAddPopup: false,
        name: '',
        description: '',
      });
    }
  };

  handleSuccessUpdateArea = (success) => {
    const { status } = success;
    let id = this.props.match.params.id;
    if (status === 200) {
      this.setState({
        isShowAreaEditPopup: !this.state.isShowAreaEditPopup,
        areaError: {},
      });
      this.props.organizationActionCreators.getArea({ id });
    }
  };

  handleSuccessDeleteArea = (success) => {
    const { status } = success;
    let id = this.props.match.params.id;
    if (status === 204) {
      this.props.organizationActionCreators.getArea({ id });
      this.setState({
        isShowAreaConfirmPopup: false,
      });
    }
  };

  handleDeleteArea = () => {
    const { id } = this.state;
    const orgId = this.props.match.params.id;
    this.props.organizationActionCreators.deleteArea({
      areaId: id,
      orgId,
      handleSuccessDeleteArea: this.handleSuccessDeleteArea,
    });
  };

  handleErrorArea = (err) => {
    const { status, data } = err.response;

    if (status === 422) {
      this.setState({
        areaError: data.errors,
      });
    }
  };

  createArea = (e) => {
    e.preventDefault();
    let id = this.props.match.params.id;
    let data = {
      name: this.state.name,
      description: this.state.description,
      bgcolor: this.state.bgcolor,
      address: this.state.gps_coordination ? this.state.address : '',
      gps_coordination: this.state.gps_coordination,
    };
    this.props.organizationActionCreators.createAreas({
      data,
      id,
      handleSuccessArea: this.handleSuccessArea,
      handleErrorArea: this.handleErrorArea,
    });
  };

  handleManagerAddPopup = () => {
    const { isShowManagerAddPopup } = this.state;
    this.setState({
      isShowManagerAddPopup: !isShowManagerAddPopup,
    });
  };

  handleManagerConfirmPopup = (id, managerAreaId) => {
    const { isShowManagerConfirmPopup } = this.state;
    this.setState({
      isShowManagerConfirmPopup: !isShowManagerConfirmPopup,
      id,
      managerAreaId,
    });
  };

  handleSuccessAsignManager = (success) => {
    const { status } = success;
    let id = this.props.match.params.id;
    if (status === 201) {
      this.handleManagerAddPopup();
      this.setState({
        user_id: '',
        area_id: '',
        managerError: {},
      });
      this.props.organizationActionCreators.getAsignedManagers({ orgId: id });
    }
  };

  handleErrorAsignManager = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        managerError: data.errors,
      });
    }
  };

  handleDeleteAsignedManager = () => {
    const { id, managerAreaId } = this.state;

    this.props.organizationActionCreators.deleteAsignedManager({
      areaId: managerAreaId,
      userId: id,
      handleSuccessDeleteManager: this.handleSuccessDeleteManager,
    });
  };

  handleSuccessDeleteManager = (success) => {
    const { status } = success;
    let id = this.props.match.params.id;
    if (status === 200) {
      this.setState({
        id: '',
        managerAreaId: '',
        isShowManagerConfirmPopup: false,
      });
      this.props.organizationActionCreators.getAsignedManagers({ orgId: id });
    }
  };

  asignManager = () => {
    const { user_id, area_id } = this.state;
    const data = {
      user_id,
      area_id,
    };
    this.props.organizationActionCreators.asignManager({
      data,
      handleSuccessAsignManager: this.handleSuccessAsignManager,
      handleErrorAsignManager: this.handleErrorAsignManager,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeAddress = (address) => {
    this.setState({ address, gps_coordination: null });
  }

  handleSelectAddress = (address) => {
    this.handleChangeAddress(address);

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ gps_coordination: latLng });
      })
      .catch(error => console.error('Error', error));
  };

  handleChangeColor = (color) => {
    this.setState({
      bgcolor: color.hex,
    });
  };

  handleChangeArea = (selectedAreaId) => {
    this.setState({ area_id: selectedAreaId.value });
  };

  handleChangeManager = (selectedManagerId) => {
    this.setState({ user_id: selectedManagerId.value });
  };

  clearErrors = (form) => {
    this.setState({ [form]: {} });
  };

  render() {
    const { organizationData, area, asignedManagers, t } = this.props;
    const {
      isShowAreaAddPopup,
      isShowAreaConfirmPopup,
      isShowManagerAddPopup,
      isShowAreaEditPopup,
      isShowManagerConfirmPopup,
    } = this.state;
    return (
      <div className="organization">
        <div className="content organization-wrapper">
          <Link to="/settings/organization" className="addManager__link">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="#212529" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {t('back')}
          </Link>

          <div className="organization-tables-wrapper">
            <div className="organization-tables-wrapper-left">
              <div className="organization-info">
                <div className="organization-header">
                  <h6 className="organization-header__title">{organizationData?.name}</h6>
                  <Link className="organization__link" to={`/settings/organization/update/${organizationData?.id}`}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.7"
                        d="M14.1665 2.49993C14.3854 2.28106 14.6452 2.10744 14.9312 1.98899C15.2171 1.87054 15.5236 1.80957 15.8332 1.80957C16.1427 1.80957 16.4492 1.87054 16.7352 1.98899C17.0211 2.10744 17.281 2.28106 17.4998 2.49993C17.7187 2.7188 17.8923 2.97863 18.0108 3.2646C18.1292 3.55057 18.1902 3.85706 18.1902 4.16659C18.1902 4.47612 18.1292 4.78262 18.0108 5.06859C17.8923 5.35455 17.7187 5.61439 17.4998 5.83326L6.24984 17.0833L1.6665 18.3333L2.9165 13.7499L14.1665 2.49993Z"
                        stroke="#212529"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </div>

                <p className="organization-header__subtitle">{t('contact_info')}</p>

                <div className="organization-info-items">
                  <p className="organization-info__text light">{t('email')}</p>
                  <p className="organization-info__text">{organizationData?.contact_email}</p>
                </div>
                <div className="organization-info-items">
                  <p className="organization-info__text light">{t('phone')}</p>
                  <p className="organization-info__text">{organizationData?.contact_phone}</p>
                </div>
                <div className="organization-info-items">
                  <p className="organization-info__text light">{t('address')}</p>
                  <p className="organization-info__text">{organizationData?.address}</p>
                </div>
                <div className="organization-info-items">
                  <p className="organization-info__text light">{t('contact_person')}</p>
                  <p className="organization-info__text">{organizationData?.contact_person}</p>
                </div>
              </div>

              <form className="organization-areas" onSubmit={this.createArea}>
                <div className="organization-areas__header">
                  <p className="organization-areas__header__text">{t('areas')}</p>
                  <button className="organization-areas__header__btn" type="button" onClick={this.handleAreaAddPopup}>
                    {t('add_area')}
                  </button>
                </div>
                <AreaTable
                  handlePopup={this.handleAreaConfirmPopup}
                  handleEditPopup={this.handleAreaEditPopup}
                  area={area}
                />
                <div className="organization-areas-footer">
                  <p className="organization-areas-footer__text">{area?.length} areas added</p>
                </div>
              </form>
            </div>

            <div className="organization-tables-wrapper-right">
              <form className="organization-areas" onSubmit={this.submitForm}>
                <div className="organization-areas__header">
                  <p className="organization-areas__header__text">{t('managers')}</p>
                  <button
                    className="organization-areas__header__btn"
                    type="button"
                    onClick={this.handleManagerAddPopup}
                  >
                    {t('assign_manager')}
                  </button>
                </div>
                <ManagerTable
                  handlePopup={this.handleManagerConfirmPopup}
                  asignedManagers={asignedManagers}
                  orgId={this.props.match.params.id}
                />
                <div className="organization-areas-footer">
                  <p className="organization-areas-footer__text">{asignedManagers?.length} {t('assigned_managers')}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
        <CSSTransition in={isShowAreaAddPopup} timeout={300} classNames="show" unmountOnExit>
          <AreaAddPopup
            closePopup={this.handleAreaAddPopup}
            errors={this.state.areaError}
            handleChange={this.handleChange}
            handleChangeAddress={this.handleChangeAddress}
            handleSelectAddress={this.handleSelectAddress}
            handleChangeColor={this.handleChangeColor}
            address={this.state.address}
            bgcolor={this.state.bgcolor}
            displayColorPicker={this.state.displayColorPicker}
            createArea={this.createArea}
            clearErrors={this.clearErrors}
          />
        </CSSTransition>
        <CSSTransition in={isShowAreaEditPopup} timeout={300} classNames="show" unmountOnExit>
          <AreaEditPopup
            handlePopupClose={this.handleAreaEditPopup}
            areaId={this.state.areaId}
            orgId={this.props.match.params.id}
            handleSuccessUpdateArea={this.handleSuccessUpdateArea}
            handleErrorUpdateArea={this.handleErrorArea}
            errors={this.state.areaError}
          />
        </CSSTransition>
        <CSSTransition in={isShowAreaConfirmPopup} timeout={300} classNames="show" unmountOnExit>
          <ConfirmPopup
            type="delete"
            text="Are you sure you want to delete an area?"
            onHandleClose={this.handleAreaConfirmPopup}
            onHandleDelete={this.handleDeleteArea}
          />
        </CSSTransition>

        <CSSTransition in={isShowManagerAddPopup} timeout={300} classNames="show" unmountOnExit>
          <ManagerAddPopup
            closePopup={this.handleManagerAddPopup}
            asignManager={this.asignManager}
            handleChangeManager={this.handleChangeManager}
            handleChangeArea={this.handleChangeArea}
            area={area}
            managerError={this.state.managerError}
            clearErrors={this.clearErrors}
          />
        </CSSTransition>
        <CSSTransition in={isShowManagerConfirmPopup} timeout={300} classNames="show" unmountOnExit>
          <ConfirmPopup
            type="delete"
            text="Are you sure you want to delete a manager?"
            onHandleClose={this.handleManagerConfirmPopup}
            onHandleDelete={this.handleDeleteAsignedManager}
          />
        </CSSTransition>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoader: state.viewReducer.showLoader,
    showSmallLoader: state.viewReducer.showSmallLoader,
    organizationData: state.organizationReducer.organizationData,
    area: state.organizationReducer.area,
    asignedManagers: state.organizationReducer.asignedManagers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActionCreators: bindActionCreators(organizationActionCreators, dispatch),
  };
}

export default withTranslation()(withScriptjs(connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails)));
