import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import RadioButton from '../helpComponent/RadioButton';

class RepeatableDeletePopup extends Component {
  constructor() {
    super();

    this.state = {
      delete: 'this',
    };
  }

  handleChangeRadio = (e) => {
    this.setState({ delete: e.target.value });
  };

  render() {
    const { onHandleDelete, onHandleClose, t } = this.props;

    return (
      <div className="notify-overlay">
        <div className="organization-popup">
          <h6 className="organization-popup__title">{t('delete_reccuring_shift')}</h6>
          <div className="organization-popup-items">
            <RadioButton
              text={t('only_this_shift')}
              name="this"
              value="this"
              isCheckedRadioBtn={this.state.delete === 'this'}
              onClick={this.handleChangeRadio}
            />
            <RadioButton
              text={t('all_shifts')}
              name="all"
              value="all"
              isCheckedRadioBtn={this.state.delete === 'all'}
              onClick={this.handleChangeRadio}
            />
          </div>
          <div className="organization-popup-buttons">
            <button className="organization-popup-buttons__addBtn" onClick={() => onHandleDelete(this.state.delete)}>
              {t('ok')}
            </button>
            <button className="organization-popup-buttons__cancelBtn" onClick={onHandleClose}>
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RepeatableDeletePopup);
