import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Select from 'react-select';
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getMonth, getYear } from 'date-fns';
import { withTranslation } from 'react-i18next';

import * as administratorActionCreators from '../../actions/administratorActionCreators';
import * as authActionCreators from '../../actions/authActionCreators';
import checkUserPermission from '../../Utils/CheckUserPermission';
import AdditionalInformation from '../AddManager/AdditionalInformation';

const genders = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];
const roles = [
  { label: 'Employee', value: 'EMPLOYEE' },
  { label: 'Manager', value: 'MANAGER' },
  { label: 'Admin', value: 'ADMIN' },
];

class Edit extends Component {
  constructor() {
    super();
    this.state = {
      file: [],
      firstName: '',
      lastName: '',
      email: '',
      ssn: '',
      bank_account: '',
      address: '',
      phone: '',
      language: '',
      role: 'ADMIN',
      errors: {},
      image: '',
      relative_name: '',
      relative_last_name: '',
      relative_phone: '',
      employee_number: '',
      birthday: null,
      years: [],
      sex: 'MALE',
    };
  }

  componentDidMount() {
    this.range();
  }

  componentDidUpdate(prevProps) {
    const { adminData } = this.props;
    if (this.props.adminData !== prevProps.adminData) {
      this.setState({
        firstName: adminData.first_name,
        lastName: adminData.last_name,
        email: adminData.email,
        ssn: adminData.ssn || '',
        bank_account: adminData.bank_account || '',
        address: adminData.address || '',
        phone: adminData.phone || '',
        role: adminData.role,
        isActive: adminData.is_active,
        language: adminData.language,
        relative_name: adminData.relative_name || '',
        relative_last_name: adminData.relative_last_name || '',
        relative_phone: adminData.relative_phone || '',
        employee_number: adminData.employee_number || '',
        birthday: adminData.birthday && new Date(adminData.birthday),
        sex: adminData.sex || 'MALE',
      });
    }
  }

  submitForm = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      ssn,
      bank_account,
      address,
      phone,
      language,
      role,
      isActive,
      relative_name,
      relative_last_name,
      relative_phone,
      employee_number,
      birthday,
      sex,
    } = this.state;
    const id = this.props.id;
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      language,
      phone,
      address,
      ssn,
      bank_account,
      is_active: isActive,
      role,
      relative_name,
      relative_last_name,
      relative_phone,
      employee_number,
      birthday: birthday && moment(birthday).format('yyyy-MM-DD'),
      sex,
    };
    this.props.administratorActionCreators.updateAdministrator({
      id,
      data,
      handleError: this.handleError,
      handleSuccess: this.handleSuccess,
    });
  };

  handleSuccess = (success) => {
    const { status, data } = success;
    if (status === 200) {
      if (data.data.id === this.props.myAccData.id) this.props.authActionCreators.getAccount();
      this.props.history.push('/settings/administrators');
    }
  };

  handleError = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeLanguage = (selectedLanguage) => {
    this.setState({ language: selectedLanguage.value });
  };

  handleChangeRole = (selectedRole) => {
    this.setState({ role: selectedRole.value });
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  range = (startAt = 1930) => {
    const d = new Date();
    let size = d.getFullYear() - startAt + 1;
    let years = [...Array(size).keys()].map((i) => i + startAt);
    this.setState({
      years: years.reverse(),
    });
  };

  render() {
    const { isActive, errors, birthday, sex, role } = this.state;
    const { adminData, myAccData, t } = this.props;
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const language = [
      { value: 'en', label: t('english') },
      { value: 'sv', label: t('swedish') },
      { value: 'es', label: t('spanish') },
    ];
    return (
      <div className="d-flex">
        <div className="addManager-left">
          <h6 className="addManager-left__title">
            {!!adminData && adminData.first_name} {!!adminData && adminData.last_name}
          </h6>
          <form className="addManager-left__form" onSubmit={this.submitForm} id="form">
            <div className="addManager-avatarUploader-wrapper">
              <label className="addManager-avatarUploader">
                <input type="file" onChange={this.onImageChange} id="group_image" />
                {this.state.image.length !== 0 && (
                  <img id="target" className="addManager-avatarUploader-preview" src={this.state.image} />
                )}
                <img className="addManager-avatarUploader__img" src="/images/camera.svg" />
              </label>
              <div className="d-flex">
                {isActive ? (
                  <div className="d-flex align-items-center">
                    <svg
                      className="addManager-activeIcon"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.66634 4L4.99967 7.66667L3.33301 6"
                        stroke="#0B9444"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <circle cx="6" cy="6" r="5.5" stroke="#0B9444" />
                    </svg>
                    <p className="addManager-left__text">{t('active')}</p>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <p className="addManager-left__text">{t('inactive')}</p>
                  </div>
                )}
                <Switch
                  className={`switcher ${isActive ? 'active' : 'inactive'}`}
                  onChange={() => {
                    this.setState({ isActive: !isActive });
                  }}
                  checked={!!isActive}
                />
              </div>
            </div>
            <div className="addManager-left__form-input-wrapper">
              <div className="addManager-left__form-input-wrapper-items name w-100">
                <label className="addManager-left__form-input-wrapper-items__label">
                  {t('first_name')} <span>*</span>
                </label>
                <input
                  className={`addManager-left__form-input-wrapper-items__input ${errors.first_name && 'error-input'}`}
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                {errors.first_name && <p className="error-input-text mb-0">{errors.first_name}</p>}
              </div>
              <div className="addManager-left__form-input-wrapper-items w-100">
                <label className="addManager-left__form-input-wrapper-items__label">
                  {t('last_name')} <span>*</span>
                </label>
                <input
                  className={`addManager-left__form-input-wrapper-items__input ${errors.last_name && 'error-input'}`}
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                {errors.last_name && <p className="error-input-text mb-0">{errors.last_name}</p>}
              </div>
            </div>

            <div>
              <div className="addManager-left__form-input-wrapper-items">
                <label className="addManager-left__form-input-wrapper-items__label">
                  {t('email')} <span>*</span>
                </label>
                <input
                  className={`addManager-left__form-input-wrapper-items__input ${errors.email && 'error-input'}`}
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                {errors.email && <p className="error-input-text mb-0">{errors.email}</p>}
              </div>
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('employee_number')}</label>
              <input
                className="addManager-left__form-input-wrapper-items__input"
                type="text"
                name="employee_number"
                value={this.state.employee_number}
                onChange={this.handleChange}
              />
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('ssn_number')}</label>
              <input
                className="addManager-left__form-input-wrapper-items__input"
                type="text"
                name="ssn"
                value={this.state.ssn}
                onChange={this.handleChange}
              />
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('bank_number')}</label>
              <input
                className="addManager-left__form-input-wrapper-items__input"
                type="text"
                name="bank_account"
                value={this.state.bank_account}
                onChange={this.handleChange}
              />
            </div>

            {checkUserPermission(myAccData, 'component.roleField') && (
              <div className="addManager-left__form-input-wrapper-items">
                <label className="addManager-left__form-input-wrapper-items__label">{t('role')}</label>
                <Select
                  className={`addManager-left__form-input-wrapper-items__select`}
                  options={roles}
                  name="role"
                  onChange={this.handleChangeRole}
                  value={roles.find((item) => item.value === role)}
                />
              </div>
            )}

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('sex')}</label>
              <Select
                className={`addManager-left__form-input-wrapper-items__select`}
                options={genders}
                placeholder={t('select')}
                name="sex"
                onChange={(item) => this.setState({ sex: item.value })}
                value={genders.find((item) => item.value === sex)}
              />
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('birthday')}</label>
              <DatePicker
                showPopperArrow={false}
                selected={birthday}
                onChange={(value) => this.setState({ birthday: value })}
                dateFormat="yyyy-MM-dd"
                className="date-picker"
                calendarClassName="date-calendar"
                maxDate={new Date()}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                      className="date-picker-button__prev"
                      type="button"
                    >
                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.25 10.5L1.75 6L6.25 1.5"
                          stroke="#0B9444"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                      className="date-picker-select year"
                    >
                      {this.state.years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[getMonth(date)]}
                      className="date-picker-select"
                      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                      className="date-picker-button__next"
                      type="button"
                    >
                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.25 10.5L1.75 6L6.25 1.5"
                          stroke="#0B9444"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              />
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">{t('address')}</label>
              <input
                className="addManager-left__form-input-wrapper-items__input"
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">
                {t('phone_number')} <span>*</span>
              </label>
              <input
                className={`addManager-left__form-input-wrapper-items__input ${errors.phone && 'error-input'}`}
                type="text"
                name="phone"
                value={this.state.phone}
                onChange={this.handleChange}
              />
              {errors.phone && <p className="error-input-text mb-0">{errors.phone}</p>}
            </div>

            <div className="addManager-left__form-input-wrapper-items">
              <label className="addManager-left__form-input-wrapper-items__label">
                {t('language')} <span>*</span>
              </label>
              <Select
                className={`addManager-left__form-input-wrapper-items__select ${errors.language && 'error-select'}`}
                options={language}
                placeholder={t('select')}
                value={language.filter((option) => {
                  if (option.value == this.state.language) {
                    return option.label;
                  }
                })}
                // inputValue={this.state.language}
                name="language"
                onChange={this.handleChangeLanguage}
              />
              {errors.language && <p className="error-input-text mb-0">{errors.language}</p>}
            </div>
            <div className="addManager-buttons">
              <button type="submit" className="addManager-buttons__saveBtn">
                {t('update')}
              </button>
              <button
                type="button"
                className="addManager-buttons__cancelBtn"
                onClick={() => this.props.history.push('/settings/administrators')}
              >
                {t('cancel')}
              </button>
            </div>
          </form>
        </div>
        <AdditionalInformation
          handleChange={this.handleChange}
          relative_name={this.state.relative_name}
          relative_last_name={this.state.relative_last_name}
          relative_phone={this.state.relative_phone}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myAccData: state.authReducer.myAccData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    administratorActionCreators: bindActionCreators(administratorActionCreators, dispatch),
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Edit));
