import React, { Component } from 'react';
import { connect } from 'react-redux';

import Select from 'react-select';
import { withTranslation } from 'react-i18next';

const week = [
  ['Sun', 'SU'],
  ['Mon', 'MO'],
  ['Tue', 'TU'],
  ['Wed', 'WE'],
  ['Thu', 'TH'],
  ['Fri', 'FR'],
  ['Sat', 'SA'],
];
const month = createMonthArray();
const year = [
  ['Jan', 1],
  ['Feb', 2],
  ['Mar', 3],
  ['Apr', 4],
  ['May', 5],
  ['Jun', 6],
  ['Jul', 7],
  ['Aug', 8],
  ['Sep', 9],
  ['Oct', 10],
  ['Nov', 11],
  ['Dec', 12],
];

function createMonthArray() {
  const arr = [];

  for (let i = 1; i < 32; i++) {
    arr.push([i, i]);
  }
  return arr;
}

export class RepeatModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repeat: props.data.repeat || 'daily',
      repeat_every: props.data.repeat_every,
      repeat_on: [...props.data.repeat_on],
      repeat_all_items: [],
      error: '',
    };
  }

  componentDidMount() {
    this.setAllRepeats(this.state.repeat);
  }

  setAllRepeats = (repeat) => {
    switch (repeat) {
      case 'daily':
        this.setState({ repeat_all_items: [] });
        break;
      case 'weekly':
        this.setState({ repeat_all_items: week });
        break;
      case 'monthly':
        this.setState({ repeat_all_items: month });
        break;
      case 'yearly':
        this.setState({ repeat_all_items: year });
        break;
    }
  };

  handleChangeSelect = (repeat) => {
    this.setState({ repeat: repeat.value, repeat_every: 1, repeat_on: [], error: '' });
    this.setAllRepeats(repeat.value);
  };

  handleChangeInput = (e) => {
    const { name, value, validity } = e.target;

    this.setState({
      [name]: validity.valid ? +value : this.state[name],
      error: '',
    });
  };

  handleChangeEach = (value) => {
    const { repeat_on } = this.state;
    let findIndex = repeat_on.findIndex((el) => el === value);

    if (findIndex > -1) {
      repeat_on.splice(findIndex, 1);
    } else {
      repeat_on.push(value);
    }
    this.setState({ repeat_on, error: '' });
  };

  saveRepeats = () => {
    const { repeat, repeat_every, repeat_on } = this.state;
    if ((repeat_on.length === 0 && repeat !== 'daily') || repeat_every === 0) {
      this.setState({ error: 'Select at least one shift recurrence' });
      return;
    }
    this.props.saveRepeats(this.state);
  };

  render() {
    const { closePopup, saveRepeats, t } = this.props;
    const { repeat, repeat_every, repeat_on, repeat_all_items, error } = this.state;
    const repeatsList = [
      { label: t('daily'), value: 'daily' },
      { label: t('weekly'), value: 'weekly' },
      { label: t('monthly'), value: 'monthly' },
      { label: t('yearly'), value: 'yearly' },
    ];

    return (
      <div className="notify-overlay">
        <div className="organization-popup">
          <h6 className="organization-popup__title">{t('repeat_shift')}</h6>
          {error && <p className="error-input-text ml-0 mb-1 mt-0">{error}</p>}
          <div className="organization-popup-items">
            <div className="addManager-left__form-input-wrapper-items organization-popup__select repeat-field">
              <label className="addManager-left__form-input-wrapper-items__label">{t('repeats')}</label>
              <Select
                className={`addManager-left__form-input-wrapper-items__select repeat-field-repeats`}
                options={repeatsList}
                onChange={this.handleChangeSelect}
                name="repeat"
                value={repeatsList.find((item) => item.value === repeat)}
              />
            </div>
            <div className="addManager-left__form-input-wrapper-items organization-popup__select repeat-field">
              <label className="addManager-left__form-input-wrapper-items__label">Repeat every</label>
              <input
                type="text"
                pattern="[0-9]*"
                name="repeat_every"
                className="addShift-input repeat-field-every"
                type="text"
                value={repeat_every}
                onChange={this.handleChangeInput}
              />
            </div>
            {!!repeat_all_items.length && (
              <div className="addManager-left__form-input-wrapper-items organization-popup__select">
                <label className="addManager-left__form-input-wrapper-items__label mb-3">Repeat on</label>
                <div className="repeat-field-each">
                  {repeat_all_items.map((item, index) => {
                    const isItemOn = repeat_on.includes(item[1]);

                    return (
                      <span
                        onClick={() => this.handleChangeEach(item[1])}
                        className={`${isItemOn ? 'active' : ''}`}
                        key={index}
                      >
                        {item[0]}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="organization-popup-buttons">
            <button onClick={this.saveRepeats} className="organization-popup-buttons__addBtn">
              {t('save')}
            </button>
            <button onClick={closePopup} className="organization-popup-buttons__cancelBtn" type="button">
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RepeatModal));
