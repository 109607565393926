import React from 'react';
import { Component, Fragment } from 'react';
import Table from './Table';
import { CSSTransition } from 'react-transition-group';
import FullPageLoader from '../helpComponent/FullPageLoader';
import Popup from '../helpComponent/Popup';
import qs from 'query-string';

import { bindActionCreators } from 'redux';
import * as managerActionCreators from '../../actions/managerActionCreators';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      isShowMenu: false,
      isShowAgreePopup: false,
      searchValue: '',
      users: [],
      deleteId: '',
      pagination: {
        page: 1,
        limit: 10,
        search: '',
      },
      typingTimeout: 0,
    };
  }
  componentDidMount() {
    this.setState({
      users: this.props.managers,
    });
    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });
    this.setState({ pagination }, () => {
      let params = `?${qs.stringify(pagination)}`;
      this.props.managerActionCreators.getManager({ params });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.managers !== prevProps.managers) {
      this.setState({
        users: this.props.managers,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  handlePopup = (id) => {
    this.setState({
      isShowAgreePopup: !this.state.isShowAgreePopup,
      deleteId: id,
    });
  };

  handleDelete = () => {
    const { deleteId } = this.state;
    this.props.managerActionCreators.deleteManager({
      id: deleteId,
      handleDeleteSuccess: this.handleDeleteSuccess,
    });
  };

  handleChangeSearch = (e) => {
    const self = this;
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.search = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      pagination,
      typingTimeout: setTimeout(function () {
        self.props.managerActionCreators.getManager({ params });
      }, 500),
    });
    history.push({ search: params });
  };

  handleDeleteSuccess = (response) => {
    const { status } = response;
    const { pagination } = this.state;
    const { history } = this.props;
    if (status === 200) {
      this.setState({
        isShowAgreePopup: false,
        deleteId: '',
      });
    }
    let params = `?${qs.stringify(pagination)}`;
    this.props.managerActionCreators.getManager({
      params,
    });
    history.push({ search: params });
  };

  handlePageChange = (page) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.page = page;
    let params = `?${qs.stringify(pagination)}`;
    this.props.managerActionCreators.getManager({
      params,
    });
    history.push({ search: params });
  };

  handleSelect = (e) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.limit = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;
    this.props.managerActionCreators.getManager({
      params,
    });
    history.push({ search: params });
  };

  toggleActivation = (id, isActive) => {
    let active = isActive === 1 ? 0 : 1;
    this.props.managerActionCreators.toggleActivationManager({
      id,
      active,
      handleActivateSuccess: this.handleActivateSuccess,
    });
  };

  handleActivateSuccess = (response) => {
    const { status } = response;
    const { pagination } = this.state;
    const { history } = this.props;
    if (status === 200) {
      let params = `?${qs.stringify(pagination)}`;
      this.props.managerActionCreators.getManager({
        params,
      });
      history.push({ search: params });
    }
  };

  render() {
    const { managers, meta, t } = this.props;
    const { searchValue, isShowAgreePopup } = this.state;
    // const filtredTable =
    //   !!this.state.users &&
    //   this.state.users.filter((item) => {
    //     return item.name
    //       .toLowerCase()
    //       .includes(this.state.searchValue.toLocaleLowerCase());
    //   });
    return (
      <Fragment>
        <CSSTransition in={isShowAgreePopup} timeout={300} classNames="show" unmountOnExit>
          <Popup
            type={t('delete')}
            text={t('sure_delete_manager')}
            onHandleClose={this.handlePopup}
            onHandleDelete={this.handleDelete}
          />
        </CSSTransition>
        <div className="settings full-height">
          <div className="content">
            <div>
              <Table
                managers={managers}
                handleChangeSearch={this.handleChangeSearch}
                meta={meta}
                searchValue={this.state.pagination.search}
                handleChange={this.handleChange}
                handlePopup={this.handlePopup}
                count={meta?.last_page}
                handlePageChange={this.handlePageChange}
                handleSelect={this.handleSelect}
                toggleActivation={this.toggleActivation}
                page={this.state.pagination.page}
                selectValue={this.state.pagination.limit}
              />
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    managers: state.managerReducer.managers,
    meta: state.managerReducer.meta,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    managerActionCreators: bindActionCreators(managerActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Settings));
