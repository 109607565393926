import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Select from 'react-select';
import Avatar from 'react-avatar';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';

import * as scheduleActionCreators from '../../actions/scheduleActionCreators';

const Option = ({ value, label }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar name={label} size="22" round="50%" className="mr-3" />
      <div>{label}</div>
    </div>
  );
};
const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div className="d-flex cursor-pointer" {...restInnerProps} ref={ref}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5L5 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 5L15 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

class AddOffs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'TYPE_DAY_OFF',
      user_id: '',
      start_at: new Date(),
      end_at: new Date(),
      description: '',
      errors: {},
    };
  }
  componentDidMount() {
    this.props.scheduleActionCreators.getUsers({
      filter: 'EMPLOYEE,MANAGER',
      active: 1,
    });
  }

  handleChangeText = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeSelect = (selected, e) => {
    let value = !!selected ? selected.value : '';
    this.setState({ [e.name]: value });
  };

  handleChangeDate = (date, name) => {
    this.setState({ [name]: date });
    if (name === 'start_at' && moment(this.state.end_at).isBefore(date, 'day')) {
      this.setState({ end_at: date });
    }
  };

  handleOffsResponse = (response) => {
    const { status, data } = response;
    const { schedulerData, areasIds, memberFilter, closeAddOffs, scheduleActionCreators } = this.props;
    if (status === 201) {
      this.setState({
        errors: {},
      });
      let params = {
        start: `${schedulerData.startDate}T00:00:00`,
        end: `${schedulerData.endDate}T23:59:59`,
        'filter[employees]': memberFilter,
      };
      if (areasIds.length) {
        params = { ...params, 'filter[area]': areasIds.join(',') };
      }
      let statisticsParams = {
        start: `${schedulerData.startDate}`,
        end: `${schedulerData.endDate}`,
      };
      params = `?${qs.stringify(params)}`;
      statisticsParams = `?${qs.stringify(statisticsParams)}`;
      closeAddOffs();
      scheduleActionCreators.getAllShift(params);
      scheduleActionCreators.getShiftsStatistics(statisticsParams);
    } else if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  createOffs = () => {
    const { type, user_id, start_at, end_at, description } = this.state;

    let data = {
      type,
      user_id,
      start_at: moment(start_at).format(`YYYY-MM-DD`) + 'T00:00:00',
      end_at: moment(end_at).format(`YYYY-MM-DD`) + 'T23:59:59',
      description,
    };

    this.props.scheduleActionCreators.userOffsCreate({
      data,
      callback: this.handleOffsResponse,
    });
  };

  render() {
    const { users, t } = this.props;
    const { errors, start_at, end_at, type } = this.state;
    const types = [
      { label: t('day_off'), value: 'TYPE_DAY_OFF' },
      { label: t('vacation'), value: 'TYPE_VACATION' },
      { label: t('sick_leave'), value: 'TYPE_SICK_LEAVE' },
      { label: t('parental_leave'), value: 'TYPE_PARENTAL_LEAVE' },
    ];
    const usersList = users?.map((item) => {
      return { label: `${item.first_name} ${item.last_name}`, value: item.id };
    });

    return (
      <div className="addShift">
        <div className="addShift-header">
          <p className="addShift-header__title">{t('add_offs')}</p>
          <button type="button" className="addShift-header__btn" onClick={this.props.closeAddOffs}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5L5 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 5L15 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        <div className="addShift-content">
          <div className="addManager-left__form-input-wrapper-items organization-popup__select">
            <label className="addManager-left__form-input-wrapper-items__label">{t('employee')}</label>
            <Select
              className={`addManager-left__form-input-wrapper-items__select ${errors.user_id && 'error-select'}`}
              formatOptionLabel={Option}
              components={{ ClearIndicator }}
              noOptionsMessage={() => 'No users'}
              options={usersList}
              isClearable={true}
              placeholder={t('select_employee')}
              onChange={this.handleChangeSelect}
              styles={{
                option: (base, state) => ({
                  ...base,
                  height: '100%',
                  backgroundColor: state.isSelected ? '#F6F7F9' : null,
                  color: '#212529',
                  '&:hover': {
                    backgroundColor: '#F6F7F9',
                  },
                }),
              }}
              name="user_id"
            />
            {errors.user_id && <p className="error-input-text mb-0 mt-1">{errors.user_id}</p>}
          </div>
          <div className="addManager-left__form-input-wrapper-items organization-popup__select">
            <label className="addManager-left__form-input-wrapper-items__label">{t('type')}</label>
            <Select
              className={`addManager-left__form-input-wrapper-items__select ${errors.type && 'error-select'}`}
              options={types}
              onChange={this.handleChangeSelect}
              name="type"
              value={types.find((item) => item.value === type)}
            />
            {errors.type && <p className="error-input-text mb-0 mt-1">{errors.type}</p>}
          </div>
          <div className="addManager-left__form-input-wrapper-items">
            <label className="addManager-left__form-input-wrapper-items__label">{t('date_start')}</label>
            <DatePicker
              popperPlacement="top-start"
              popperModifiers={{
                flip: {
                  enabled: false,
                },
              }}
              showPopperArrow={false}
              selected={start_at}
              onChange={(value) => this.handleChangeDate(value, 'start_at')}
              dateFormat="yyyy-MM-dd"
              className={`date-picker schedule-bg ${errors.start_at && 'error-input'}`}
              calendarClassName="date-calendar"
            />
            {errors.start_at && <p className="error-input-text mb-0 mt-1">{errors.start_at}</p>}
          </div>
          <div className="addManager-left__form-input-wrapper-items">
            <label className="addManager-left__form-input-wrapper-items__label">{t('date_end')}</label>
            <DatePicker
              popperPlacement="top-start"
              popperModifiers={{
                flip: {
                  enabled: false,
                },
              }}
              showPopperArrow={false}
              selected={end_at}
              onChange={(value) => this.handleChangeDate(value, 'end_at')}
              dateFormat="yyyy-MM-dd"
              className={`date-picker schedule-bg ${errors.end_at && 'error-input'}`}
              calendarClassName="date-calendar"
            />
            {errors.end_at && <p className="error-input-text mb-0 mt-1">{errors.end_at}</p>}
          </div>
          <div className="addManager-left__form-input-wrapper-items">
            <label className="addManager-left__form-input-wrapper-items__label">{t('description')}</label>
            <textarea
              className="addShift-textarea"
              placeholder={t('write_some_comments')}
              name="description"
              onChange={this.handleChangeText}
            />
          </div>
          <div className="addShift-content-buttons">
            <button className="addShift-content-buttons__save" onClick={this.createOffs} value="save">
              {t('save')}
            </button>
            <button className="addShift-content-buttons__cancel" onClick={this.props.closeAddOffs}>
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.scheduleReducer.users,
    memberFilter: state.scheduleReducer.memberFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    scheduleActionCreators: bindActionCreators(scheduleActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddOffs));
