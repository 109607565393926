import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import qs from 'query-string';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import * as reportsActionCreators from '../../../actions/reportsActionCreators';
import Pagination from '../../helpComponent/Pagination';
import SmallLoader from '../../helpComponent/SmallLoader';
// import SidebarReport from '../SidebarReport';
import RangeDatePicker from '../../helpComponent/RangeDatePicker';
import { convertTimeToHours } from '../../../Utils/helpers'

const START_DATE = 'YYYY-MM-DDT00:00:00';
const END_DATE = 'YYYY-MM-DDT23:59:59';

class ReportsAreasTable extends Component {
  constructor() {
    super();
    this.state = {
      startDate: null,
      endDate: null,
      pagination: {
        page: 1,
        limit: 10,
        name: '',
        start: moment().add(-30, 'days').format(START_DATE),
        end: moment().format(END_DATE),
      },
      // selectedRow: {},
      typingTimeout: 0,
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });
    const startDate = moment(pagination.start).toDate();
    const endDate = moment(pagination.end).toDate();

    this.setState({ pagination, startDate, endDate }, () => {
      let params = `?${qs.stringify(pagination)}`;
      this.props.reportsActionCreators.getAreasStatistics({ params });
    });
  }

  handlePageChange = (page) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.page = page;
    let params = `?${qs.stringify(pagination)}`;
    this.props.reportsActionCreators.getAreasStatistics({ params });
    history.push({ search: params });
  };

  handleSelect = (e) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.limit = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;
    this.props.reportsActionCreators.getAreasStatistics({ params });
    history.push({ search: params });
  };

  handleChangeSearch = (e) => {
    const self = this;
    const { pagination } = this.state;
    const { history } = this.props;

    pagination.name = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      pagination,
      typingTimeout: setTimeout(function () {
        self.props.reportsActionCreators.getAreasStatistics({ params });
      }, 500),
    });
    history.push({ search: params });
  };

  clearSearch = () => {
    const { pagination } = this.state;

    pagination.page = 1;
    pagination.name = '';
    let params = `?${qs.stringify(pagination)}`;

    this.props.reportsActionCreators.getAreasStatistics({ params });
    this.props.history.push({ search: params });
  };

  handleChangeDate = ({ startDate, endDate }) => {
    const { pagination } = this.state;
    const { history } = this.props;

    pagination.start = moment(startDate).format('YYYY-MM-DDT00:00:00');
    pagination.end = moment(endDate).format('YYYY-MM-DDT23:59:59');
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (!!startDate && !!endDate) {
      this.props.reportsActionCreators.getAreasStatistics({ params });
      history.push({ search: params });
    }
  };

  // setSelectedRow = (selectedRow) => {
  //   if (selectedRow.area.id === this.state.selectedRow.area?.id) {
  //     this.setState({ selectedRow: {} });
  //   } else {
  //     this.setState({ selectedRow });
  //   }
  // };

  render() {
    const { areasStatistics, meta, t } = this.props;
    const { startDate, endDate, pagination, selectedRow} = this.state;
    // const selectedRowExists = Object.keys(selectedRow).length !== 0;

    return (
      <div className="d-flex">
        <div className="content">
          <div className="settings-wrapper d-flex">
            <div className={`settings-table reports-table w-100`}>
              <div className="reports-table-header">
                <h6 className="reports-table-header__text">{t('statistics_by_areas')}</h6>
                <div className="reports-table-header-items">
                  <div className="reports-table-header-items">
                    <RangeDatePicker
                      startDate={startDate}
                      endDate={endDate}
                      className="bg-light-grey"
                      onChange={this.handleChangeDate}
                    />
                  </div>
                  <div className="reports-table-header-items">
                    <div className="reports-table-header-search">
                      <div className="reports-table-header-search-wrapper">
                        <svg
                          className={`reports-table-header-search__icon`}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.7">
                            <path
                              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                              stroke="#212529"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.9999 20.9999L16.6499 16.6499"
                              stroke="#212529"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                        <input
                          className={`reports-table-header-search__input`}
                          value={pagination.name}
                          onChange={this.handleChangeSearch}
                          type="text"
                        />
                        {pagination.name && (
                          <svg
                            className={`reports-table-header-search__icon clear`}
                            onClick={this.clearSearch}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 5L5 15"
                              stroke="#646669"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5 5L15 15"
                              stroke="#646669"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table mb-0">
                <thead className="settings-table-thead reports-table-thead">
                  <tr>
                    <th scope="col">{t('areas')}</th>
                    <th scope="col">{t('total_hours')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.showSmallLoader ? (
                    <tr>
                      <td colSpan="8">
                        <SmallLoader />
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      {areasStatistics?.length !== 0 ? (
                        <Fragment>
                          {areasStatistics?.map((item, index) => {
                            if (item.area) {
                              return (
                                <tr
                                  className={`settings-table__tr`}
                                  key={index}
                                  // onClick={() => this.setSelectedRow(item)}
                                >
                                  <td className="reports-table__text">{item.area.name}</td>
                                  <td className="reports-table__text">{convertTimeToHours(item.working_time_hours, item.working_time_minutes)} h</td>
                                  <td className="text-right">
                                    <button className="reports-table__dotsBtn">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g opacity="0.7">
                                          <path
                                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                            stroke="#212529"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                            stroke="#212529"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                            stroke="#212529"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="4">
                            <p className="settings-table-emptyText">You don't have any areas</p>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
              <div className="settings-table-pagination reports-table-pagination">
                  <Pagination
                    count={meta?.last_page}
                    handlePageChange={this.handlePageChange}
                    activePage={pagination.page}
                  />
                  <div className="settings-table-pagination-items">
                    <p className="settings-table-pagination__text">{t('view')}</p>
                    <select
                      className="settings-table-pagination__select"
                      onChange={this.handleSelect}
                      value={pagination.limit}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>{' '}
                    <p className="settings-table-pagination__text">{t('items_per_page')}</p>
                  </div>
                  <p className="settings-table-pagination__text">
                    {!!meta && meta.from} - {!!meta && meta.to} {t('out_of')} {!!meta && meta.total} {t('items')}
                  </p>
                </div>
            </div>
            {/* <CSSTransition in={selectedRowExists} timeout={300} classNames="detailed-report" unmountOnExit>
              <SidebarReport selectedItem={selectedRow} />
            </CSSTransition> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    areasStatistics: state.reportsReducer.areasStatistics,
    meta: state.reportsReducer.meta,
    showSmallLoader: state.viewReducer.showSmallLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActionCreators: bindActionCreators(reportsActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportsAreasTable));
