import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import moment from 'moment';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';

import * as reportsActionCreators from '../../../actions/reportsActionCreators';
import * as scheduleActionCreators from '../../../actions/scheduleActionCreators';
import RangeDatePicker from '../../helpComponent/RangeDatePicker';
import Pagination from '../../helpComponent/Pagination';
import ChartBar from '../../helpComponent/ChartBar';
import FullPageLoader from '../../helpComponent/FullPageLoader';
import { convertSecondsToHours, getCustomPagination } from '../../../Utils/helpers';

const FORMAT_DATE = 'YYYY-MM-DD';

export class ReportEmployeeDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      currentPage: 1,
      pagination: {
        start: moment().add(-30, 'days').format(FORMAT_DATE),
        end: moment().format(FORMAT_DATE),
      },
    };
  }

  componentDidMount() {
    // this.props.scheduleActionCreators.getAllAreas();

    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });
    params = `?${qs.stringify(pagination)}`;

    const startDate = moment(pagination.start).toDate();
    const endDate = moment(pagination.end).toDate();

    this.props.reportsActionCreators.getEmployeeDetailedStatistics({
      id: this.props.match.params.id,
      params,
      loader: true,
    });
    this.setState({ pagination, startDate, endDate });
  }

  // handleChangeSelect = (selectedId) => {};

  handleChangeDate = ({ startDate, endDate }) => {
    const { pagination } = this.state;

    pagination.start = startDate ? moment(startDate).format(FORMAT_DATE) : null;
    pagination.end = endDate ? moment(endDate).format(FORMAT_DATE) : null;
    this.setState({ pagination });
  };

  handleChangePage = (page) => this.setState({ currentPage: page });

  handleSubmit = () => {
    const { pagination } = this.state;
    const historyParams = {
      start: pagination.start,
      end: pagination.end,
    };

    this.props.reportsActionCreators.getEmployeeDetailedStatistics({
      id: this.props.match.params.id,
      params: `?${qs.stringify(pagination)}`,
      loader: true,
    });
    this.props.history.push({ search: `?${qs.stringify(historyParams)}` });
    this.setState({ pagination, currentPage: 1 });
  };

  render() {
    const { allAreas, showLoader, employeeDetailedStatistics, t } = this.props;
    const { pagination, startDate, endDate, currentPage } = this.state;
    const { currentItems, lastPage } = getCustomPagination(employeeDetailedStatistics.data, 15, currentPage);
    const total_sec = employeeDetailedStatistics.data?.reduce((accum, item) => accum + item.total_seconds, 0);
    // const areaList = allAreas?.map((item) => {
    //   return { label: item.name, value: item.id };
    // });

    return (
      <Fragment>
        <div className="d-flex">
          <div className="content">
            <div className="addManager-wrapper">
              <Link to="/reports/employees" className="addManager__link">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('back_to_reports')}
              </Link>
              <div className="reports-table-header mb-4">
                <h6 className="reports-table-header__text-big">{t('statistics')}</h6>
                <div className="d-flex">
                  <RangeDatePicker startDate={startDate} endDate={endDate} onChange={this.handleChangeDate} />
                  {/* <Select
                    className={`addManager-left__form-input-wrapper-items__select reports-select dark ml-3`}
                    options={areaList}
                    placeholder="Filter by areas"
                    name="areas"
                    noOptionsMessage={() => 'No areas'}
                    onChange={this.handleChangeSelect}
                  /> */}
                  <button
                    onClick={this.handleSubmit}
                    type="button"
                    className="primary-btn ml-3"
                    disabled={!pagination.end || !pagination.start}
                  >
                    {t('show_report')}
                  </button>
                </div>
              </div>
              <div className={`settings-table reports-table-detailed`}>
                <div className="reports-table-detailed__header">
                  <h6>
                    {employeeDetailedStatistics.first_name} {employeeDetailedStatistics.last_name}
                  </h6>
                  <button className="export-btn">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                        stroke="#0B9444"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.25 7.5L9 11.25L12.75 7.5"
                        stroke="#0B9444"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 11.25V2.25"
                        stroke="#0B9444"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {t('export')}
                    <svg
                      className="arrow-btn"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.7">
                        <path
                          d="M4.5 6.75L9 11.25L13.5 6.75"
                          stroke="#0B9444"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
                <div className="d-flex">
                  <div className="reports-table-detailed__table--wrapper">
                    <div className="reports-table-detailed__table">
                      <table>
                        <thead>
                          <tr>
                            <td>{t('days')}</td>
                            {/* <td>Area</td> */}
                            <td className="text-center">{t('hours')}</td>
                            <td className="text-center">{t('day')}</td>
                            <td className="text-center">Night</td>
                            <td className="text-center">{t('weekend')}</td>
                            <td className="text-center">{t('total')}</td>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((item, index) => {
                            return (
                              <Fragment key={index}>
                                <tr>
                                  <td>
                                    <span>{moment(item.date).format('YYYY-MM-DD')}</span>
                                  </td>
                                  <td className="text-center">
                                    <span>{convertSecondsToHours(item.total_seconds)}</span>
                                  </td>
                                  <td className="text-center">
                                    <span>{convertSecondsToHours(item.daily_seconds)}</span>
                                  </td>
                                  <td className="text-center">
                                    <span>{convertSecondsToHours(item.nightly_seconds)}</span>
                                  </td>
                                  <td className="text-center">
                                    <span>{convertSecondsToHours(item.weekend_seconds)}</span>
                                  </td>
                                  {index === 0 && (
                                    <td className="td-fullheight" rowSpan={employeeDetailedStatistics.data.length}>
                                      <span>{convertSecondsToHours(total_sec)}</span>
                                    </td>
                                  )}
                                </tr>
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-3">
                      <Pagination count={lastPage} handlePageChange={this.handleChangePage} activePage={currentPage} />
                    </div>
                  </div>
                  <div className="reports-table-detailed__graph">
                    <div className="dashboard-table__border">
                      <div className="dashboard-table-header-workingHoursChart">
                        <p className="dashboard-table-header__text">{convertSecondsToHours(total_sec)} {t('h')}</p>
                        <div></div>
                      </div>
                      <div className="dashboard-table-container">
                        <ChartBar data={employeeDetailedStatistics.data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showLoader && <FullPageLoader />}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoader: state.viewReducer.showLoader,
    employeeDetailedStatistics: state.reportsReducer.employeeDetailedStatistics,
    allAreas: state.scheduleReducer.allAreas,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActionCreators: bindActionCreators(reportsActionCreators, dispatch),
    scheduleActionCreators: bindActionCreators(scheduleActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportEmployeeDetails));
