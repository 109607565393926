export const initialState = {
  leaves: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_LEAVES':
      return {
        ...state,
        leaves: action.leaves,
      };

    default:
      return state;
  }
}
