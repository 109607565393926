import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';
import Avatar from 'react-avatar';
import Switch from 'react-switch';
import moment from 'moment';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';

import * as managerActionCreators from '../../actions/managerActionCreators';
import * as employeeActionCreators from '../../actions/employeeActionCreators';
import SmallLoader from '../helpComponent/SmallLoader';
import Pagination from '../helpComponent/Pagination';
import Popup from '../helpComponent/Popup';

import { connect } from 'react-redux';

const language = [
  { value: 'en', label: 'English' },
  { value: 'sv', label: 'Swedish' },
  { value: 'es', label: 'Spanish' },
];

class Table extends Component {
  constructor() {
    super();
    this.state = {
      isShowConfirmDelete: false,
      isShowConfirmDetach: false,
      employeeId: null,
      pagination: {
        page: 1,
        limit: 10,
        name: '',
      },
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    let params = `?${qs.stringify(pagination)}`;

    this.props.managerActionCreators.getManagerEmployees({
      params,
      id: this.props.match.params.id,
    });
  }

  handleChangeSearch = (e) => {
    const { pagination } = this.state;
    const { history, match, managerActionCreators } = this.props;

    pagination.name = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    managerActionCreators.getManagerEmployees({ params, id: match.params.id });
    // history.push({ search: params });
  };

  handlePageChange = (page) => {
    const { pagination } = this.state;
    const { history, managerActionCreators, match } = this.props;

    pagination.page = page;
    let params = `?${qs.stringify(pagination)}`;

    managerActionCreators.getManagerEmployees({
      params,
      id: match.params.id,
    });
    // history.push({ search: params });
  };

  handleSelectLimit = (e) => {
    const { pagination } = this.state;
    const { history, managerActionCreators, match } = this.props;

    pagination.limit = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    managerActionCreators.getManagerEmployees({
      params,
      id: match.params.id,
    });
  };

  toggleActivation = (id, isActive) => {
    let active = isActive === 1 ? 0 : 1;

    this.props.employeeActionCreators.toggleActivationManager({
      id,
      active,
      handleActivateSuccess: this.handleSuccessResponse,
    });
  };

  handleConfirmDeletePopup = (id) => {
    this.setState({
      isShowConfirmDelete: !this.state.isShowConfirmDelete,
      employeeId: id,
    });
  };

  handleConfirmDetachPopup = (id) => {
    this.setState({
      isShowConfirmDetach: !this.state.isShowConfirmDetach,
      employeeId: id,
    });
  };

  handleSuccessResponse = (response) => {
    const { status } = response;
    const { pagination, employeeId } = this.state;
    const { match, history, managerActionCreators } = this.props;

    if (status === 200) {
      this.setState({
        isShowConfirmDelete: false,
        isShowConfirmDetach: false,
        employeeId: null,
      });
      let params = `?${qs.stringify(pagination)}`;
      managerActionCreators.getManagerEmployees({
        params,
        id: match.params.id,
      });
    }
  };

  handleDelete = () => {
    const { employeeId } = this.state;

    this.props.employeeActionCreators.deleteEmployee({
      id: employeeId,
      handleDeleteSuccess: this.handleSuccessResponse,
    });
  };

  handleDetach = () => {
    const { employeeId } = this.state;

    this.props.managerActionCreators.detachManagerEmployee({
      id: employeeId,
      handleDeleteSuccess: this.handleSuccessResponse,
    });
  };

  render() {
    const { employees, meta, t } = this.props;
    const { pagination, isShowConfirmDelete, isShowConfirmDetach } = this.state;

    const d = new Date();
    const currentYear = d.getFullYear();

    return (
      <div className="settings-table">
        <div className="settings-table-header">
          <div className="settings-table-header-left">
            <h6 className="settings-table-header__text">{t('employees')}</h6>
            <div className="settings-table-header__input">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.9999 20.9999L16.6499 16.6499"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>

              <input type="text" value={pagination.name} onChange={this.handleChangeSearch} />
            </div>
          </div>
        </div>

        {employees && employees?.length !== 0 ? (
          <table className="table">
            <thead className="settings-table-thead">
              <tr>
                <th scope="col">{t('name')}</th>
                <th scope="col">{t('status')}</th>
                <th scope="col">{t('language')}</th>
                <th scope="col">{t('birthday')}</th>
                <th scope="col">{t('manager')}</th>
                <th scope="col">{t('last_activity')}</th>
                <th scope="col">{t('action')}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {this.props.showSmallLoader ? (
                <tr>
                  <td colSpan="8">
                    <SmallLoader />
                  </td>
                </tr>
              ) : (
                <Fragment>
                  {!!employees &&
                    employees.map((item, index) => {
                      return (
                        <tr className="settings-table__tr" key={index}>
                          <td className="settings-table-items" scope="row">
                            <Avatar name={`${item.first_name} ${item.last_name}`} size="44" round="50%" />
                            {/* <img className="settings-table__img" src={item.img} /> */}
                            <div className="settings-table-items-info">
                              <Link
                                to={`/settings/employees/employee/${item.id}`}
                                className="settings-table-items-info__name"
                              >
                                {item.first_name} {item.last_name}
                              </Link>
                              <p className="settings-table-items-info__id"># {item.id}</p>
                              <p></p>
                            </div>
                          </td>
                          <td className="settings-table__text regular">
                            {/* {item.status} */}
                            <div className={`settings-table-status ${item.is_active === 0 ? 'inactive' : 'active'}`}>
                              {item.is_active === 0 ? t('inactive') : t('active')}
                            </div>
                          </td>
                          <td className="settings-table__text regular">
                            {language.map((option) => {
                              if (option.value == item.language) {
                                return option.label;
                              }
                            })}
                          </td>

                          <td className="settings-table__text">
                            {!!item.birthday ? moment(item.birthday).format('YYYY-MM-DD') : t('empty')}{' '}
                            {!!item.birthday && `(${currentYear - moment(item.birthday).format('yyyy')})`}
                          </td>
                          <td className="settings-table__text">
                            {item?.manager?.first_name} {item?.manager?.last_name}
                          </td>
                          <td className="settings-table__text">{item.lastActivity}</td>
                          <td className="settings-table-action">
                            <Switch
                              className={`switcher ${item.is_active === 1 ? 'active' : 'inactive'}`}
                              onChange={() => this.toggleActivation(item.id, item.is_active)}
                              checked={!!item.is_active}
                            />
                          </td>
                          <td className="settings-table-btn">
                            <div className="settings-table-btn-wrapper">
                              <Link
                                className="settings-table-btn-wrapper__btn edit"
                                id={item.id}
                                to={`/settings/employees/edit/${item.id}`}
                              >
                                <svg
                                  id={item.id}
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.7"
                                    d="M14.1665 2.49993C14.3854 2.28106 14.6452 2.10744 14.9312 1.98899C15.2171 1.87054 15.5236 1.80957 15.8332 1.80957C16.1427 1.80957 16.4492 1.87054 16.7352 1.98899C17.0211 2.10744 17.281 2.28106 17.4998 2.49993C17.7187 2.7188 17.8923 2.97863 18.0108 3.2646C18.1292 3.55057 18.1902 3.85706 18.1902 4.16659C18.1902 4.47612 18.1292 4.78262 18.0108 5.06859C17.8923 5.35455 17.7187 5.61439 17.4998 5.83326L6.24984 17.0833L1.6665 18.3333L2.9165 13.7499L14.1665 2.49993Z"
                                    stroke="#212529"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Link>
                              <button
                                className="settings-table-btn-wrapper__btn edit"
                                onClick={() => this.handleConfirmDetachPopup(item.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-paperclip"
                                >
                                  <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                </svg>
                              </button>
                              <button
                                className="settings-table-btn-wrapper__btn delete"
                                id={item.id}
                                onClick={() => this.handleConfirmDeletePopup(item.id)}
                              >
                                <svg
                                  id={item.id}
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.5 5H4.16667H17.5"
                                    stroke="#EB5757"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033H15.8337Z"
                                    stroke="#EB5757"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </Fragment>
              )}
            </tbody>
          </table>
        ) : (
          <p className="settings-table-emptyText">{t('dont_have_employees')}</p>
        )}
        <div className="settings-table-pagination">
          <Pagination count={meta?.last_page} handlePageChange={this.handlePageChange} activePage={pagination.page} />
          <div className="settings-table-pagination-items">
            <p className="settings-table-pagination__text">{t('view')}</p>
            <select
              className="settings-table-pagination__select"
              onChange={this.handleSelectLimit}
              value={pagination.limit}
            >
              <option value="10">10</option>
              <option value="15">15</option>
            </select>{' '}
            <p className="settings-table-pagination__text">{t('items_per_page')}</p>
          </div>
          <p className="settings-table-pagination__text">
            {!!meta && meta.from} - {!!meta && meta.to} {t('out_of')} {!!meta && meta.total} {t('items')}
          </p>
        </div>
        <CSSTransition in={isShowConfirmDelete} timeout={300} classNames="show" unmountOnExit>
          <Popup
            type="delete"
            text={t('sure_delete_employee')}
            onHandleClose={this.handleConfirmDeletePopup}
            onHandleDelete={this.handleDelete}
          />
        </CSSTransition>
        <CSSTransition in={isShowConfirmDetach} timeout={300} classNames="show" unmountOnExit>
          <Popup
            type="confirm"
            text={t('sure_detach_employee')}
            onHandleClose={this.handleConfirmDetachPopup}
            onHandleDelete={this.handleDetach}
          />
        </CSSTransition>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showSmallLoader: state.viewReducer.showSmallLoader,
    employees: state.managerReducer.managerEmployees.data,
    meta: state.managerReducer.managerEmployees.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    managerActionCreators: bindActionCreators(managerActionCreators, dispatch),
    employeeActionCreators: bindActionCreators(employeeActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Table));
