import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import moment from 'moment';
import qs from 'query-string';
import MonthPicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import * as reportsActionCreators from '../../actions/reportsActionCreators';
import ChartBar from '../helpComponent/ChartBar';
import { Input } from '../helpComponent/RangeDatePicker';
import { convertSecondsToHours } from '../../Utils/helpers';

class WorkingHoursChart extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
    };
  }

  componentDidMount() {
    this.setChart();
  }

  setChart = (date = undefined) => {
    const start = moment(date).startOf('month').format('YYYY-MM-DD');
    const end = moment(date).endOf('month').format('YYYY-MM-DD');
    let params = `?${qs.stringify({ start, end })}`;

    this.props.reportsActionCreators.getDailyReport({
      params,
    });
  };

  handleChangeMonth = (startDate) => {
    this.setChart(startDate);
    this.setState({ startDate });
  };

  render() {
    const { startDate } = this.state;
    const { dailyReport } = this.props;
    const { t } = this.props;
    const total_sec = dailyReport.reduce((accum, item) => accum + item.total_seconds, 0);

    return (
      <div className="dashboard-table workingHoursChart">
        <div className="dashboard-table-header workingHoursChart">
          <h6 className="dashboard-table__title mb-0">{t('reports')}</h6>
          <button onClick={() => this.props.history.push('/reports/employees')} className="dashboard-table__btn">
            {t('see_all')}
          </button>
        </div>
        <div className="dashboard-table__border">
          <div className="dashboard-table-header-workingHoursChart">
            <p className="dashboard-table-header__text">{convertSecondsToHours(total_sec)} {t('h')}</p>
            <div>
              <MonthPicker
                selected={startDate}
                onChange={(date) => this.handleChangeMonth(date)}
                dateFormat="MMM, yyyy"
                className={`date-picker date-picker-range`}
                calendarClassName={`date-calendar date-calendar-months`}
                showPopperArrow={false}
                customInput={<Input />}
                showMonthYearPicker
                // showFullMonthYearPicker
              />
            </div>
          </div>
          {/* <p className="dashboard-table-header-workingHoursChart__statisticText">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.99951 15.8337V4.16699"
                stroke="#0B9444"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.16602 10.0003L9.99935 4.16699L15.8327 10.0003"
                stroke="#0B9444"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>45 % up </span>&nbsp; from last month
          </p> */}
          <div className="dashboard-table-container">
            <ChartBar data={dailyReport} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dailyReport: state.reportsReducer.dailyReport,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActionCreators: bindActionCreators(reportsActionCreators, dispatch),
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkingHoursChart)));
