import React, { useState } from 'react';

import { ChromePicker } from 'react-color';

export default function ColorPicker({ onChange, value }) {
  const [show, setShow] = useState(false);
  const backgroundStyles = {
    wrapper: {
      backgroundColor: '#f6f7f9',
      border: '2px solid #edeff3',
      borderRadius: 8,
      padding: 8,
      width: 100,
      cursor: 'pointer',
    },
    color: { backgroundColor: value || '#ffffff', width: '100%', height: 19 },
  };

  const pickerStyles = {
    palette: {
      position: 'absolute',
      zIndex: 100,
    },
    cover: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  return (
    <div>
      <div className="color-picker__background-wrapper" onClick={() => setShow(true)} style={backgroundStyles.wrapper}>
        <div className="color-picker__background-color" style={backgroundStyles.color} />
      </div>
      {show && (
        <div className="color-picker__palette" style={pickerStyles.palette}>
          <div className="color-picker__cover" style={pickerStyles.cover} onClick={() => setShow(false)} />
          <ChromePicker disableAlpha={true} color={value || '#ffffff'} onChange={onChange} />
        </div>
      )}
    </div>
  );
}
