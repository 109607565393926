export const initialState = { news: [], newsData: {} };

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_NEWS':
      return {
        ...state,
        news: action.news,
        meta: action.meta ? action.meta : state.meta,
      };

    case 'GET_NEWS_DATA':
      return {
        ...state,
        newsData: action.newsData,
      };

    default:
      return state;
  }
}
