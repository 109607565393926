export const initialState = { managerEmployees: {} };

export default function (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_MANAGER':
      return {
        ...state,
      };

    case 'UPDATE_MANAGER':
      return {
        ...state,
      };

    case 'GET_MANAGERS':
      return {
        ...state,
        managers: action.managers,
        meta: action.meta,
      };

    case 'GET_MANAGER_DATA':
      return {
        ...state,
        managerData: action.managerData,
      };

    case 'GET_MANAGER_EMPLOYEES':
      return {
        ...state,
        managerEmployees: action.managerEmployees,
      };

    case 'TOGGLE_ACTIVATION_MANAGER':
      return {
        ...state,
      };

    default:
      return state;
  }
}
