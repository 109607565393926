import { render } from '@testing-library/react';
import React, { Component } from 'react';
import Select from 'react-select';
import Avatar from 'react-avatar';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ShiftInformation from './AddShiftItems/ShiftInformation';
import Description from './AddShiftItems/Description';
import SalaryTypeRules from './AddShiftItems/SalaryTypeRules';
import AdvancedSettings from './AddShiftItems/AdvancedSettings';
import Breaks from './AddShiftItems/Breaks';
import Tasks from './AddShiftItems/Tasks';
import Repeat from './AddShiftItems/Repeat';
import RepeatModal from './AddShiftItems/RepeatModal';
import qs from 'query-string';
import { CSSTransition } from 'react-transition-group';
import MaskedInput from 'react-text-mask';
import { withTranslation } from 'react-i18next';
import SmallLoader from '../helpComponent/SmallLoader';
import { Check, Clock } from '../Svg/Svg';

import { bindActionCreators } from 'redux';
import * as scheduleActionCreators from '../../actions/scheduleActionCreators';
import * as organizationActionCreators from '../../actions/organizationActionCreators';
import { connect } from 'react-redux';

const Option = ({ value, label }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar name={label} size="22" round="50%" className="mr-3" />
      <div>{label}</div>
    </div>
  );
};

const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div className="d-flex cursor-pointer" {...restInnerProps} ref={ref}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5L5 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 5L15 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

class EditShift extends Component {
  constructor() {
    super();
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      start_work_at: moment().set('hour', 7).set('minute', 0).toDate(),
      end_work_at: moment().set('hour', 16).set('minute', 0).toDate(),
      start_work_raw: '07:00',
      end_work_raw: '16:00',

      description: '',
      breaks: [],
      tasks: [],
      user_id: '',
      area_id: '',
      shift_id: '',
      errors: {},
      timeError: '',
      checkInsAndOutsList: [],

      repeat: 0,
      repeat_other: 0,
      repeat_every: 1,
      repeat_on: [],

      isCheckInsAndOutsLoading: false,
    };
  }

  componentDidMount() {
    const { shift, scheduleActionCreators, organizationActionCreators } = this.props;
    scheduleActionCreators.getUsers({
      filter: 'EMPLOYEE,MANAGER',
      active: 1,
    });
    organizationActionCreators.getOrganization({ params: '?search=' });
    this.setState({isCheckInsAndOutsLoading: true})
    scheduleActionCreators.getShiftCheckInsAndOuts(shift.id, this.onSuccessGetCheckInsAndOuts)

    this.setState({
      start_work_at: moment(shift.start_work_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      end_work_at: moment(shift.end_work_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      start_work_raw: moment(shift.start_work_at).format('HH:mm'),
      end_work_raw: moment(shift.end_work_at).format('HH:mm'),
      date_start: moment(shift.start_work_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      date_end: moment(shift.end_work_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      description: shift.description || '',
      user_id: shift.resourceId,
      area_id: shift.area.id,
      shift_id: shift.id,
      breaks: shift.breaks.map((breakItem) => {
        breakItem.start = moment(breakItem.start_break_at, 'YYYY-MM-DD HH:mm:ss').toDate();
        breakItem.end = moment(breakItem.end_break_at, 'YYYY-MM-DD HH:mm:ss').toDate();
        return breakItem;
      }),
      tasks: shift.tasks.map((task) => {
        task.start = moment(task.start_task_at, 'HH:mm:ss').toDate();
        task.end = moment(task.end_task_at, 'HH:mm:ss').toDate();
        return task;
      }),
      // repeat: shift.shift_repeat ? shift.shift_repeat.repeat : 0,
      // repeat_every: shift.shift_repeat ? shift.shift_repeat.repeat_every : 1,
      // repeat_on: shift.shift_repeat ? shift.shift_repeat.repeat_on : [],
      // repeat_other: this.defineOtherShiftRepeat(shift.shift_repeat),
    });
  }

  defineOtherShiftRepeat = (shift) => {
    if (!shift) return 0;
    if (shift.repeat === 'daily' && shift.repeat_every > 1) return 'other';
    if (shift.repeat === 'weekly' && (shift.repeat_every > 1 || shift.repeat_on.length < 7)) return 'other';
    if (shift.repeat === 'monthly' && (shift.repeat_every > 1 || shift.repeat_on.length < 31)) return 'other';
    if (shift.repeat === 'yearly' && (shift.repeat_every > 1 || shift.repeat_on.length < 12)) return 'other';
    return shift.repeat;
  };

  addBreaks = () => {
    const { breaks, date_start, date_end } = this.state;
    breaks.push({ start: new Date(date_start).setHours(12, 0), end: new Date(date_end).setHours(13, 0) });
    this.setState({
      breaks,
    });
  };

  deleteBreaks = (e) => {
    const { breaks } = this.state;
    const index = e.target.id;
    if (index > -1) {
      breaks.splice(index, 1);
    }
    this.setState({
      breaks,
    });
  };

  handleChangeBreaksStart = (date, index) => {
    const { breaks } = this.state;
    breaks[index].start = date;
    this.setState({
      breaks,
    });
  };

  handleChangeBreaksEnd = (date, index) => {
    const { breaks } = this.state;
    breaks[index].end = date;
    this.setState({
      breaks,
    });
  };

  addTasks = () => {
    const { tasks } = this.state;
    tasks.push({ start: new Date().setHours(0, 0), end: new Date().setHours(23, 59), name: '' });
    this.setState({
      tasks,
    });
  };

  deleteTasks = (e) => {
    const { tasks } = this.state;
    const index = e.target.id;
    if (index > -1) {
      tasks.splice(index, 1);
    }
    this.setState({
      tasks,
    });
  };

  handleChangeTaskName = (value, index) => {
    const { tasks } = this.state;
    tasks[index].name = value;
    this.setState({
      tasks,
    });
  };

  handleChangeTaskStart = (date, index) => {
    const { tasks } = this.state;
    tasks[index].start = date;
    this.setState({
      tasks,
    });
  };

  handleChangeTaskEnd = (date, index) => {
    const { tasks } = this.state;
    tasks[index].end = date;
    this.setState({
      tasks,
    });
  };

  handleChangeDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  updateShift = (e) => {
    let isSave = e.target.value;
    const {
      user_id,
      date_start,
      date_end,
      start_work_at,
      end_work_at,
      description,
      breaks,
      tasks,
      area_id,
      shift_id,
      repeat,
      repeat_every,
      repeat_on,
      start_work_raw,
      end_work_raw,
    } = this.state;

    this.setState({ timeError: '', errors: {} });
    if (start_work_raw.replace('_', '').length < 5 || end_work_raw.replace('_', '').length < 5) {
      this.setState({ timeError: 'The time is invalid' });
      return;
    }

    let newBreaks = breaks.map((item) => {
      return {
        start: moment(item.start).format('YYYY-MM-DDTHH:mm:00'),
        end: moment(item.end).format('YYYY-MM-DDTHH:mm:00'),
      };
    });

    let newTasks = tasks.map((item) => {
      return {
        start: moment(item.start).format('HH:mm:ss'),
        end: moment(item.end).format('HH:mm:ss'),
        name: item.name,
      };
    });

    let data = {
      user_id,
      area_id,
      start_work_at: `${moment(date_start).format(`YYYY-MM-DD`)}T${moment(start_work_at).format(`HH:mm:00`)}`,
      end_work_at: `${moment(date_end).format(`YYYY-MM-DD`)}T${moment(end_work_at).format(`HH:mm:00`)}`,
      description,
      breaks: newBreaks,
      tasks: newTasks,
      // repeat,
      // repeat_every,
      // repeat_on,
    };

    this.props.scheduleActionCreators.userShiftUpdate({
      id: shift_id,
      data,
      handleShiftSuccess: this.handleShiftSuccess,
      handleShiftError: this.handleShiftError,
    });
  };

  handleShiftSuccess = (success) => {
    const { status } = success;
    const { schedulerData, areasIds, memberFilter, closeEditShift, scheduleActionCreators } = this.props;
    let params = {
      start: `${schedulerData.startDate}T00:00:00`,
      end: `${schedulerData.endDate}T23:59:59`,
      'filter[employees]': memberFilter,
    };
    if (areasIds.length) {
      params = { ...params, 'filter[area]': areasIds.join(',') };
    }
    let statisticsParams = {
      start: `${schedulerData.startDate}`,
      end: `${schedulerData.endDate}`,
    };
    params = `?${qs.stringify(params)}`;
    statisticsParams = `?${qs.stringify(statisticsParams)}`;

    if (status === 200) {
      this.setState({
        errors: {},
      });
      closeEditShift();
      scheduleActionCreators.getAllShift(params);
      scheduleActionCreators.getShiftsStatistics(statisticsParams);
    }
  };

  handleShiftError = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  handleChangeEmployeeId = (selectedId) => {
    let id = !!selectedId ? selectedId.value : '';
    this.setState({ user_id: id });
  };

  handleAreaId = (selectedId) => {
    this.setState({ area_id: selectedId.value });
  };

  handleDateStartChange = (date) => {
    this.setState({ date_start: date });
    if (moment(this.state.date_end).isBefore(date, 'day')) {
      this.setState({ date_end: date });
    }
  };

  handleChangeRepeat = (repeatValue, config) => {
    if (repeatValue === 'other') {
      this.setState({
        repeat_other: repeatValue,
        isShowRepeatModal: !this.state.isShowRepeatModal,
      });
    } else {
      this.setState({
        repeat: repeatValue,
        repeat_other: repeatValue,
        ...config,
      });
    }
  };

  closeRepeats = () => {
    const { isShowRepeatModal, repeat } = this.state;

    this.setState({
      isShowRepeatModal: !isShowRepeatModal,
    });
    if (repeat === 0)
      this.setState({
        repeat_other: 0,
      });
  };

  saveRepeats = ({ repeat, repeat_every, repeat_on }) => {
    this.setState({
      repeat,
      repeat_every,
      repeat_on,
      isShowRepeatModal: !this.state.isShowRepeatModal,
    });
  };

  handleCheckShift = () => {
    const { checkInsAndOutsList, shift_id  } = this.state;
    const { scheduleActionCreators } = this.props;

    if (checkInsAndOutsList[checkInsAndOutsList.length - 1]?.status === 'CHECK_IN') {
      scheduleActionCreators.shiftCheckOut(shift_id, this.onSuccessCheckInsAndOutsList)
    } else {
      scheduleActionCreators.shiftCheckIn(shift_id, this.onSuccessCheckInsAndOutsList)
    }
  }

  onSuccessCheckInsAndOutsList = (response) => {
    this.setState({checkInsAndOutsList: [...this.state.checkInsAndOutsList, response]})
  }

  onSuccessGetCheckInsAndOuts = (response) => {
    this.setState({checkInsAndOutsList: response, isCheckInsAndOutsLoading: false})
  }

  createNewCheckInsAndOutsArray = () => {
    const newArray = [...this.state.checkInsAndOutsList];
    if (newArray[newArray.length - 1]?.status === 'CHECK_IN') {
      newArray.push({id: Math.random() * 10000, shift_id: this.state.shift_id, status: 'CHECK_OUT', created_at: null})
    }
    return newArray;
  }

  render() {
    const {
      date_start,
      date_end,
      start_work_at,
      end_work_at,
      start_work_raw,
      end_work_raw,
      breaks,
      tasks,
      description,
      user_id,
      area_id,
      errors,
      repeat,
      repeat_other,
      repeat_every,
      repeat_on,
      timeError,
      checkInsAndOutsList,
      isCheckInsAndOutsLoading
    } = this.state;
    const { organization, users, closeEditShift, shift, t, toggleAddShift } = this.props;
    const usersList = users?.map((item) => {
      return { label: `${item.first_name} ${item.last_name}`, value: item.id };
    });

    const areaList = organization?.map((o) => {
      return {
        label: o.name,
        options: o.areas.map((el) => {
          return { label: el.name, value: el.id };
        }),
      };
    });

    const checkTime = (val) => val[0] === '2';

    return (
      <div className="addShift">
        <div className="addShift-header">
          <p className="addShift-header__title">Edit shift</p>
          <div>
            {this.props.myAccData.role !== 'EMPLOYEE' && (
              <button type="button" className="addShift-header__btn" onClick={() => toggleAddShift(this.state)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-copy"
                >
                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                </svg>
              </button>
            )}
            <button type="button" className="addShift-header__btn" onClick={this.props.closeEditShift}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L5 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 5L15 15" stroke="#646669" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
        <div className="addShift-header__status-wrapper">{checkInsAndOutsList.length !== 0 ? <p className="addShift-header__status"><Check />{checkInsAndOutsList[checkInsAndOutsList.length - 1].status === 'CHECK_IN' ? 'Check in' : 'Check out'}</p> : null}</div>
        <div className="addShift-content">
          <div className="addManager-left__form-input-wrapper-items organization-popup__select">
            <label className="addManager-left__form-input-wrapper-items__label">{t('employee')}</label>
            <Select
              className={`addManager-left__form-input-wrapper-items__select ${errors.user_id && 'error-select'}`}
              formatOptionLabel={Option}
              components={{ ClearIndicator }}
              noOptionsMessage={() => 'No users'}
              options={usersList}
              isClearable={true}
              placeholder={t('select_employee')}
              value={usersList?.find((users) => users.value === user_id)}
              onChange={this.handleChangeEmployeeId}
              styles={{
                option: (base, state) => ({
                  ...base,
                  height: '100%',
                  backgroundColor: state.isSelected ? '#F6F7F9' : null,
                  color: '#212529',
                  '&:hover': {
                    backgroundColor: '#F6F7F9',
                  },
                }),
              }}
              name="manager"
            />
            {errors.user_id && <p className="error-input-text mb-0 mt-1">{errors.user_id}</p>}
          </div>
          <div className="addManager-left__form-input-wrapper-items organization-popup__select">
            <label className="addManager-left__form-input-wrapper-items__label">{t('area')}</label>
            <Select
              className={`addManager-left__form-input-wrapper-items__select ${errors.area_id && 'error-select'}`}
              options={areaList}
              placeholder={t('select_area')}
              name="area"
              value={areaList?.map((area) => area.options.find((v) => v.value === area_id))}
              onChange={this.handleAreaId}
            />
            {errors.area_id && <p className="error-input-text mb-0 mt-1">{errors.area_id}</p>}
          </div>

          <div className="addManager-left__form-input-wrapper-items mb-3">
            <label className="addManager-left__form-input-wrapper-items__label">{t('date_time_start')}</label>
            <div className="addManager-left__form-input-wrapper-items__date">
              <div>
                <DatePicker
                  popperPlacement="bottom-start"
                  popperModifiers={{
                    flip: {
                      enabled: false,
                    },
                  }}
                  value={date_start}
                  defaultValue={date_start}
                  showPopperArrow={false}
                  selected={date_start}
                  onChange={this.handleDateStartChange}
                  dateFormat="yyyy-MM-dd"
                  className={`date-picker schedule-bg ${errors.start_work_at && 'error-input'}`}
                  calendarClassName="date-calendar"
                />
              </div>
              <span>-</span>
              <div className="timepicker">
                <DatePicker
                  className={`datepicker-time ${(errors.start_work_at || timeError) && 'error-input'}`}
                  selected={start_work_at}
                  onChange={(date) => {
                    this.setState({ start_work_at: date });
                  }}
                  onChangeRaw={(e) => this.setState({ start_work_raw: e.target.value })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  placeholderText={start_work_at}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  customInput={
                    <MaskedInput
                      type="text"
                      mask={[/[0-2]/, checkTime(start_work_raw) ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                    />
                  }
                  showPopperArrow={false}
                />
              </div>
            </div>
            {errors.start_work_at && <p className="error-input-text mb-0 mt-1">{errors.start_work_at}</p>}
          </div>
          <div className="addManager-left__form-input-wrapper-items mb-0">
            <label className="addManager-left__form-input-wrapper-items__label">{t('date_time_end')}</label>
            <div className="addManager-left__form-input-wrapper-items__date">
              <div>
                <DatePicker
                  popperPlacement="bottom-start"
                  popperModifiers={{
                    flip: {
                      enabled: false,
                    },
                  }}
                  value={date_end}
                  defaultValue={date_end}
                  showPopperArrow={false}
                  selected={date_end}
                  onChange={(value) => this.setState({ date_end: value })}
                  dateFormat="yyyy-MM-dd"
                  className={`date-picker schedule-bg ${errors.end_work_at && 'error-input'}`}
                  calendarClassName="date-calendar"
                />
              </div>
              <span>-</span>
              <div className="timepicker">
                <DatePicker
                  className={`datepicker-time ${(errors.end_work_at || timeError) && 'error-input'}`}
                  selected={end_work_at}
                  onChange={(date) => this.setState({ end_work_at: date })}
                  onChangeRaw={(e) => this.setState({ end_work_raw: e.target.value })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  placeholderText={end_work_at}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  showPopperArrow={false}
                  customInput={
                    <MaskedInput
                      type="text"
                      mask={[/[0-2]/, checkTime(end_work_raw) ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                    />
                  }
                />
              </div>
            </div>
            {errors.end_work_at && <p className="error-input-text mb-0 mt-1">{errors.end_work_at}</p>}
            {timeError && <p className="error-input-text mb-0 mt-1">{timeError}</p>}
          </div>
          {/* <Repeat
            handleChangeRepeat={this.handleChangeRepeat}
            repeat={repeat_other}
            startDate={date_start}
            errors={errors}
          /> */}
          <div className="addShift-content-items">
            <Breaks
              addBreaks={this.addBreaks}
              breaks={breaks}
              errors={errors}
              deleteBreaks={this.deleteBreaks}
              handleChangeBreaksStart={this.handleChangeBreaksStart}
              handleChangeBreaksEnd={this.handleChangeBreaksEnd}
            />
          </div>
          <div className="addShift-content-items">
            <Tasks
              tasks={tasks}
              errors={errors}
              addTasks={this.addTasks}
              deleteTasks={this.deleteTasks}
              handleChangeTaskName={this.handleChangeTaskName}
              handleChangeTaskEnd={this.handleChangeTaskEnd}
              handleChangeTaskStart={this.handleChangeTaskStart}
            />
          </div>
          {/* <div className="addShift-content-items">
            <ShiftInformation />
          </div> */}
          <div className="addShift-content-items">
            <Description description={description} handleChangeDescription={this.handleChangeDescription} />
          </div>
          {/* <div className="addShift-content-items">
            <SalaryTypeRules toggleAddRule={this.props.toggleAddRule} />
          </div>
          <div className="addShift-content-items">
            <AdvancedSettings />
          </div> */}
          <div className={`${checkInsAndOutsList.length > 0 ? 'addShift-content-buttons' : ''} d-flex flex-column`}>
            {isCheckInsAndOutsLoading ? <SmallLoader /> :  this.createNewCheckInsAndOutsArray().map((el) => {
              return (
                <p
                  key={el.id}
                  className="d-flex justify-content-between addShift__checkpoint"
                >
                  <span className="addShift__checkpoint-status">
                    {el.status === 'CHECK_IN' ? 'Check in' : 'Check out'}
                  </span>
                  <span className="addShift__checkpoint-time">
                    {el.created_at
                      ? moment(el.created_at).format('MMMM D, YYYY, h:mm a')
                      : '-'}
                  </span>
                </p>)})}
          </div>
          <div className="addShift-content-buttons d-flex">
            <button className='addShift-header__status' onClick={this.handleCheckShift}>{checkInsAndOutsList[checkInsAndOutsList.length - 1]?.status === 'CHECK_IN' ? <span><Clock />Check out</span> : <span>Check in</span>}</button>
          </div>
          <div className="addShift-content-buttons d-flex justify-content-between">
            <div>
              {this.props.myAccData.role !== 'EMPLOYEE' && (
                <button className="addShift-content-buttons__save" onClick={this.updateShift} value="save">
                  {t('update')}
                </button>
              )}
              <button className="addShift-content-buttons__cancel" onClick={closeEditShift}>
                {t('cancel')}
              </button>
            </div>
            {this.props.myAccData.role !== 'EMPLOYEE' && (
              <button className="addShift-content-buttons__delete" onClick={this.props.showConfirmPopup}>
                {t('delete')}
              </button>
            )}
          </div>
        </div>
        <CSSTransition in={this.state.isShowRepeatModal} timeout={300} classNames="show" unmountOnExit>
          <RepeatModal
            data={{ repeat, repeat_every, repeat_on }}
            closePopup={this.closeRepeats}
            saveRepeats={this.saveRepeats}
          />
        </CSSTransition>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.scheduleReducer.users,
    memberFilter: state.scheduleReducer.memberFilter,
    myAccData: state.authReducer.myAccData,
    organization: state.organizationReducer.organization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    scheduleActionCreators: bindActionCreators(scheduleActionCreators, dispatch),
    organizationActionCreators: bindActionCreators(organizationActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditShift));
