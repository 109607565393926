import React, { Component } from 'react';
import { EyeOn, EyeOff } from '../Svg/Svg';
import axios from 'axios';

import { withTranslation } from 'react-i18next';

class PasswordEditor extends Component {
  state = {
    isShowPassword: false,
    password: '',
    confirm: '',
    errors: {},
  };

  toggleInputType = () => {
    const { isShowPassword } = this.state;
    this.setState({ isShowPassword: !isShowPassword });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();
    const data = { password: this.state.password, password_confirmation: this.state.confirm };
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/change-password`,
      data,
    };
    axios(options)
      .then((response) => {
        this.handleSuccess(response);
      })
      .catch((error) => {
        this.handleError(error);
      });
  };

  handleSuccess = (success) => {
    const { status } = success;
    if (status === 200) {
      this.props.history.goBack();
    }
  };

  handleError = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  render() {
    const { isShowPassword, errors } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="addManager">
          <div className="content addManager__background">
            <div className="addManager-wrapper">
              <button className="settings-buttonBack" onClick={() => this.props.history.goBack()}>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('back')}
              </button>
              <form
                className="addManager-left__form borderless"
                onSubmit={this.submitForm}
                id="form"
              >
                <label className="addManager-left__form-input-wrapper-items__label">
                  {t('new_password')} <span>*</span>
                </label>
                <div className="auth-form-inputContainer">
                  <div
                    className={`auth-form__input bg-white ${errors.password ? 'error-input' : ''}`}
                  >
                    <input
                      type={isShowPassword ? 'text' : 'password'}
                      name="password"
                      className="addManager-left__form-input-wrapper-items__input"
                      onChange={this.handleChange}
                      value={this.state.password}
                    />
                    {isShowPassword ? (
                      <EyeOff toggleInputType={this.toggleInputType} />
                    ) : (
                      <EyeOn toggleInputType={this.toggleInputType} />
                    )}
                  </div>
                  {errors.password && <span className="error-input-text">{errors.password}</span>}
                </div>
                <label className="addManager-left__form-input-wrapper-items__label">
                  {t('confirm_password')} <span>*</span>
                </label>
                <div className="auth-form-inputContainer">
                  <div
                    className={`auth-form__input bg-white ${
                      errors.password || errors.password_confirmation ? 'error-input' : ''
                    }`}
                  >
                    <input
                      type={isShowPassword ? 'text' : 'password'}
                      name="confirm"
                      className="addManager-left__form-input-wrapper-items__input"
                      onChange={this.handleChange}
                      value={this.state.confirm}
                    />
                    {isShowPassword ? (
                      <EyeOff toggleInputType={this.toggleInputType} />
                    ) : (
                      <EyeOn toggleInputType={this.toggleInputType} />
                    )}
                  </div>
                  {errors.password && (
                    <span className="error-input-text">{errors.password_confirmation}</span>
                  )}
                </div>
                <div className="addManager-buttons">
                  <button type="submit" className="addManager-buttons__saveBtn">
                    {t('update')}
                  </button>
                  <button
                    type="button"
                    className="addManager-buttons__cancelBtn"
                    onClick={() => this.props.history.goBack()}
                  >
                    {t('cancel')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PasswordEditor);
