export const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CREATE_EMPLOYEE":
      return {
        ...state,
      };

    case "UPDATE_EMPLOYEE":
      return {
        ...state,
      };

    case "GET_EMPLOYEES":
      return {
        ...state,
        employees: action.employees,
        meta: action.meta,
      };

    case "GET_EMPLOYEE_DATA":
      return {
        ...state,
        employeeData: action.employeeData,
      };

    case "DELETE_EMPLOYEE":
      return {
        ...state,
      };

    case "TOGGLE_ACTIVATION_EMPLOYEE":
      return {
        ...state,
      };

    default:
      return state;
  }
}
