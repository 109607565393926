export const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CREATE_ORGANIZATION":
      return {
        ...state,
      };

    case "GET_ORGANIZATION":
      return {
        ...state,
        organization: action.organization,
        meta: action.meta,
      };

    case "CREATE_ORGANIZATION_AREAS":
      return {
        ...state,
      };

    case "GET_ORGANIZATION_ACCOUNT":
      return {
        ...state,
        organizationData: action.organizationData,
      };

    case "DELETE_ORGANIZATION":
      return {
        ...state,
      };

    case "UPDATE_ORGANIZATION":
      return {
        ...state,
      };

    case "GET_CURRENT_ORGANIZATION_AREA":
      return {
        ...state,
        currentArea: action.currentArea,
      };

    case "UPDATE_CURRENT_ORGANIZATION_AREA":
      return {
        ...state,
      };

    case "UPDATE_ORGANIZATION_AREA":
      return {
        ...state,
      };

    case "GET_ORGANIZATION_AREA":
      return {
        ...state,
        area: action.area,
      };

    case "DELETE_ORGANIZATION_AREA":
      return {
        ...state,
      };

    case "GET_ASIGNED_MANAGER":
      return {
        ...state,
        asignedManagers: action.asignedManagers,
      };

    case "ASIGN_MANAGER":
      return {
        ...state,
      };

    case "DELETE_ORGANIZATION":
      return {
        ...state,
      };

    default:
      return state;
  }
}
