import moment from 'moment';

export const capitalize = (str) => {
  const firstCharacter = str.substring(0, 1);
  const restString = str.substring(1);

  return firstCharacter.toUpperCase() + restString.toLowerCase();
};

export const deepCopyArray = (arr) => {
  let copy = [];

  arr.forEach((elem) => {
    if (Array.isArray(elem)) {
      copy.push(deepCopyArray(elem));
    } else {
      if (typeof elem === 'object') {
        copy.push(deepCopyObject(elem));
      } else {
        copy.push(elem);
      }
    }
  });
  return copy;
};

export const deepCopyObject = (obj) => {
  let tempObj = {};

  for (let [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      tempObj[key] = deepCopyArray(value);
    } else {
      if (typeof value === 'object') {
        tempObj[key] = deepCopyObject(value);
      } else {
        tempObj[key] = value;
      }
    }
  }
  return tempObj;
};

export const generateAvatarInitials = (name) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...name.matchAll(rgx)] || [];

  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
  return initials;
};

export const humanizeString = (str) => {
  return str
    .split('_')
    .map(
      (word, index) =>
        (index === 0 ? word.charAt(0).toUpperCase() : word.charAt(0).toLowerCase()) + word.slice(1).toLowerCase()
    )
    .join(' ');
};

export const sortArrByDate = (arr, format, key) => {
  return arr.sort((a, b) => moment(a[key]).format(format) - moment(b[key]).format(format));
};

export const convertTimeToHours = (hours, minutes) => {
  return Math.round((hours + minutes / 60) * 10) / 10;
};

export const convertSecondsToHours = (sec) => {
  return Math.round((sec / 3600) * 10) / 10;
};

export const getCustomPagination = (arr, perPage, curPage) => {
  const indexOfLastItem = curPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentItems = arr?.slice(indexOfFirstItem, indexOfLastItem);
  const lastPage = Math.ceil(arr?.length / perPage);

  return { currentItems, lastPage };
};
