import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Select from 'react-select';
import qs from 'query-string';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import * as managerActionCreators from '../../../actions/managerActionCreators';

const Option = ({ value, label }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar name={label} size="22" round="50%" className="mr-3" />
      <div>{label}</div>
    </div>
  );
};

function ManagerAddPopup({
  closePopup,
  asignManager,
  area,
  managerActionCreators,
  managers,
  handleChangeManager,
  handleChangeArea,
  managerError,
  clearErrors,
}) {
  useEffect(() => {
    const pagination = {};
    pagination.search = '';
    pagination.isActive = 'active';
    let params = `?${qs.stringify(pagination)}`;
    managerActionCreators.getManager({ params });

    return () => {
      clearErrors('managerError');
    };
  }, []);
  const { t } = useTranslation();
  const managerList = managers?.map((item) => {
    return { label: `${item.first_name} ${item.last_name}`, value: item.id };
  });
  const areas = area?.map((item) => {
    return { label: item.name, value: item.id };
  });
  return (
    <div className="notify-overlay">
      <div className="organization-popup">
        <h6 className="organization-popup__title">{t('assign_manager')}</h6>
        <div className="organization-popup-items">
          <div className="addManager-left__form-input-wrapper-items organization-popup__select">
            <label className="addManager-left__form-input-wrapper-items__label">{t('name')}</label>
            <Select
              className={`addManager-left__form-input-wrapper-items__select ${managerError.user_id && 'error-input'}`}
              formatOptionLabel={Option}
              noOptionsMessage={() => (
                <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              options={managerList}
              placeholder={t('select_manager')}
              onChange={handleChangeManager}
              styles={{
                option: (base, state) => ({
                  ...base,
                  height: '100%',
                  backgroundColor: state.isSelected ? '#F6F7F9' : null,
                  color: '#212529',
                  '&:hover': {
                    backgroundColor: '#F6F7F9',
                  },
                }),
              }}
              name="manager"
            />
            {managerError.user_id && <p className="error-input-text mb-0">{managerError.user_id}</p>}
          </div>
          <div className="addManager-left__form-input-wrapper-items organization-popup__select">
            <label className="addManager-left__form-input-wrapper-items__label">{t('area')}</label>
            <Select
              className={`addManager-left__form-input-wrapper-items__select ${managerError.area_id && 'error-input'}`}
              options={areas}
              placeholder={t('select_area')}
              onChange={handleChangeArea}
              styles={{
                option: (base, state) => ({
                  ...base,
                  height: '100%',
                  backgroundColor: state.isSelected ? '#F6F7F9' : null,
                  color: '#212529',
                  '&:hover': {
                    backgroundColor: '#F6F7F9',
                  },
                }),
              }}
              name="area"
            />
            {managerError.area_id && <p className="error-input-text mb-0">{managerError.area_id}</p>}
          </div>
        </div>
        <div className="organization-popup-buttons">
          <button className="organization-popup-buttons__addBtn" onClick={asignManager}>
            {t('add')}
          </button>
          <button className="organization-popup-buttons__cancelBtn" onClick={closePopup} type="button">
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    managers: state.managerReducer.managers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    managerActionCreators: bindActionCreators(managerActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerAddPopup);
