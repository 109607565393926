import React from 'react';
import { useTranslation } from 'react-i18next';

import bellOff from '../../images/bell-off.svg';

export default function NoData() {
  const { t } = useTranslation();
  return (
    <div className="notifications__content">
      <div className="notifications__no-data">
        <img src={bellOff} alt="bell-off" />
        <h1>{t('no_new_notifications')}</h1>
        <h3>{t('looks_havent_notifications')}</h3>
      </div>
    </div>
  );
}
