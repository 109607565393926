import axios from "axios";
import qs from "query-string";
import {
  showLoader,
  hideLoader,
  showSmallLoader,
  hideSmallLoader,
} from "./viewActionCreators";

export function getAdministrator({ params }) {
  let param = qs.parse(params);
  let url =
    param.search.length === 0
      ? `${process.env.REACT_APP_API_URL}/api/v1/admins${params}`
      : `${process.env.REACT_APP_API_URL}/api/v1/search/admins${params}`;
  return (dispatch) => {
    const options = {
      method: "GET",
      url,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "GET_ADMINISTRATOR",
          administrator: response.data.data,
          meta: response.data.meta,
        });
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        dispatch(hideSmallLoader());
      });
  };
}

export function createAdministrator({ data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/admin/create`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "CREATE_ADMINISTRATOR",
        });
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function updateAdministrator({ id, data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/v1/admin/${id}/update`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "UPDATE_ADMINISTRATOR",
        });
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function toggleActivationAdministrator({
  id,
  active,
  handleActivateSuccess,
}) {
  return (dispatch) => {
    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/${id}/activation`,
      data: {
        is_active: active,
      },
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "TOGGLE_ACTIVATION_ADMINISTRATOR",
        });
        dispatch(hideLoader());
        handleActivateSuccess(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function deleteAdministrator({ id, handleDeleteSuccess }) {
  return (dispatch) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/${id}/trash`,
    };

    return axios(options)
      .then((response) => {
        dispatch({
          type: "DELETE_ADMINISTRATOR",
        });
        handleDeleteSuccess(response);
      })
      .catch((error) => {});
  };
}

export function getAdminAcc({ id }) {
  return (dispatch) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/v1/user/${id}`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: "GET_ADMINISTRATOR_DATA",
          adminData: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}
