import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from 'react-avatar';
import moment from 'moment';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import * as leavesActionCreators from '../../actions/leavesActionCreators';
import SmallLoader from '../helpComponent/SmallLoader';
import FullPageLoader from '../helpComponent/FullPageLoader';
import checkUserPermission from '../../Utils/CheckUserPermission';
import { humanizeString } from '../../Utils/helpers';

const statuses = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Declined', value: 'DECLINED' },
];

class LeavesTable extends Component {
  constructor() {
    super();
    this.state = {
      pagination: {
        start: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
        end: moment().add(11, 'M').endOf('month').format('YYYY-MM-DDT23:59:00'),
        status: '',
      },
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });

    this.setState({ pagination });
    this.setLeaves(pagination);
  }

  setLeaves = (pagination = this.state.pagination) => {
    const { leavesActionCreators, history } = this.props;

    let params = `?${qs.stringify(pagination)}`;
    leavesActionCreators.getLeaves({ params });
    history.push({ search: params });
  };

  handleChangeSelect = (selected) => {
    const { pagination } = this.state;
    pagination.status = selected.value;

    this.setState({ pagination });
    this.setLeaves(pagination);
  };

  changeLeavesStatus = (request_id, status) => {
    const { leavesActionCreators } = this.props;
    const data = {
      status,
      request_id,
    };
    leavesActionCreators.changeLeavesStatus({
      data,
      handleResponse: () => this.setLeaves(),
    });
  };

  render() {
    const { leaves, showSmallLoader, t, myAccData, showLoader } = this.props;

    return (
      <div className="d-flex">
        <div className="content">
          <div className="settings-wrapper d-flex">
            <div className={`settings-table reports-table w-100`}>
              <div className="reports-table-header">
                <h6 className="reports-table-header__text">{t('all_leaves')}</h6>
                <div className="reports-table-header-items">
                  <Select
                    className={`addManager-left__form-input-wrapper-items__select reports-select`}
                    options={statuses}
                    onChange={this.handleChangeSelect}
                    name="type"
                    value={statuses.find((item) => item.value === this.state.pagination.status)}
                    placeholder={t('sort_by_status')}
                  />
                </div>
              </div>
              <table className="table">
                <thead className="settings-table-thead reports-table-thead">
                  <tr>
                    <th scope="col">{t('employees')}</th>
                    <th scope="col">{t('reason')}</th>
                    <th scope="col">{t('from')}</th>
                    <th scope="col">{t('to')}</th>
                    <th scope="col">{t('date_of_request')}</th>
                    <th scope="col">{t('comment')}</th>
                    <th scope="col">{t('status')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {showSmallLoader ? (
                    <tr>
                      <td colSpan="8">
                        <SmallLoader />
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      {leaves?.length !== 0 ? (
                        <Fragment>
                          {leaves?.map((item, index) => {
                            return (
                              <tr className={`settings-table__tr`} key={index}>
                                <td className="settings-table-items" scope="row">
                                  <Avatar
                                    name={`${item.user.first_name} ${item.user.last_name}`}
                                    size="36"
                                    round="50%"
                                  />
                                  <div className="settings-table-items-info">
                                    <Link
                                      to={`/settings/employees/employee/${item.user.id}`}
                                      className="settings-table-items-info__name"
                                    >
                                      {item.user.first_name} {item.user.last_name}
                                    </Link>
                                    <p className="settings-table-items-info__id"># {item.id}</p>
                                    <p></p>
                                  </div>
                                </td>
                                <td className="reports-table__text align-middle">
                                  {`${humanizeString(item.type.replace('TYPE_', ''))}`}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {moment(item.start_at).format('YYYY-MM-DD')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {moment(item.end_at).format('YYYY-MM-DD')}
                                </td>
                                <td className="reports-table__text align-middle">
                                  {moment(item.created_at).format('YYYY-MM-DD')}
                                </td>
                                <td className="reports-table__text align-middle">{item.description}</td>
                                <td className="reports-table__text align-middle">
                                  <span className={`status-badge status-badge__${item.status.toLowerCase()}`}>
                                    {humanizeString(item.status)}
                                  </span>
                                </td>
                                <td className="text-center align-middle">
                                  {checkUserPermission(myAccData, 'component.leavesActions') &&
                                    item.status === 'PENDING' && (
                                      <div>
                                        <button
                                          onClick={() => this.changeLeavesStatus(item.id, 'DECLINED')}
                                          className="outline-btn m-1"
                                        >
                                          {t('decline')}
                                        </button>
                                        <button
                                          onClick={() => this.changeLeavesStatus(item.id, 'APPROVED')}
                                          className="organization-popup-buttons__addBtn m-1"
                                        >
                                          {t('approve')}
                                        </button>
                                      </div>
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <p className="settings-table-emptyText">{t('dont_have_leaves')}</p>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    leaves: state.leavesReducer.leaves,
    showSmallLoader: state.viewReducer.showSmallLoader,
    showLoader: state.viewReducer.showLoader,
    myAccData: state.authReducer.myAccData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leavesActionCreators: bindActionCreators(leavesActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LeavesTable));
