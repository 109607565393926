import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Switch from 'react-switch';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import { EditorState } from 'draft-js';
import { withTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import * as newsActionCreators from '../../actions/newsActionCreators';
// import 'draft-js/dist/Draft.css';

class AddNewNews extends Component {
  constructor() {
    super();
    this.state = {
      isActive: true,
      title: '',
      // content: '',
      publish_at: new Date(),
      errors: {},
      years: [],
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    this.range();
  }

  submitForm = (e) => {
    e.preventDefault();

    const { title, isActive, publish_at, editorState } = this.state;
    const data = {
      title,
      content: stateToHTML(editorState.getCurrentContent()),
      publish_at: moment(publish_at).format('yyyy-MM-DD'),
      is_active: isActive,
    };
    this.props.newsActionCreators.createNews({
      data,
      handleError: this.handleError,
      handleSuccess: this.handleSuccess,
    });
  };

  handleSuccess = (success) => {
    const { status } = success;
    if (status === 201) {
      this.props.history.push('/news');
    }
  };

  handleError = (err) => {
    const { status, data } = err.response;
    if (status === 422) {
      this.setState({
        errors: data.errors,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeEditor = (editorState) => {
    this.setState({ editorState });
  };

  range = (startAt = 1930) => {
    const d = new Date();
    let size = d.getFullYear() - startAt + 1;
    let years = [...Array(size).keys()].map((i) => i + startAt);
    this.setState({
      years: years.reverse(),
    });
  };

  render() {
    const { isActive, errors, years, publish_at, editorState } = this.state;
    const { t } = this.props;
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return (
      <form className="organization__form mw-600" onSubmit={this.submitForm} id="form">
        <h6 className="addManager-left__title">{t('add_news')}</h6>
        <div className="addManager-avatarUploader-wrapper">
          <div className="d-flex">
            {isActive ? (
              <div className="d-flex align-items-center">
                <svg
                  className="addManager-activeIcon"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.66634 4L4.99967 7.66667L3.33301 6"
                    stroke="#0B9444"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <circle cx="6" cy="6" r="5.5" stroke="#0B9444" />
                </svg>
                <p className="addManager-left__text">{t('active')}</p>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <p className="addManager-left__text">{t('inactive')}</p>
              </div>
            )}
            <Switch
              className={`switcher ${isActive ? 'active' : 'inactive'}`}
              onChange={() => {
                this.setState({ isActive: !isActive });
              }}
              checked={!!isActive}
            />
          </div>
        </div>
        <div className="addManager-left__form-input-wrapper-items">
          <label className="addManager-left__form-input-wrapper-items__label">
            {t('title')} <span>*</span>
          </label>
          <input
            className={`addManager-left__form-input-wrapper-items__input ${errors.title && 'error-input'}`}
            type="text"
            name="title"
            onChange={this.handleChange}
          />
          {errors.title && <p className="error-input-text mb-0">{errors.title}</p>}
        </div>
        <div className="addManager-left__form-input-wrapper-items">
          <label className="addManager-left__form-input-wrapper-items__label">
            {t('content')} <span>*</span>
          </label>
          <Editor
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState={editorState}
            onEditorStateChange={this.handleChangeEditor}
          />
          {/* <textarea
            name="content"
            className={`textarea addManager-left__form-input-wrapper-items__input ${errors.content && 'error-input'}`}
            onChange={this.handleChange}
          /> */}
          {errors.content && <p className="error-input-text mb-0">{errors.content}</p>}
        </div>
        <div className="addManager-left__form-input-wrapper-items">
          <label className="addManager-left__form-input-wrapper-items__label">{t('publish_at')}</label>
          <DatePicker
            showPopperArrow={false}
            selected={publish_at}
            onChange={(value) => this.setState({ publish_at: value })}
            dateFormat="yyyy-MM-dd"
            className="date-picker"
            calendarClassName="date-calendar"
            minDate={new Date()}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className="date-picker-button__prev"
                  type="button"
                >
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.25 10.5L1.75 6L6.25 1.5"
                      stroke="#0B9444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                  className="date-picker-select year"
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(date)]}
                  className="date-picker-select"
                  onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  className="date-picker-button__next"
                  type="button"
                >
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.25 10.5L1.75 6L6.25 1.5"
                      stroke="#0B9444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )}
          />
        </div>
        <div className="addManager-buttons">
          <button type="submit" className="addManager-buttons__saveBtn">
            Create
          </button>
          <button
            type="button"
            className="addManager-buttons__cancelBtn"
            onClick={() => this.props.history.push('/news')}
          >
            {t('cancel')}
          </button>
        </div>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newsActionCreators: bindActionCreators(newsActionCreators, dispatch),
  };
}

export default withTranslation()(connect(null, mapDispatchToProps)(AddNewNews));
