import React from 'react';

import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { convertSecondsToHours } from '../../Utils/helpers';

export default function ChartBar(props) {
  const { t } = useTranslation();
  const data = {
    datasets: [
      {
        barThickness: 16,
        backgroundColor: '#F6F7F9',
        hoverBackgroundColor: '#0B9444',
        data: props.data?.map((item) => ({
          x: moment(item.date),
          y: convertSecondsToHours(item.total_seconds),
        })),
      },
    ],
  };
  const options = {
    cornerRadius: 100,
    type: 'bar',
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'label',
      position: 'cursor',
      xPadding: 8,
      yPadding: 8,
      backgroundColor: '#828282',
      displayColors: false,
      cornerRadius: 4,
      titleFontSize: 14,
      titleMarginBottom: 0,
      bodyFontColor: 'rgba(255, 255, 255, 0.7)',
      callbacks: {
        label: () => null,
        title: (tooltipItem) => `${tooltipItem[0].value} ${t('hours')}`,
      },
    },
    scales: {
      xAxes: [
        {
          offset: true,
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'D',
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            min: 0,
            max: props.yMax || undefined,
            stepSize: 1,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return <Bar data={data} options={options} height={180} />;
}
