import React, { Component } from 'react';
import Table from './Table';
import FullPageLoader from '../helpComponent/FullPageLoader';
import qs from 'query-string';
import { CSSTransition } from 'react-transition-group';
import Popup from '../helpComponent/Popup';

import { bindActionCreators } from 'redux';
import * as administratorActionCreators from '../../actions/administratorActionCreators';
import { connect } from 'react-redux';

class Administrator extends Component {
  constructor() {
    super();
    this.state = {
      isShowAgreePopup: false,
      deleteId: '',
      pagination: {
        page: 1,
        limit: 10,
        search: '',
      },
      search: '',
      typingTimeout: 0,
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.keys(pagination).forEach((key) => {
      if (!!params[key]) {
        pagination[key] = params[key];
      }
    });
    this.setState({ pagination }, () => {
      let params = `?${qs.stringify(pagination)}`;
      this.props.administratorActionCreators.getAdministrator({ params });
    });
  }

  handlePopup = (id) => {
    this.setState({
      isShowAgreePopup: !this.state.isShowAgreePopup,
      deleteId: id,
    });
  };

  handleDelete = () => {
    const { deleteId } = this.state;
    this.props.administratorActionCreators.deleteAdministrator({
      id: deleteId,
      handleDeleteSuccess: this.handleDeleteSuccess,
    });
  };

  handleDeleteSuccess = (response) => {
    const { status } = response;
    const { pagination } = this.state;
    const { history } = this.props;
    if (status === 200) {
      this.setState({
        isShowAgreePopup: false,
        deleteId: '',
      });
    }
    let params = `?${qs.stringify(pagination)}`;
    this.props.administratorActionCreators.getAdministrator({ params });
    history.push({ search: params });
  };

  handlePageChange = (page) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.page = page;
    let params = `?${qs.stringify(pagination)}`;
    this.props.administratorActionCreators.getAdministrator({ params });
    history.push({ search: params });
  };

  handleChangeSearch = (e) => {
    const self = this;
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.search = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      pagination,
      typingTimeout: setTimeout(function () {
        self.props.administratorActionCreators.getAdministrator({ params });
      }, 500),
    });
    history.push({ search: params });
  };

  handleSelect = (e) => {
    const { pagination } = this.state;
    const { history } = this.props;
    pagination.limit = e.target.value;
    pagination.page = 1;
    let params = `?${qs.stringify(pagination)}`;
    this.props.administratorActionCreators.getAdministrator({ params });
    history.push({ search: params });
  };

  toggleActivation = (id, isActive) => {
    let active = isActive === 1 ? 0 : 1;
    this.props.administratorActionCreators.toggleActivationAdministrator({
      id,
      active,
      handleActivateSuccess: this.handleActivateSuccess,
    });
  };

  handleActivateSuccess = (response) => {
    const { status } = response;
    const { pagination } = this.state;
    const { history } = this.props;
    if (status === 200) {
      let params = `?${qs.stringify(pagination)}`;
      this.props.administratorActionCreators.getAdministrator({
        params,
      });
      history.push({ search: params });
    }
  };

  render() {
    const { administrator, meta } = this.props;
    const { isShowAgreePopup } = this.state;
    return (
      <div className="administrator">
        <CSSTransition in={isShowAgreePopup} timeout={300} classNames="show" unmountOnExit>
          <Popup
            type="delete"
            text="Are you sure you want to delete an administrator ?"
            onHandleClose={this.handlePopup}
            onHandleDelete={this.handleDelete}
          />
        </CSSTransition>
        <div className="content ">
          <Table
            administrator={administrator}
            meta={meta}
            handleChangeSearch={this.handleChangeSearch}
            // handleChange={this.handleChange}
            handlePopup={this.handlePopup}
            count={meta?.last_page}
            searchValue={this.state.pagination.search}
            handlePageChange={this.handlePageChange}
            handleSelect={this.handleSelect}
            toggleActivation={this.toggleActivation}
            page={this.state.pagination.page}
            selectValue={this.state.pagination.limit}
          />
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    administrator: state.administratorReducer.administrator,
    meta: state.administratorReducer.meta,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    administratorActionCreators: bindActionCreators(administratorActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Administrator);
