import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

// Helmet wrapper component with meta props (only title at the moment)
const withMeta = ({ children, title }) => {
  const defaultTitle = 'EFMAB';

  return (
    <Fragment>
      <Helmet>
        <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
      </Helmet>

      {children}
    </Fragment>
  );
};

export { withMeta };
