import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import qs from 'query-string';
import moment from 'moment';

import * as leavesActionCreators from '../../actions/leavesActionCreators';
import { humanizeString } from '../../Utils/helpers';
import checkUserPermission from '../../Utils/CheckUserPermission';
import FullPageLoader from '../helpComponent/FullPageLoader';

class Leaves extends Component {
  componentDidMount() {
    this.setLeaves();
  }

  setLeaves = () => {
    const { leavesActionCreators } = this.props;
    const data = {
      start: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
      end: moment().add(11, 'M').endOf('month').format('YYYY-MM-DDT23:59:00'),
      status: 'PENDING',
    };
    const params = `?${qs.stringify(data)}`;
    leavesActionCreators.getLeaves({ params });
  };

  changeLeavesStatus = (request_id, status) => {
    const { leavesActionCreators } = this.props;
    const data = {
      status,
      request_id,
    };
    leavesActionCreators.changeLeavesStatus({
      data,
      handleResponse: () => this.setLeaves(),
    });
  };

  render() {
    const { leaves, myAccData, t, showLoader } = this.props;

    return (
      <div className="dashboard-table dashboard-table-scroll">
        <div className="dashboard-table-titles">
          <h6 className="dashboard-table__title mb-0">{t('requests_for_leave')}</h6>
          <button onClick={() => this.props.history.push('/leaves')} className="dashboard-table__btn">
            {t('see_all')}
          </button>
        </div>
        {leaves?.length !== 0 ? (
          leaves.map((item, index) => (
            <div key={index} className="dashboard-news-table mt-3">
              <div className="d-flex justify-content-between align-items-start">
                <p className="dashboard-news-table__title">
                  {item.user.first_name} {item.user.last_name}
                </p>
                <span className={`status-badge status-badge__${item.status.toLowerCase()}`}>
                  {humanizeString(item.status)}
                </span>
              </div>
              <p className="dashboard-news-table__text">{`${humanizeString(item.type.replace('TYPE_', ''))}`}</p>
              <p className="dashboard-news-table__description">
                {t('from')} {moment(item.start_at).format('MMM DD')} {t('to')} {moment(item.end_at).format('MMM DD')}
              </p>
              <p className="dashboard-news-table__description">
                {t('date_of_request')}: {moment(item.created_at).format('DD MMM, YYYY')}
              </p>
              {item.description && (
                <p className="dashboard-news-table__description">
                  {t('comment')}: {item.description}
                </p>
              )}
              {checkUserPermission(myAccData, 'component.leavesActions') && (
                <div className="dashboard-news-table__buttons d-flex mt-3">
                  <button onClick={() => this.changeLeavesStatus(item.id, 'DECLINED')} className="grey-btn w-50 mr-3">
                    {t('decline')}
                  </button>
                  <button
                    onClick={() => this.changeLeavesStatus(item.id, 'APPROVED')}
                    className="organization-popup-buttons__addBtn mr-0 w-50"
                  >
                    {t('approve')}
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="dashboard-nodata">{t('dont_have_leaves')}</p>
        )}
        {showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    leaves: state.leavesReducer.leaves,
    myAccData: state.authReducer.myAccData,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leavesActionCreators: bindActionCreators(leavesActionCreators, dispatch),
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Leaves)));
