import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import * as viewActionCreators from '../../actions/viewActionCreators';
import All from './All';
import Pending from './Pending';
// import NoData from './NoData';

import cross from '../../images/cross.svg';

export class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      component: 'all',
      tabs: [
        { name: 'all', val: 'All' },
        { name: 'pending', val: 'Pending' },
      ],
    };
  }

  setTabComponent = (name) => {
    switch (name) {
      case 'all':
        return (
          <All
            notifications={this.props.notifications}
            onDelete={this.onDelete}
            onCLickRequest={this.onCLickRequest}
          />
        );

      case 'pending':
        return (
          <Pending
            notifications={this.props.notifications}
            onDelete={this.onDelete}
            onCLickRequest={this.onCLickRequest}
          />
        );

      default:
        return (
          <All
            notifications={this.props.notifications}
            onDelete={this.onDelete}
            onCLickRequest={this.onCLickRequest}
          />
        );
    }
  };

  onCLickRequest = (id, status) => {
    const params = { data: { status, request_id: id } };
    this.props.viewActionCreators.requestNotification(params);
  };

  onDelete = (id) => {
    const params = { id };
    this.props.viewActionCreators.deleteNotification(params);
  };

  render() {
    const { close, t } = this.props;
    const { component, tabs } = this.state;

    return (
      <div className="notifications">
        <div className="notifications__header">
          <h1>{t('notifications')}</h1>
          <img src={cross} alt="close" onClick={close} />
        </div>

        <ul className="notifications__tabs">
          {tabs.map((item, index) => {
            const selectedTab = component === item.val.toLowerCase();

            return (
              <li
                onClick={() => this.setState({ component: item.val.toLowerCase() })}
                key={index}
                className={`notifications__tabs-item${selectedTab ? '_selected' : ''}`}
              >
                {t(item.name)}
              </li>
            );
          })}
        </ul>

        <TransitionGroup component={null}>
          <CSSTransition key={component} timeout={300} classNames="show" exit={false}>
            {this.setTabComponent(component)}
          </CSSTransition>{' '}
        </TransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.viewReducer.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    viewActionCreators: bindActionCreators(viewActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Notifications));
