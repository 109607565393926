import React from 'react';
import { connect } from 'react-redux';
import { Component } from 'react';
import ShiftsInfo from './ShiftsInfo';
import StaffingOptimization from './StaffingOptimization';
import ShiftCoverage from './ShiftCoverage';
import WorkingHourChart from './WorkingHoursChart';
import BirthdaysInfo from './BirthdaysInfo';
import News from './News';
import Leaves from './Leaves';
import checkUserPermission from '../../Utils/CheckUserPermission';

class Dashboard extends Component {
  render() {
    return (
      <div>
        <div className="dashboard">
          <div className="content">
            <div className="dashboard-container">
              <div className="dashboard-tables">
                <ShiftsInfo />
                <WorkingHourChart />
                <div className="d-flex">
                  {checkUserPermission(this.props.myAccData, 'route.leaves') && <Leaves />}
                  <News />
                </div>
              </div>
              <div className="dashboard-wrapper">
                <div className="dashboard-items-wrapper">
                  <StaffingOptimization />
                  <ShiftCoverage />
                </div>
                <div className="dashboard-items-wrapper">
                  <BirthdaysInfo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myAccData: state.authReducer.myAccData,
  };
}

export default connect(mapStateToProps)(Dashboard);
