import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ColorPicker from '../../helpComponent/ColorPicker';
import PlacesAutocomplete from '../../helpComponent/PlacesAutocomplete';

export default function AreaAddPopup({
  closePopup,
  handleChange,
  handleChangeColor,
  handleChangeAddress,
  handleSelectAddress,
  address,
  bgcolor,
  createArea,
  errors,
  clearErrors,
}) {
  useEffect(() => {
    return () => {
      clearErrors('areaError');
    };
  }, []);
  const { t } = useTranslation();

  return (
    <div className="notify-overlay">
      <div className="organization-popup">
        <h6 className="organization-popup__title">{t('add_area')}</h6>
        <div className="organization-popup-items">
          <div className="organization-popup-items-wrapper">
            <label className="organization-popup-items__label">{t('name')}</label>
            <input
              type="text"
              name="name"
              className={`organization-popup-items-field ${errors.name && 'error-input'}`}
              onChange={handleChange}
            />
            {errors.name && <p className="error-input-text mb-0">{errors.name}</p>}
          </div>
          <div className="organization-popup-items-wrapper">
            <label className="organization-popup-items__label">{t('address')}</label>
            <PlacesAutocomplete
              value={address}
              onChange={handleChangeAddress}
              onSelect={handleSelectAddress}
              className="organization-popup-items-field w-100"
            />
          </div>
          <div className="organization-popup-items-wrapper">
            <label className="organization-popup-items__label">{t('bg_color')}</label>
            <ColorPicker onChange={handleChangeColor} value={bgcolor} />
          </div>
          <div className="organization-popup-items-wrapper">
            <label className="organization-popup-items__label">{t('description')}</label>
            <textarea name="description" className="organization-popup-items-field textarea" onChange={handleChange} />
          </div>
        </div>
        <div className="organization-popup-buttons">
          <button className="organization-popup-buttons__addBtn" onClick={createArea}>
            {t('add')}
          </button>
          <button className="organization-popup-buttons__cancelBtn" onClick={closePopup}>
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  );
}
