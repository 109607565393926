import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';

import * as newsActionCreators from '../../actions/newsActionCreators';
import FullPageLoader from '../helpComponent/FullPageLoader';
import { withTranslation } from 'react-i18next';

class NewsAccount extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.newsActionCreators.getNewsById({ id });
  }

  render() {
    const { newsData, t } = this.props;

    return (
      <div>
        <div className="userAccount">
          <div className="content">
            <div className="userAccount-wrapper">
              <button onClick={() => this.props.history.goBack()} className="settings-buttonBack">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Back to table
              </button>
              <div className="userAccount-table">
                <div className="userAccount-table-avatar ml-0 mw-50">
                  <div className="userAccount-table-avatar-col ml-0">
                    <div className="d-flex">
                      <p className="userAccount-table-avatar__name">{newsData?.title} </p>
                      <span
                        className={`userAccount-table-avatar-active ${
                          newsData?.is_active === 1 ? 'active' : 'inactive'
                        }`}
                      >
                        {newsData?.is_active === 1 ? t('active') : t('inactive')}
                      </span>
                    </div>
                    <p className="userAccount-table-avatar__secondaryText"># {newsData?.id}</p>
                  </div>
                </div>
                <div className="userAccount-table-mainInfo">
                  <h4 className="userAccount-table__title">{t('main_info')}</h4>
                  <div className="userAccount-table-column">
                    <div className="d-flex justify-content-between w-100">
                      <p className="userAccount-table__text">{t('publish_at')}</p>
                      <p className="userAccount-table__text bold">{moment(newsData.publish_at).format('YYYY-MM-DD')}</p>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <p className="userAccount-table__text">{t('content')}</p>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: newsData?.content }}></div>
                  </div>
                </div>
                <div className="userAccount-table-edit">
                  <div className="userAccount-table-edit-wrapper">
                    <Link to={`/news/edit/${newsData?.id}`}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.7"
                          d="M14.1665 2.49993C14.3854 2.28106 14.6452 2.10744 14.9312 1.98899C15.2171 1.87054 15.5236 1.80957 15.8332 1.80957C16.1427 1.80957 16.4492 1.87054 16.7352 1.98899C17.0211 2.10744 17.281 2.28106 17.4998 2.49993C17.7187 2.7188 17.8923 2.97863 18.0108 3.2646C18.1292 3.55057 18.1902 3.85706 18.1902 4.16659C18.1902 4.47612 18.1292 4.78262 18.0108 5.06859C17.8923 5.35455 17.7187 5.61439 17.4998 5.83326L6.24984 17.0833L1.6665 18.3333L2.9165 13.7499L14.1665 2.49993Z"
                          stroke="#212529"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    newsData: state.newsReducer.newsData,
    showLoader: state.viewReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newsActionCreators: bindActionCreators(newsActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NewsAccount));
