import React from 'react';
import Autocomplete from 'react-places-autocomplete';

const DotIndicator = () => {
  return (
    <div aria-hidden="true" className="loading-indicator">
      <span className="loading-dot-1" />
      <span className="loading-dot-2" />
      <span className="loading-dot-3" />
    </div>
  );
};

export default function PlacesAutocomplete({
  onChange,
  onSelect,
  value,
  disabled,
  placeholder,
  isLoading = false,
  className,
}) {
  return (
    <Autocomplete value={value} onChange={onChange} onSelect={onSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="position-relative">
          <input
            {...getInputProps({
              placeholder: placeholder || '',
              className,
              disabled: disabled || false,
            })}
          />
          {(loading || isLoading) && <DotIndicator />}
          {!!suggestions.length && (
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item-active' : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </Autocomplete>
  );
}
