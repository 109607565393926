import axios from 'axios';
import { showLoader, hideLoader, showSmallLoader, hideSmallLoader } from './viewActionCreators';

export function getNews({ params }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/news${params}`,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_NEWS',
          news: response.data.data,
          meta: response.data.meta,
        });
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        dispatch(hideSmallLoader());
      });
  };
}

export function getNewsById({ id }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/news/${id}`,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_NEWS_DATA',
          newsData: response.data.data,
        });
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
}

export function createNews({ data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/api/v1/news/create`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function updateNews({ id, data, handleError, handleSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/api/v1/news/${id}/update`,
      data,
    };

    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        handleSuccess(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleError(error);
        dispatch(hideLoader());
      });
  };
}

export function deleteNews({ id, handleDeleteSuccess }) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/news/${id}/delete`,
    };

    return axios(options)
      .then((response) => {
        handleDeleteSuccess(response);
      })
      .catch((error) => {});
  };
}
