import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import SmallLoader from '../../helpComponent/SmallLoader';

class ManagerTable extends Component {
  toggleHoverClass = (e) => {};

  render() {
    const { handlePopup, asignedManagers, orgId, t } = this.props;
    return (
      <table className="table organization-table">
        <thead className="organization-table-thead">
          <tr>
            <th scope="col">{t('name')}</th>
            <th scope="col">{t('area')}</th>
            <th scope="col">{t('date_added')}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        {!!asignedManagers ? (
          <Fragment>
            {asignedManagers?.length !== 0 ? (
              asignedManagers?.map((item, index) => {
                const firstManagerAdded = item.user_area.filter((el) => item.areas[0].id === el.area_id)[0].created_at;

                return (
                  <tbody className="organization-table-tbody row-hover" key={index}>
                    <tr className="organization-table__tr border-0">
                      <td rowSpan={item.areas.length}>
                        <span className="organization-table__text regular">
                          {item.first_name} {item.last_name}
                        </span>
                      </td>
                      <td>
                        <span className="organization-table__text light">{item.areas[0].name}</span>
                      </td>
                      <td>
                        <span className="organization-table__text light">
                          {moment(firstManagerAdded).format('YYYY-MM-DD')}
                        </span>
                      </td>

                      <td className="organization-table-btn">
                        <div className="organization-table-btn-wrapper">
                          <button
                            className="organization-table__deleteBtn"
                            onClick={() => handlePopup(item.id, item.areas[0].id)}
                            type="button"
                          >
                            <svg
                              id={index}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.5 5H4.16667H17.5"
                                stroke="#EB5757"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033H15.8337Z"
                                stroke="#EB5757"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                    {item.areas.map((area, indexArea) => {
                      if (indexArea > 0) {
                        const managerAdded = item.user_area.filter((el) => area.id === el.area_id)[0].created_at;

                        return (
                          <tr className={`organization-table__tr border-0`} key={indexArea}>
                            <td>
                              <span className="organization-table__text light">{area.name}</span>
                            </td>
                            <td>
                              <span className="organization-table__text light">
                                {moment(managerAdded).format('YYYY-MM-DD')}
                              </span>
                            </td>

                            <td className="organization-table-btn">
                              <div className="organization-table-btn-wrapper">
                                <button
                                  className="organization-table__deleteBtn"
                                  onClick={() => handlePopup(item.id, area.id)}
                                  type="button"
                                >
                                  <svg
                                    id={index}
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.5 5H4.16667H17.5"
                                      stroke="#EB5757"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033H15.8337Z"
                                      stroke="#EB5757"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                );
              })
            ) : (
              <tr>
                <td colSpan="4" className="text-center p-0">
                  <div className="organization-table-empty">
                    <p className="organization-table-empty__text">You have no assigned managers yet</p>
                  </div>
                </td>
              </tr>
            )}
          </Fragment>
        ) : (
          <tbody>
            <tr>
              <td>
                <SmallLoader />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    );
  }
}

export default withTranslation()(ManagerTable);
