import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function Description({ handleChangeDescription, description }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="accordion" id="accordion2">
        <div className="">
          <div className="addShift-card-header" id="headingTwo">
            <svg
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.7"
                d="M1 0.75L5.5 5.25L10 0.75"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <button
              className="addShift-card-header__btn"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {t('description')}
            </button>
          </div>

          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion2">
            <div className="addShift-card-body">
              <textarea
                value={description}
                className="addShift-textarea"
                placeholder={t('write_some_comments')}
                onChange={handleChangeDescription}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
