import React, { Fragment, Component } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';

import calendar from '../../images/calendar.svg';

registerLocale('en-gb', enGb);

export const Input = React.forwardRef(({ onClick, value, placeholder, className }, ref) => {
  return (
    <Fragment>
      <img className="date-picker-range__icon" src={calendar} alt="calendar" />
      <input
        ref={ref}
        readOnly
        onFocus={onClick}
        type="text"
        placeholder={placeholder}
        className={className}
        value={value}
      />
    </Fragment>
  );
});

export default class RangeDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.startDate && !state.endDate) {
      return {
        startDate: props.startDate || null,
        endDate: props.endDate || null,
      };
    }
    return null;
  }

  handleChangeDate = (date, type) => {
    switch (type) {
      case 'startDate':
        this.setState({ startDate: date, endDate: null }, () => {
          this.datepickerEndRef.input.focus();
          this.props.onChange(this.state);
        });
        break;
      case 'endDate':
        this.setState({ endDate: date }, () => {
          this.props.onChange(this.state);
        });
        break;
      default:
        this.setState({ startDate: null, endDate: null }, () => {
          this.props.onChange(this.state);
        });
        break;
    }
  };

  render() {
    const { startDate, endDate } = this.state;
    const { customInput, className, calendarClassName } = this.props;
    const commonProps = {
      startDate,
      endDate,
      customInput: customInput || <Input />,
      disabledKeyboardNavigation: true,
      monthsShown: 2,
      locale: 'en-gb',
      showPopperArrow: false,
      dateFormat: 'MMM dd, yyyy',
    };

    return (
      <Fragment>
        <DatePicker
          {...commonProps}
          onChange={(startDate) => this.handleChangeDate(startDate, 'startDate')}
          selected={startDate}
          className={`date-picker date-picker-range mr-2 ${className || ''}`}
          calendarClassName={`date-calendar ${calendarClassName || ''}`}
          placeholderText="From"
          selectsStart
        />
        <DatePicker
          {...commonProps}
          ref={(datepicker) => (this.datepickerEndRef = datepicker)}
          onChange={(endDate) => this.handleChangeDate(endDate, 'endDate')}
          selected={endDate}
          minDate={startDate}
          className={`date-picker date-picker-range ${className || ''}`}
          calendarClassName={`date-calendar ${calendarClassName || ''}`}
          placeholderText="To"
          selectsEnd
        />
      </Fragment>
    );
  }
}
