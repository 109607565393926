import axios from 'axios';
import { showLoader, hideLoader, showSmallLoader, hideSmallLoader } from './viewActionCreators';

export function getLeaves({ params }) {
  return (dispatch) => {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/api/v1/vacation-requests${params}`,
    };

    dispatch(showSmallLoader());

    return axios(options)
      .then((response) => {
        dispatch({
          type: 'GET_LEAVES',
          leaves: response.data.data,
        });
        dispatch(hideSmallLoader());
      })
      .catch((error) => {
        dispatch(hideSmallLoader());
      });
  };
}

export function changeLeavesStatus({ data, handleResponse }) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/api/v1/change-status/request`,
      data,
    };
    dispatch(showLoader());

    return axios(options)
      .then((response) => {
        handleResponse(response);
        dispatch(hideLoader());
      })
      .catch((error) => {
        handleResponse(error.response);
        dispatch(hideLoader());
      });
  };
}

export function deleteLeaves({ leavesId, handleSuccessDelete }) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/api/v1/vacation-request/${leavesId}/delete`,
    };

    return axios(options)
      .then((response) => {
        handleSuccessDelete(response);
      })
      .catch((error) => {});
  };
}
