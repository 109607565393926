import React from "react";

const DivStyle = {
  position: "fixed",
  zIndex: "20000000",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  backgroundColor: "rgba(255,255,255, .7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function FullPageLoader() {
  return (
    <div style={DivStyle}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.5 20.5C40.5 31.5457 31.5457 40.5 20.5 40.5C9.4543 40.5 0.5 31.5457 0.5 20.5C0.5 9.4543 9.4543 0.5 20.5 0.5C31.5457 0.5 40.5 9.4543 40.5 20.5ZM2.5 20.5C2.5 30.4411 10.5589 38.5 20.5 38.5C30.4411 38.5 38.5 30.4411 38.5 20.5C38.5 10.5589 30.4411 2.5 20.5 2.5C10.5589 2.5 2.5 10.5589 2.5 20.5Z"
          fill="#F6F7F9"
        />
        <path
          d="M20.5 1.5C20.5 0.947715 20.948 0.497392 21.4996 0.524994C23.7845 0.639335 26.0355 1.14502 28.1537 2.02241C30.5802 3.0275 32.785 4.50069 34.6421 6.35787C36.4993 8.21504 37.9725 10.4198 38.9776 12.8463C39.855 14.9645 40.3607 17.2155 40.475 19.5004C40.5026 20.052 40.0523 20.5 39.5 20.5C38.9477 20.5 38.5029 20.0519 38.4722 19.5005C38.3598 17.4784 37.9067 15.4872 37.1298 13.6117C36.2252 11.4278 34.8994 9.44353 33.2279 7.77208C31.5565 6.10062 29.5722 4.77475 27.3883 3.87017C25.5128 3.09331 23.5216 2.64023 21.4995 2.52777C20.9481 2.4971 20.5 2.05228 20.5 1.5Z"
          fill="#0B9444"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 0 0"
          to="360 0 0"
          dur="0.7s"
          repeatCount="indefinite"
        />
      </svg>
    </div>
  );
}
