import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Edit from './Edit';
import FullPageLoader from '../helpComponent/FullPageLoader';
import * as authActionCreators from '../../actions/authActionCreators';

class EditProfile extends Component {
  componentDidMount() {
    this.props.authActionCreators.getAccount();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { myData, t } = this.props;
    return (
      <div>
        <div className="addManager">
          <div className="content addManager__background">
            <div className="addManager-wrapper">
              <button className="settings-buttonBack" onClick={() => this.props.history.goBack()}>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('back')}
              </button>
              <Edit employeeData={myData} history={this.props.history} />
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoader: state.viewReducer.showLoader,
    myData: state.authReducer.myAccData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
