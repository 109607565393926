import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AddNewManager from './AddNewManager';
import FullPageLoader from '../helpComponent/FullPageLoader';

import { connect } from 'react-redux';

class AddManager extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {t} = this.props;
    return (
      <div>
        <div className="addManager">
          <div className="content addManager__background">
            <div className="addManager-wrapper">
              <Link to="/settings/managers" className="addManager__link">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#212529"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('back')}
              </Link>
              <AddNewManager myAccData={this.props.myAccData} history={this.props.history} />
            </div>
          </div>
        </div>
        {this.props.showLoader && <FullPageLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoader: state.viewReducer.showLoader,
    myAccData: state.authReducer.myAccData,
  };
}

export default withTranslation()(connect(mapStateToProps, null)(AddManager));
