import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({}));

export default function PaginationRounded({ count, handlePageChange, activePage }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    handlePageChange(value);
  };
  return (
    <div className={classes.root}>
      <Pagination
        count={count}
        page={Number(activePage)}
        onChange={handleChange}
      />
    </div>
  );
}
