import React, { useState, useEffect } from "react";
import Bus from "../../Utils/Bus";
import { CSSTransition } from "react-transition-group";

export default function FlashMessage() {
  let [visibility, setVisibility] = useState(false);
  let [message, setMessage] = useState("");
  let [type, setType] = useState("");

  useEffect(() => {
    Bus.addListener("flash", ({ message, type }) => {
      setVisibility(true);
      setMessage(message);
      setType(type);
      setTimeout(() => {
        setVisibility(false);
      }, 4000);
    });
  }, []);

  useEffect(() => {
    if (document.querySelector(".flash-close") !== null) {
      document
        .querySelector(".flash-close")
        .addEventListener("click", () => setVisibility(false));
    }
  });
  return (
    <CSSTransition
      in={visibility}
      timeout={300}
      classNames="show"
      unmountOnExit
    >
      <div className={`flash alert-${type}`}>
        <div className="flash-wrapper">
          <span>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.15" cx="16" cy="16" r="16" fill="#0B9444" />
              <path
                d="M22.6663 11L13.4997 20.1667L9.33301 16"
                stroke="#0B9444"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="flash-items">
            <h4 className="flash-items__title">Success</h4>
            <p className="flash-items__message">{message}</p>
          </div>
        </div>
        <span className="flash-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </span>
      </div>
    </CSSTransition>
  );
}
