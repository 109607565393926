export function setCookie(name, value) {
  var expires = "";
  var date = new Date();
  date.setDate(date.getDate() + 14);
  expires = "; expires=" + date.toUTCString();

  document.cookie =
    name +
    "=" +
    (value || "") +
    expires +
    ";path=/;domain=" +
    window.location.hostname;
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name) {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=" +
      window.location.hostname;
  }
}
