import React from 'react';
import { Component } from 'react';
import Schedule from './Schedule';
import qs from 'query-string';
import FullPageLoader from '../helpComponent/FullPageLoader';
import SmallLoader from '../helpComponent/SmallLoader';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { schedulerData } from './Schedule';

import { bindActionCreators } from 'redux';
import * as scheduleActionCreators from '../../actions/scheduleActionCreators';
import * as organizationActionCreators from '../../actions/organizationActionCreators';
import { connect } from 'react-redux';

class Main extends Component {
  constructor() {
    super();
    this.state = {
      deleteShiftId: '',
      isShowEditShift: false,
      isShowConfirmPopup: false,
    };
  }
  componentDidMount() {
    let params = {
      start: `${schedulerData.startDate}T00:00:00`,
      end: `${schedulerData.endDate}T23:59:59`,
      'filter[employees]': this.props.memberFilter,
    };
    let statisticsParams = {
      start: `${schedulerData.startDate}`,
      end: `${schedulerData.endDate}`,
    };

    params = `?${qs.stringify(params)}`;
    statisticsParams = `?${qs.stringify(statisticsParams)}`;
    
    this.props.scheduleActionCreators.getAllShift(params);
    this.props.scheduleActionCreators.getShiftsStatistics(statisticsParams);
    this.props.organizationActionCreators.getOrganization({ params: '?search=' });
  }

  toggleEditShift = () => {
    const { isShowEditShift } = this.state;
    this.setState({
      isShowEditShift: !isShowEditShift,
    });
  };

  togglePopup = () => {
    const { isShowConfirmPopup } = this.state;
    this.setState({
      isShowConfirmPopup: !isShowConfirmPopup,
    });
  };

  render() {
    const { isShiftsLoaded, t } = this.props;
    return (
      <div className="schedule">
        <div className="content">
          <div className="schedule-wrapper">
            <div
              id="schedule-bg"
              className={`${isShiftsLoaded ? 'schedule-bg' : ''}`}
            >
              {isShiftsLoaded ? (
                <Schedule
                  shifts={this.props.shifts}
                  scheduleActionCreators={this.props.scheduleActionCreators}
                  isShowConfirmPopup={this.state.isShowConfirmPopup}
                  isShowEditShift={this.state.isShowEditShift}
                  showLoader={this.props.showLoader}
                  location={this.props.location}
                  history={this.props.history}
                  allAreas={this.props.organization}
                  memberFilter={this.props.memberFilter}
                />
              ) : (
                <SmallLoader />
              )}
            </div>
            {this.props.shifts.length === 0 && (
              <p className="settings-table-emptyText">{t('dont_have_events')}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isShiftsLoaded: state.scheduleReducer.isShiftsLoaded,
    showLoader: state.viewReducer.showLoader,
    shifts: state.scheduleReducer.shifts,
    memberFilter: state.scheduleReducer.memberFilter,
    organization: state.organizationReducer.organization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    scheduleActionCreators: bindActionCreators(scheduleActionCreators, dispatch),
    organizationActionCreators: bindActionCreators(organizationActionCreators, dispatch),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Main));
