import React from 'react';

export default function Checkbox({
  text,
  checkboxStyle,
  containerStyle,
  textStyle,
  styles = {},
  handleChangeCheckbox,
  isCheckedCheckbox,
  name,
}) {
  return (
    <label className={`container-checkbox ${containerStyle || ''}`}>
      {text && (
        <p className={`container-checkbox__text ${textStyle || ''}`} style={styles}>
          {text}
        </p>
      )}
      <input name={name} type="checkbox" onChange={handleChangeCheckbox} checked={isCheckedCheckbox} />
      <span className={`checkbox-fake ${checkboxStyle || ''}`}></span>
    </label>
  );
}
