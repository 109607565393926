import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import * as newsActionCreators from '../../actions/newsActionCreators';
import { sortArrByDate } from '../../Utils/helpers';
import List from './List';

class News extends Component {
  componentDidMount() {
    this.props.newsActionCreators.getNews({ params: '' });
  }

  render() {
    const sortedData = sortArrByDate(this.props.news, 'YYYYMMDD', 'publish_at');
    const {t} = this.props;

    return (
      <div className="dashboard-table dashboard-table-scroll">
        <div className="dashboard-table-titles">
          <h6 className="dashboard-table__title news">{t('news')}</h6>
          <button onClick={() => this.props.history.push('/news')} className="dashboard-table__btn">
            {t('see_all')}
          </button>
        </div>
        {sortedData?.length !== 0 ? (
          sortedData?.map((item, index, arr) => !!item.is_active && <List key={index} data={item} prevData={arr[index - 1]} />)
        ) : (
          <p className="dashboard-nodata">You don't have any news</p>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    news: state.newsReducer.news,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newsActionCreators: bindActionCreators(newsActionCreators, dispatch),
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(News)));
