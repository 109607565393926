export const initialState = {
  employeeDetailedStatistics: {},
  dailyReport: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_EMPLOYEE_STATISTICS':
      return {
        ...state,
        employeeStatistics: action.employeeStatistics,
        meta: action.meta,
      };

    case 'GET_AREAS_STATISTICS':
      return {
        ...state,
        areasStatistics: action.areasStatistics,
        meta: action.meta,
      };

    case 'GET_EMPLOYEE_DETAILED_STATISTICS':
      return {
        ...state,
        employeeDetailedStatistics: action.employeeDetailedStatistics,
      };

    case 'GET_DAILY_REPORT':
      return {
        ...state,
        dailyReport: action.dailyReport,
      };

    default:
      return state;
  }
}
